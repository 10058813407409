import React from 'react';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useLanguage } from './i18nContext';

interface LocalizationProviderProps {
  children?: React.ReactNode;
}

export const LocalizationProvider: React.FC<LocalizationProviderProps> = ({ children }) => {
  const language = useLanguage();

  return (
    <MuiLocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
      {children}
    </MuiLocalizationProvider>
  );
};
