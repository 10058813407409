import React from 'react';
import { Tooltip } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useFormikContext } from 'formik';
import { displayName } from '../../util';
import { FilterValueDTO } from '../../dto';
import { ReportRecipientValues } from './ReportRecipientEditor';
import { useTranslation } from 'react-i18next';

interface Props {
  distinctValues: FilterValueDTO[];
}

const FilterValueWarning: React.FC<Props> = ({ distinctValues }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<ReportRecipientValues>();

  if (!values.value || distinctValues.map(v => v.label).includes(values.value)) {
    return null;
  }
  const tooltipText =
    values.value === 'nonBatchRecipient'
      ? t('sidebar.customReport.validation.missingRecipientValue')
      : t('sidebar.customReport.validation.notExistingValue', { value: values.value });

  return (
    <Tooltip title={tooltipText}>
      <WarningAmberIcon color="warning" />
    </Tooltip>
  );
};

displayName(FilterValueWarning, 'FilterValueWarning');

export default FilterValueWarning;
