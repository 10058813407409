import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { keyBy } from 'lodash';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Card, Stack, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ErrorOverlay } from '../Overlay';
import AnalysisTableHeader from './AnalysisTableHeader';
import { IconButton } from '../Button';
import DataGrid from '../DataGrid';
import AnalysisDetails from './AnalysisDetails';
import { DetailsDTO, FieldDTO, HistoryElement } from '../../dto';
import FilterUpdater from '../filter/FilterUpdater';
import { useUpdateTabState } from './AnalysisToggle';
import { displayName, useEntity } from '../../util';
import Spinner from '../Spinner';
import SettingsIcon from '@mui/icons-material/Settings';
import AnalysisSummary from './AnalysisSummary';
import { CustomColumnsPanel } from './customColumnsPanel/CustomColumnsPanel';
import { useCreateError, useLogError } from '../Error';
import ServerSidePagination from '../pagination/ServerSidePagination';
import { GridColDef, GridPreferencePanelsValue, GridSortModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { determineNewAnalysisTableFields } from '../../globalState/analysis';
import { useUnsetLoadingAnalysisState, useUnsetLoadingTabState } from '../../globalState/loading';
import {
  cleanUpQueryHistory,
  FilterState,
  useAnalysisFilterFields,
  useAnalysisGroupDrilldown,
  useAnalysisTable,
  useAnalysisTableChangeState,
  useAnalysisTableDetails,
  useAnalysisTableGroup,
  useAnalysisTableSort,
  useApi,
  useColumnPanelUpdateAnalysisTableFieldState,
  useCurrentAggregationAnalysisTableFields,
  useCurrentPageState,
  useDataGridUpdate,
  useGetQueryHistory,
  useGetSelectedCustomReport,
  useSetFieldStylesUpdateCallback,
  useSetModelsUpdateAfterGroupingCallback,
  useSetQueryHistory,
  useSetQueryHistoryStateCallback,
  useVisibleTableQueryObject,
} from '../../globalState';

const Container = styled(Card)`
  margin-top: ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;
`;

const VisibilityBox = styled(Box, { shouldForwardProp: prop => !['isVisible'].includes(prop.toString()) })<{
  isVisible: boolean;
}>`
  ${({ isVisible }) => (isVisible ? 'display: block;' : `display: none;`)}
`;

const createFilter = (field: FieldDTO, rawValues: Record<string, unknown>): FilterState => {
  if (field.filterType === 'textField' || field.filterType === 'textFieldStartsWith') {
    return { field, text: rawValues[field.name] as string };
  } else if (field.filterType === 'choice' || field.filterType === 'multiSelectFilter') {
    return { field, choice: [rawValues[field.name] + ''] };
  } else if (field.filterType === 'range') {
    return {
      field,
      rangeFrom: (rawValues[field.name] as number) / (field.conversionFactor ?? 1),
      rangeTo: ((rawValues[`${field.name}To`] ?? rawValues[field.name]) as number) / (field.conversionFactor ?? 1),
    };
  }
  throw new Error('Unknown filter type ' + field.filterType);
};

const AnalysisTable: React.FC = () => {
  const api = useApi();
  const entity = useEntity();
  const currentAggregationFields = useCurrentAggregationAnalysisTableFields();
  const response = useAnalysisTable();
  const apiRef = useGridApiRef();
  const addFilter = useAnalysisGroupDrilldown();
  const filterFields = useAnalysisFilterFields();
  const selectedReport = useGetSelectedCustomReport();
  const setModelsUpdateAfterGroupingCallback = useSetModelsUpdateAfterGroupingCallback();
  const setHandleAnalysisTableChangeCallback = useSetFieldStylesUpdateCallback();
  const updateAnalysisTableFieldState = useColumnPanelUpdateAnalysisTableFieldState();
  const columnsPanelHasChanged = useAnalysisTableChangeState();
  const visibleQueryObject = useVisibleTableQueryObject();
  const setQueryHistory = useSetQueryHistory();
  const queryHistory = useGetQueryHistory();
  const setQueryHistoryStateCallback = useSetQueryHistoryStateCallback();
  const logError = useLogError();
  const createError = useCreateError();
  const [dataGridUpdate, setDataGridUpdate] = useDataGridUpdate();
  const [analysisTableDetails, setAnalysisTableDetails] = useAnalysisTableDetails(entity);
  const [savingPreferences, setSavingPreferences] = useState(false);
  //two states for the query history are needed, so that adding a query to the history does not cause an infinite re-rendering
  const [queryHistoryState, setQueryHistoryState] = useState<HistoryElement[]>(queryHistory);
  const [currentPage, setCurrentPage] = useCurrentPageState();
  const [group1] = useAnalysisTableGroup('1');
  const [group2] = useAnalysisTableGroup('2');
  const [group3] = useAnalysisTableGroup('3');
  const [sortModel, setSortModel] = useAnalysisTableSort();
  const { t } = useTranslation();
  const unsetLoadingTabState = useUnsetLoadingTabState();
  const [loadingAnalysisState, unsetLoadingAnalysisState] = useUnsetLoadingAnalysisState();

  useEffect(() => {
    if (!response.loading) {
      unsetLoadingTabState();
      if (loadingAnalysisState === 'table') {
        unsetLoadingAnalysisState();
      }
    }
  }, [loadingAnalysisState, response.loading, unsetLoadingAnalysisState, unsetLoadingTabState]);

  useUpdateTabState('table');
  const filterFieldsByName = useMemo(() => keyBy(filterFields.fields, 'name'), [filterFields.fields]);
  const handleDrilldown = useCallback(
    (row: Record<string, unknown>) => {
      if (group1 === '--' && group2 === '--' && group3 === '--') {
        setAnalysisTableDetails(new Map(Object.entries(row)));
        const detailsDTO: DetailsDTO = { details: row, entity: entity };
        setQueryHistoryState(previousQueryHistoryState => [...previousQueryHistoryState, detailsDTO]);
      } else {
        addFilter(createFilter(filterFieldsByName[group1], row['__raw'] as Record<string, unknown>));
      }
    },
    [addFilter, entity, filterFieldsByName, group1, group2, group3, setAnalysisTableDetails]
  );
  const handleEditConfig = useCallback(() => {
    if (apiRef.current) {
      apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
    }
  }, [apiRef]);

  useEffect(() => {
    if (dataGridUpdate !== undefined) {
      const columnOrderModel = dataGridUpdate.columnOrderModel;
      const columnVisibilityModel = dataGridUpdate.columnVisibilityModel;

      if (
        !response.loading &&
        (response.error === undefined || response.error == null) &&
        apiRef !== undefined &&
        apiRef !== null &&
        apiRef.current !== undefined
      ) {
        const newFields = determineNewAnalysisTableFields(
          currentAggregationFields,
          columnVisibilityModel,
          columnOrderModel
        );
        const newColumns: GridColDef[] = newFields
          //The columns from the other aggregation are undefined and thus have to be filtered out
          .filter(f => f.name !== undefined)
          .map(f => ({
            field: f.name,
            headerName: f.label,
            width: 150,
            headerAlign: f.align,
            align: f.align,
            sortingOrder: ['asc', 'desc'],
            disableColumnMenu: true,
          }));

        apiRef.current.updateColumns(newColumns);

        setDataGridUpdate(undefined);
      }
    }
  }, [apiRef, currentAggregationFields, dataGridUpdate, response.error, response.loading, setDataGridUpdate]);

  const columns = useMemo(() => {
    const actionColumn: GridColDef = {
      field: '__DRILLDOWN__',
      disableColumnMenu: true,
      disableReorder: true,
      hideable: false,
      sortable: false,
      headerName: 'Drilldown',
      width: 40,
      resizable: false,
      renderHeader: () => {
        return savingPreferences ? (
          <Spinner size={4} />
        ) : (
          <Tooltip title={t('analysis.table.columnsPanel.tooltip')}>
            <IconButton onClick={handleEditConfig}>
              <SettingsIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        );
      },
      renderCell: ({ row }) => {
        return group1 === '--' || filterFieldsByName[group1] ? (
          <IconButton onClick={() => handleDrilldown(row)}>
            <ChevronRightIcon />
          </IconButton>
        ) : null;
      },
    };
    return [actionColumn].concat(
      currentAggregationFields.map(f => ({
        field: f.name,
        headerName: f.label,
        width: 150,
        headerAlign: f.align,
        align: f.align,
        sortingOrder: ['asc', 'desc'],
        disableColumnMenu: true,
      })) || []
    );
  }, [currentAggregationFields, filterFieldsByName, group1, handleDrilldown, handleEditConfig, savingPreferences, t]);

  const queryHistoryStateCallback = useCallback((newHistory: HistoryElement[]) => {
    setQueryHistoryState(newHistory);
  }, []);

  useEffect(() => {
    setQueryHistoryStateCallback({ callback: queryHistoryStateCallback });
  }, [setQueryHistoryStateCallback, queryHistoryStateCallback]);

  useEffect(() => {
    setQueryHistoryState(previousQueryHistoryState =>
      cleanUpQueryHistory([...previousQueryHistoryState, { queryObject: visibleQueryObject, report: selectedReport }])
    );
  }, [visibleQueryObject, selectedReport]);

  //this useEffect propagates the changes from the local queryHistoryState to the global queryHistory state
  useEffect(() => {
    setQueryHistory(cleanUpQueryHistory(queryHistoryState));
  }, [setQueryHistory, queryHistoryState]);

  useEffect(() => {
    const modelsUpdateAfterGrouping = async (groupingFields: string[]) => {
      if (!apiRef.current) {
        return;
      }
      let groupingColumnIndex = 1;
      for (const groupingField of groupingFields) {
        if (groupingField !== '--') {
          apiRef.current.setColumnIndex(groupingField, groupingColumnIndex);
          apiRef.current.setColumnVisibility(groupingField, true);
          groupingColumnIndex += 1;
        }
      }
    };
    setModelsUpdateAfterGroupingCallback({ callback: modelsUpdateAfterGrouping });
    return () => undefined;
  }, [apiRef, setModelsUpdateAfterGroupingCallback]);

  useEffect(() => {
    if (apiRef !== undefined && apiRef.current !== undefined && apiRef.current !== null) {
      currentAggregationFields.forEach(f => apiRef.current.setColumnVisibility(f.name, f.visible));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, currentAggregationFields, entity]);

  const handleUpdateFieldStyles = useMemo(() => {
    const updatedFieldStyles = async () => {
      console.log(`handleUpdateFieldStyles`);
      setSavingPreferences(true);
      try {
        await api.updateFieldStyles(entity, {
          fields: currentAggregationFields.map(it => ({ name: it.name, visible: it.visible })),
          groupMode: group1 === '--' ? 'NOT_GROUPING' : 'TABLE_GROUPING',
        });
      } catch (error) {
        await logError(createError(error));
      }
      setSavingPreferences(false);
    };
    return () => updatedFieldStyles();
  }, [api, createError, currentAggregationFields, entity, group1, logError]);
  useEffect(() => {
    setHandleAnalysisTableChangeCallback({ callback: handleUpdateFieldStyles });
    return () => undefined;
  }, [handleUpdateFieldStyles, setHandleAnalysisTableChangeCallback]);

  const handleSortModelChange = useCallback(
    (model: GridSortModel) => {
      if (model.length > 0) {
        setSortModel(model);
      }
    },
    [setSortModel]
  );

  const handlePreferenceClose = () => {
    const applyChange = async () => {
      updateAnalysisTableFieldState();
      await handleUpdateFieldStyles();
    };
    if (columnsPanelHasChanged) {
      applyChange().catch(logError);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (apiRef.current === null) {
    // @ts-expect-error {} is the initial value set by useGridApiRef
    apiRef.current = {};
  }

  return (
    <Container elevation={0}>
      <FilterUpdater />
      <VisibilityBox isVisible={analysisTableDetails !== undefined}>
        {analysisTableDetails !== undefined && <AnalysisDetails row={analysisTableDetails} />}
      </VisibilityBox>
      <VisibilityBox isVisible={analysisTableDetails === undefined}>
        <AnalysisTableHeader>
          {response.error ? (
            <></>
          ) : !response.loading && response.data?.totalNumberRecords !== undefined ? (
            <ServerSidePagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalNumberRows={response.data.totalNumberRecords}
            />
          ) : (
            <Spinner size={5} />
          )}
        </AnalysisTableHeader>
        <Box sx={{ fill: 'white', width: '100%' }}>
          {response.error ? (
            <ErrorOverlay error={response.error} />
          ) : (
            <Stack marginTop={2}>
              <Suspense fallback={<div />}>
                <AnalysisSummary data={response.data?.summary || {}} />
              </Suspense>
              <DataGrid
                autoHeight={true}
                apiRef={apiRef}
                loading={response.loading}
                paginationMode={'client'}
                pagination={true}
                columns={columns}
                pinnedColumns={{ left: ['__DRILLDOWN__'] }}
                rows={response.data?.rows || []}
                columnHeaderHeight={48}
                rowHeight={48}
                disableRowSelectionOnClick={true}
                disableColumnResize={false}
                hideFooter={true}
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                disableColumnReorder={true}
                onPreferencePanelClose={handlePreferenceClose}
                slots={{
                  columnsPanel: CustomColumnsPanel,
                }}
              />
            </Stack>
          )}
        </Box>
      </VisibilityBox>
    </Container>
  );
};

displayName(AnalysisTable, 'AnalysisTable');

export default AnalysisTable;
