import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useOutlet } from 'react-router';
import { TelcobillView, useGetAnalysisTab, useInitializeAnalysisTableFields, useViews } from '../../globalState';
import NavigationTabs from '../../components/NavigationTabs';
import ProfileMenu from '../../components/profilemenu/ProfileMenu';
import { useLanguage } from '../../i18nContext';
import MainLayout from '../../MainLayout';
import { displayName } from '../../util';
import { keyBy } from 'lodash';
import NotFound from '../NotFound';
import { useTranslation } from 'react-i18next';
import { useSetLoadingTabState } from '../../globalState/loading';

const User: React.FC = () => {
  const navigate = useNavigate();
  const language = useLanguage();
  const getAnalysisTab = useGetAnalysisTab();
  const outlet = useOutlet();
  const views = useViews();
  const initializeAnalysisTableFields = useInitializeAnalysisTableFields();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const setLoadingTabState = useSetLoadingTabState();

  useEffect(() => {
    const allEntities = views.map(it => it.entity).filter((item): item is string => !!item);
    const allEntitiesSet = new Set(allEntities);
    allEntitiesSet.forEach(it => initializeAnalysisTableFields(it));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  const viewsByPath = useMemo(
    () => keyBy(views, 'path'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [views, language]
  );
  const selectedView = useMemo(
    () => views.find(v => !!v.matcher.exec(pathname)) as TelcobillView,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [views, pathname, language]
  );
  const handleTabChanged = async (value: string): Promise<[string, string]> => {
    const view = viewsByPath[value];
    const analysisTab = await getAnalysisTab(view.entity as string);
    const newPath = value.replace(':lng', language).replace(':type', analysisTab);
    setLoadingTabState(newPath);
    navigate(newPath);
    return [value, newPath];
  };

  const tabItems = useMemo(
    () =>
      views.map(v => ({
        label: t(`tabs.user.${v.name}`),
        path: v.path,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, views, language]
  );

  if (outlet === null) {
    return <NotFound />;
  } else {
    return selectedView !== undefined ? (
      <>
        <MainLayout
          navigation={<NavigationTabs tabs={tabItems} path={selectedView.path} onChange={handleTabChanged} />}
          menu={<ProfileMenu />}
        >
          <Outlet />
        </MainLayout>
      </>
    ) : (
      <></>
    );
  }
};

displayName(User, 'User');

export default User;
