import React from 'react';
import { Stack, Typography } from '@mui/material';
import WizardStepLayout from '../WizardStepLayout';
import { displayName } from '../../util';
import { useFormikContext } from 'formik';
import { CustomReportData, SendEmailButton } from './CustomReportWizard';
import RichTextEditor from '../RichTextEditor';
import { useTranslation } from 'react-i18next';
import { DefaultTextContainer, useGetDefaultMailText } from './DefaultText';

interface Props {
  disabledSendEmail: boolean;
  triggerSendEmail: () => Promise<void>;
}

const CustomReportWizardStep4: React.FC<Props> = ({ disabledSendEmail, triggerSendEmail }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<CustomReportData>();
  const defaultMailText = useGetDefaultMailText();

  return (
    <WizardStepLayout title={t('sidebar.customReport.step4.title')}>
      <DefaultTextContainer elevation={0}>
        <Stack direction="column" gap={2}>
          {values.showDefaultText && defaultMailText.split('\n').map((p, i) => <Typography key={i}>{p}</Typography>)}
          <RichTextEditor readOnly={true} initialValue={values.customText} />
        </Stack>
      </DefaultTextContainer>
      <Stack marginTop={4} alignItems={'start'}>
        <SendEmailButton disabled={disabledSendEmail} triggerSendEmail={triggerSendEmail} />
      </Stack>
    </WizardStepLayout>
  );
};

displayName(CustomReportWizardStep4, 'CustomReportWizardStep4');

export default CustomReportWizardStep4;
