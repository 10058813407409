import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { keyBy } from 'lodash';
import { Box, Card, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { ErrorOverlay } from '../Overlay';
import DataGrid from '../DataGrid';
import RunAnalysisButton from '../RunAnalysisButton';
import { displayName } from '../../util';
import DrilldownSummary from './DrilldownSummary';
import Spinner from 'components/Spinner';
import { IconButton } from '../Button';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from 'react-i18next';
import DrilldownDataExportDialog from './DrilldownDataExportDialog';
import ServerSidePagination from '../pagination/ServerSidePagination';
import { useCurrentPageState, useDrilldown, useDrilldownTable, useSetCurrentPageToZero } from '../../globalState';
import { useUnsetLoadingTabState } from '../../globalState/loading';
import {
  GridCallbackDetails,
  GridColDef,
  GridColumnsState,
  GridState,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';

const Container = styled(Card)`
  margin-top: ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.grey['200']};
`;

//Without this element, there would be a flickering when the mouse leaves the data grid
const NoFlickering = styled('div')`
  & > * > * > * > * > * > * {
    background: none !important;
  }
`;

const NoRowsOverlay = () => null;

const DrilldownTable: React.FC = () => {
  const response = useDrilldownTable();
  const apiRef = useGridApiRef();
  const setCurrentPageToZero = useSetCurrentPageToZero();
  const { t } = useTranslation();
  const { current, goDown } = useDrilldown();
  const [summaryColumns, setSummaryColumns] = useState<GridColDef[]>([]);
  const [currentPage, setCurrentPage] = useCurrentPageState();
  const [clickedRow, setClickedRow] = useState<Record<string, unknown>>({});
  const [dataExporting, setDataExporting] = useState(false);
  const unsetLoadingTabState = useUnsetLoadingTabState();

  useEffect(() => {
    if (!response.loading) {
      unsetLoadingTabState();
    }
  }, [response.loading, unsetLoadingTabState]);

  const maxPageSize = 20;
  const drilldownColumn: GridColDef = useMemo(() => {
    return {
      field: '__DRILLDOWN__',
      headerName: '',
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      resizable: false,
      width: 40,
      renderCell: ({ row }: { row: Record<string, unknown> }) => {
        return row['__drilldown'] ? (
          <RunAnalysisButton
            onClick={() => {
              setCurrentPageToZero();
              goDown(row['__drilldown'] as string, row);
            }}
          />
        ) : null;
      },
    };
  }, [goDown, setCurrentPageToZero]);
  const fieldsByName = useMemo(() => keyBy(response.data?.fields, 'name'), [response.data?.fields]);
  const columns = useMemo(() => {
    const cols = [drilldownColumn].concat(
      current.level.fields
        .filter(field => field.visible)
        .map(field => fieldsByName[field.name])
        .filter(f => !!f)
        .map(f => ({ field: f.name, headerName: f.label, flex: 1, disableColumnMenu: true })) || []
    );
    if (current.level.actions.length) {
      const actionColumn: GridColDef = {
        field: '__ACTIONS__',
        headerName: t('bills.actions'),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        resizable: false,
        width: 120,
        renderCell: ({ row }: { row: Record<string, unknown> }) => {
          return (
            <Tooltip title={t('bills.exportDialog.tooltip')}>
              <IconButton
                onClick={() => {
                  setDataExporting(true);
                  setClickedRow(row);
                }}
              >
                <DownloadIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          );
        },
      };
      return cols.concat(actionColumn);
    }
    return cols;
  }, [drilldownColumn, current.level.fields, current.level.actions, fieldsByName, t]);
  const handleColumnsChanged = (columnsState: GridColumnsState) => {
    const levelFieldsByName = keyBy(current.level.fields, 'name');
    const newSummaryColumns = columnsState.orderedFields.map(name => {
      const col = columnsState.lookup[name];
      return {
        field: levelFieldsByName[col.field]?.total || col.field,
        width: col.computedWidth,
      };
    });
    if (JSON.stringify(summaryColumns) !== JSON.stringify(newSummaryColumns)) {
      setSummaryColumns(newSummaryColumns);
    }
  };
  const handleExportDialogClose = () => {
    setDataExporting(false);
  };
  const initialState = useMemo(
    () => ({
      pagination: {
        paginationModel: {
          pageSize: maxPageSize,
        },
      },
    }),
    []
  );
  const dispatchStateChange = (state: GridState, event: MuiEvent, details: GridCallbackDetails) => {
    handleColumnsChanged(state.columns);
  };
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (apiRef.current === null) {
    // @ts-expect-error {} is the initial value set by useGridApiRef
    apiRef.current = {};
  }

  return (
    <Container elevation={0} sx={{ flexGrow: 0, width: '100%' }}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ padding: '10px 16px 6px 60px' }}
      >
        <Typography sx={{ fontWeight: 700 }}>{current.level.title}</Typography>
        {response.error ? (
          <></>
        ) : !response.loading && response.data?.totalNumberRecords !== undefined ? (
          <ServerSidePagination
            totalNumberRows={response.data.totalNumberRecords}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <Spinner size={5} />
        )}
      </Stack>
      {(current.level.total && (
        <NoFlickering>
          <DrilldownSummary columns={summaryColumns} summary={response.data?.summary} />
        </NoFlickering>
      )) || <Paper />}
      <Box sx={{ fill: 'white', width: '100%' }}>
        {response.error ? (
          <ErrorOverlay error={response.error} />
        ) : (
          <DataGrid
            autoHeight={true}
            apiRef={apiRef}
            loading={response.loading}
            slots={{ noRowsOverlay: NoRowsOverlay }}
            columns={columns}
            pinnedColumns={{ left: ['__DRILLDOWN__'] }}
            rows={response.data?.rows || []}
            columnHeaderHeight={48}
            rowHeight={48}
            disableRowSelectionOnClick={true}
            pagination={true}
            initialState={initialState}
            onStateChange={dispatchStateChange}
            hideFooter={true}
          />
        )}
      </Box>
      {dataExporting && (
        <DrilldownDataExportDialog
          actions={current.level.actions}
          onCancel={handleExportDialogClose}
          sourceEntity={current.level.entityName}
          row={clickedRow}
        />
      )}
    </Container>
  );
};

displayName(DrilldownTable, 'DrilldownTable');

export default DrilldownTable;
