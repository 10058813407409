import React from 'react';
import { MenuItem, Stack } from '@mui/material';
import TextInput from './TextInput';
import DefaultSelect from './Select';
import { useLanguage } from '../i18nContext';
import moment from 'moment';
import { range } from 'lodash';
import { displayName } from '../util';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const DateInput: React.FC<Props> = ({ value, onChange }) => {
  const language = useLanguage();
  const localizedMoment = moment().locale(language);
  const [year, month, day] = value.split('-');

  return (
    <Stack direction="row" gap={3}>
      <TextInput width={6} value={day} onChange={d => onChange(`${year}-${month}-${d}`)} />
      <DefaultSelect width={9} value={month} onChange={e => onChange(`${year}-${e.target.value}-${day}`)}>
        {range(0, 12).map(i => (
          <MenuItem key={i} value={i < 9 ? `0${i + 1}` : i + 1 + ''}>
            {localizedMoment.month(i).format('MMM')}
          </MenuItem>
        ))}
      </DefaultSelect>
      <TextInput width={7} value={year} onChange={y => onChange(`${y}-${month}-${day}`)} />
    </Stack>
  );
};

displayName(DateInput, 'DateInput');

export default DateInput;
