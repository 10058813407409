import React, { useCallback } from 'react';
import WizardStepLayout from '../WizardStepLayout';
import RichTextEditor from '../RichTextEditor';
import { useFormikContext } from 'formik';
import { CustomReportData, SendEmailButton } from './CustomReportWizard';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import Checkbox from '../Checkbox';
import Text from '../Text';
import { DefaultTextContainer, useGetDefaultMailText } from './DefaultText';

interface Props {
  disabledSendEmail: boolean;
  triggerSendEmail: () => Promise<void>;
}

const CustomReportWizardStep3: React.FC<Props> = ({ disabledSendEmail, triggerSendEmail }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<CustomReportData>();
  const defaultMailText = useGetDefaultMailText();

  const handleChange = useCallback((html: string) => setFieldValue('customText', html), [setFieldValue]);
  return (
    <WizardStepLayout title={t('sidebar.customReport.step3.title')}>
      <Stack direction="column" gap={4}>
        <Stack direction="column" gap={4}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Checkbox
              checked={values.showDefaultText}
              onChange={(_, checked: boolean | ((prevState: boolean) => boolean)) =>
                setFieldValue('showDefaultText', checked)
              }
            />
            <Text width={9}>{t('sidebar.customReport.step3.checkbox')}</Text>
          </Stack>
          {values.showDefaultText && (
            <DefaultTextContainer>
              {defaultMailText.split('\n').map((p, i) => (
                <Typography key={i}>{p}</Typography>
              ))}
            </DefaultTextContainer>
          )}
        </Stack>
        <RichTextEditor
          height={12}
          placeholder={t('sidebar.customReport.step3.title')}
          initialValue={values.customText}
          onChange={handleChange}
        />
        <Stack marginTop={4} alignItems={'start'}>
          <SendEmailButton disabled={disabledSendEmail} triggerSendEmail={triggerSendEmail} />
        </Stack>
      </Stack>
    </WizardStepLayout>
  );
};

displayName(CustomReportWizardStep3, 'CustomReportWizardStep3');

export default CustomReportWizardStep3;
