import { toPairs } from 'lodash';
import download from 'downloadjs';
import { toError } from './components';
import {
  AccountsDTO,
  AdditionalProfileMenuItemsDTO,
  AdminCustomersDTO,
  AdministratorDTO,
  AdministratorsDTO,
  AdminViewsDTO,
  AnalysisChartDTO,
  ApplicationDataDTO,
  AttachCostCenterDTO,
  BaseFieldsDTO,
  BaseValuesDTO,
  CalculateBaseFieldsDTO,
  CostCenterAccountsDTO,
  CostCenterDTO,
  CostCenterSubscriptionsDTO,
  CostCenterUsersDTO,
  CreateAdministratorDTO,
  CreateCostCenterDTO,
  CreateReportDTO,
  CreateTagGroupDTO,
  CreateTagsDTO,
  CreateUserDTO,
  CustomReportDTO,
  CustomReportsDTO,
  DrilldownLevelsDTO,
  EnhancedErrorDTO,
  EntitiesDTO,
  FieldsDTO,
  FilterValuesDTO,
  FormattedValuesDTO,
  GenerateExportFileDTO,
  IdDTO,
  IdsDTO,
  InvoicePeriodsDTO,
  InvoicesDTO,
  JobsDTO,
  LoginResponseDTO,
  LogoutDTO,
  MailTemplateDTO,
  OverviewDTO,
  QueryFieldDTO,
  QueryObjectDTO,
  QueryResultDTO,
  SelectItemsDTO,
  StatusDTO,
  StatusMessageDTO,
  StatusMessageTextDTO,
  SubscriptionAssignmentDTO,
  SupportAccountsDTO,
  SupportCustomersDTO,
  SupportEntityType,
  SupportReportsDTO,
  SupportSubscriptionsDTO,
  SupportUsersDTO,
  SystemReportsDTO,
  TableExportFormatType,
  TagFileType,
  TagGroupsDTO,
  TagsDTO,
  TriggerCRMSyncDTO,
  UpdateCostCenterDTO,
  UpdateFieldStylesDTO,
  UpdateUserDTO,
  UserDataDTO,
  UserDTO,
  ViewsDTO,
  YearsDTO,
} from './dto';

export interface ClientConfig {
  headers?: Record<string, string>;
}

export interface ApiError {
  message: string;
  details?: string;
  errors?: ApiError[];
}

export interface ApiResponse<T> {
  data?: T;
  error?: ApiError;
}

const fetchApiResponse = async <T>(uri: string, options: RequestInit): Promise<ApiResponse<T>> => {
  try {
    const response = await fetch(uri, { ...options, redirect: 'error' });

    const expirationDate = response.headers.get('x-expiration-date');
    if (expirationDate !== null) {
      const expirationEvent = new CustomEvent('expirationEvent', { detail: expirationDate });
      document.dispatchEvent(expirationEvent);
    }

    return await response.json();
  } catch (err) {
    const error = toError(err);
    //On Chromium and Safari, there is the bug that not a TimeoutError but an AbortError is returned
    //https://issues.chromium.org/issues/40902497
    if (error.name === 'AbortError' || error.name === 'TimeoutError') {
      throw error;
    } else if (
      error.message === 'Unexpected token \'U\', "Unauthorized" is not valid JSON' ||
      error.message === 'Unexpected token \'U\', "Unauthorize{" is not valid JSON'
    ) {
      throw new Error(`Unauthorized`);
    } else {
      throw new Error(`Server responded with error status:\n${toError(err)}`);
    }
  }
};

const get = <T>(uri: string, config: ClientConfig, timeout?: number): Promise<ApiResponse<T>> => {
  const options = { headers: config.headers, signal: timeout !== undefined ? AbortSignal.timeout(timeout) : undefined };
  return fetchApiResponse(uri, options);
};

const post = <T>(uri: string, body: unknown, config: ClientConfig, timeout?: number): Promise<ApiResponse<T>> => {
  const isUpload = body instanceof FormData;
  const options: RequestInit = {
    method: 'POST',
    headers: isUpload ? config.headers : { ...config.headers, 'Content-Type': 'application/json' },
    body: body === undefined || isUpload ? body : JSON.stringify(body),
    signal: timeout !== undefined ? AbortSignal.timeout(timeout) : undefined,
  };
  return fetchApiResponse(uri, options);
};

const patch = <T>(uri: string, body: unknown, config: ClientConfig): Promise<ApiResponse<T>> => {
  const options: RequestInit = {
    method: 'PATCH',
    headers: { ...config.headers, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  return fetchApiResponse(uri, options);
};

const del = <T>(uri: string, body: unknown, config: ClientConfig): Promise<ApiResponse<T>> => {
  const options: RequestInit = {
    method: 'DELETE',
    headers: { ...config.headers, 'Content-Type': 'application/json' },
    body: body === undefined ? undefined : JSON.stringify(body),
  };
  return fetchApiResponse(uri, options);
};

const getDownload = (
  uri: string,
  config: ClientConfig,
  handleFailure: () => void,
  setWaitingState?: (isWaiting: boolean) => void
) => {
  const options: RequestInit = {
    method: 'GET',
    headers: config.headers,
  };
  fetchDownload(uri, options, handleFailure, setWaitingState);
};

const postDownload = (
  uri: string,
  body: unknown,
  config: ClientConfig,
  handleFailure: () => void,
  setWaitingState?: (isWaiting: boolean) => void,
  filename?: string
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: { ...config.headers, 'Content-Type': 'application/json' },
    body: body === undefined ? undefined : JSON.stringify(body),
  };
  fetchDownload(uri, options, handleFailure, setWaitingState, filename);
};

const fetchDownload = async (
  uri: string,
  options: RequestInit,
  handleFailure: () => void,
  setWaitingState?: (isWaiting: boolean) => void,
  filename?: string
) => {
  if (setWaitingState !== undefined) {
    setWaitingState(true);
  }
  const response = await fetch(uri, options);
  if (response.headers.get('Content-Type') === 'application/json') {
    if (setWaitingState !== undefined) {
      setWaitingState(false);
    }
    if (handleFailure !== undefined) {
      handleFailure();
    } else {
      console.error('Download failed');
    }
    return;
  }
  const cd = response.headers.get('Content-Disposition');
  const fn = 'filename=';
  // Extract filename from header
  // Content-Disposition: attachment; filename="Example.pdf"
  const serverFilename = cd
    ? cd
        .substring(cd.indexOf(fn) + fn.length)
        .split('"')
        .join('')
    : undefined;
  const blob = await response.blob();
  download(blob, filename ?? serverFilename);
  if (setWaitingState !== undefined) {
    setWaitingState(false);
  }
};

const toFormData = (file: File): FormData => {
  const formData = new FormData();
  formData.append('file', file);
  return formData;
};

export type FieldPropertyType =
  | 'SubscriptionField'
  | 'UserField'
  | 'JurisdictionField'
  | 'AccountField'
  | 'InvoiceNoField'
  | 'DurationField'
  | 'DateField'
  | 'AmountField'
  | 'group'
  | 'filter'
  | 'userSelectableField'
  | 'download'
  | 'summary'
  | 'analysisSummary'
  | 'chartAggregate'
  | 'recordDetailField';

export interface Api {
  login: (username: string, password: string) => Promise<ApiResponse<LoginResponseDTO>>;
  logout: (lang: string, forceDestroy: boolean) => Promise<ApiResponse<LogoutDTO>>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<ApiResponse<StatusDTO>>;
  forgottenPassword: (identification: string) => Promise<ApiResponse<StatusDTO>>;
  resetPassword: (newPassword: string, uuid: string) => Promise<ApiResponse<StatusDTO>>;
  getOverview: (account: string, invoicePeriod: string | undefined) => Promise<ApiResponse<OverviewDTO>>;
  getCostCenters: () => Promise<ApiResponse<CostCenterDTO>>;
  downloadAssignments: (handleFailure: () => void, setWaitingState: (isWaiting: boolean) => void) => void;
  uploadAssignments: (file: File) => Promise<ApiResponse<SubscriptionAssignmentDTO>>;
  applyAssignments: (subscriptionAssignmentDTO: SubscriptionAssignmentDTO) => Promise<ApiResponse<StatusDTO>>;
  createCostCenter: (dto: CreateCostCenterDTO) => Promise<ApiResponse<IdDTO>>;
  updateCostCenter: (costCenterId: number, dto: UpdateCostCenterDTO) => Promise<ApiResponse<StatusDTO>>;
  getCostCenter: (costCenterId: number) => Promise<ApiResponse<CostCenterDTO>>;
  deleteCostCenter: (costCenterId: number) => Promise<ApiResponse<StatusDTO>>;
  attachCostCenter: (dto: AttachCostCenterDTO) => Promise<ApiResponse<StatusDTO>>;
  getCostCenterAccounts: (ccId: number) => Promise<ApiResponse<CostCenterAccountsDTO>>;
  assignAccountsToCostCenter: (ccId: number, accountIds: number[]) => Promise<ApiResponse<StatusDTO>>;
  removeAccountsFromCostCenter: (ccId: number, accountIds: number[]) => Promise<ApiResponse<StatusDTO>>;
  getCostCenterSubscriptions: (ccId: number) => Promise<ApiResponse<CostCenterSubscriptionsDTO>>;
  assignSubscriptionsToCostCenter: (ccId: number, subscriptionIds: number[]) => Promise<ApiResponse<StatusDTO>>;
  removeSubscriptionsFromCostCenter: (ccId: number, subscriptionIds: number[]) => Promise<ApiResponse<StatusDTO>>;
  getCostCenterUsers: (ccId: number) => Promise<ApiResponse<CostCenterUsersDTO>>;
  assignUsersToCostCenter: (ccId: number, userIds: number[]) => Promise<ApiResponse<StatusDTO>>;
  removeUsersFromCostCenter: (ccId: number, userIds: number[]) => Promise<ApiResponse<StatusDTO>>;
  getInvoicePeriods: (accountName: string) => Promise<ApiResponse<InvoicePeriodsDTO>>;
  getAllInvoicePeriods: () => Promise<ApiResponse<InvoicePeriodsDTO>>;
  getYears: () => Promise<ApiResponse<YearsDTO>>;
  getAccounts: () => Promise<ApiResponse<AccountsDTO>>;
  getUser: () => Promise<ApiResponse<UserDTO>>;
  getDrilldownLevels: () => Promise<ApiResponse<DrilldownLevelsDTO>>;
  getFields: (entity: string, fieldProperty?: FieldPropertyType) => Promise<ApiResponse<FieldsDTO>>;
  getFieldValues: (entity: string, field: string) => Promise<ApiResponse<FormattedValuesDTO>>;
  updateFieldStyles: (entity: string, updateFieldStyles: UpdateFieldStylesDTO) => Promise<ApiResponse<StatusDTO>>;
  query: (queryObject: QueryObjectDTO) => Promise<ApiResponse<QueryResultDTO>>;
  chartQuery: (queryObject: QueryObjectDTO) => Promise<ApiResponse<AnalysisChartDTO>>;
  createTableExportFile: (
    queryObject: QueryObjectDTO,
    format: TableExportFormatType,
    fileName?: string
  ) => Promise<ApiResponse<GenerateExportFileDTO>>;
  downloadTableExportFile: (
    format: TableExportFormatType,
    directoryName: string,
    handleFailure: () => void,
    setWaitingState: (isWaiting: boolean) => void
  ) => void;
  getAllReports: (entity: string) => Promise<ApiResponse<CustomReportsDTO>>;
  getCustomReports: (entity: string) => Promise<ApiResponse<CustomReportsDTO>>;
  saveCustomReport: (entity: string, report: CreateReportDTO) => Promise<ApiResponse<CustomReportDTO>>;
  deleteCustomReport: (entity: string, reportId: number) => Promise<ApiResponse<StatusDTO>>;
  sendCustomReport: (entity: string, reportId: number) => Promise<ApiResponse<StatusDTO>>;
  getSystemReports: (entity: string) => Promise<ApiResponse<SystemReportsDTO>>;
  getMailTemplate: (name: string) => Promise<ApiResponse<MailTemplateDTO>>;
  getTagGroups: () => Promise<ApiResponse<TagGroupsDTO>>;
  getTagsOfTagGroup: (tagGroupId: number) => Promise<ApiResponse<TagsDTO>>;
  calculateValidBaseFields: (dto: CalculateBaseFieldsDTO) => Promise<ApiResponse<BaseFieldsDTO>>;
  getEntities: () => Promise<ApiResponse<EntitiesDTO>>;
  uploadTagValues: (tagGroupId: number, file: File, format: TagFileType) => Promise<ApiResponse<StatusDTO>>;
  downloadAllTagValues: (handleFailure: () => void, setWaitingState: (isWaiting: boolean) => void) => void;
  downloadTagValues: (
    tagGroupId: number,
    format: TagFileType,
    handleFailure: () => void,
    setWaitingState: (isWaiting: boolean) => void
  ) => void;
  deleteTagGroup: (tagGroupId: number) => Promise<ApiResponse<StatusDTO>>;
  deleteTag: (tagGroupId: number, tagId: number) => Promise<ApiResponse<StatusDTO>>;
  createTagGroup: (dto: CreateTagGroupDTO) => Promise<ApiResponse<IdDTO>>;
  updateTagGroup: (tagGroupId: number, dto: CreateTagGroupDTO) => Promise<ApiResponse<StatusDTO>>;
  addTags: (tagGroupId: number, dto: CreateTagsDTO) => Promise<ApiResponse<StatusDTO>>;
  getBaseValues: (tagGroupId: number, baseField: string) => Promise<ApiResponse<BaseValuesDTO>>;
  updateTagValue: (tagGroupId: number, tagId: number, value: string) => Promise<ApiResponse<StatusDTO>>;
  downloadInvoice: (
    entity: string,
    accountId: number,
    invoiceNo: string,
    handleFailure: () => void,
    setWaitingState: (isWaiting: boolean) => void
  ) => void;
  downloadManual: (handleFailure: () => void, setWaitingState: (isWaiting: boolean) => void) => void;
  getDistinctValues: (queryObject: QueryObjectDTO, filterField: QueryFieldDTO) => Promise<ApiResponse<FilterValuesDTO>>;
  getViews: () => Promise<ApiResponse<ViewsDTO>>;
  getAdminViews: () => Promise<ApiResponse<AdminViewsDTO>>;
  getAdditionalProfileMenuItems: () => Promise<ApiResponse<AdditionalProfileMenuItemsDTO>>;
  checkHeartbeat: (timeout: number) => Promise<ApiResponse<StatusDTO>>;
  getStatusMessage: () => Promise<ApiResponse<StatusMessageDTO>>;
  setStatusMessage: (dto: StatusMessageDTO) => Promise<ApiResponse<StatusDTO>>;
  getStatusMessageText: () => Promise<ApiResponse<StatusMessageTextDTO>>;
  logError: (dto: EnhancedErrorDTO, timeout?: number) => Promise<ApiResponse<StatusDTO>>;
  // admin
  searchCustomers: (search: string) => Promise<ApiResponse<SupportCustomersDTO>>;
  searchUsers: (search: string) => Promise<ApiResponse<SupportUsersDTO>>;
  getPrivilegeLevels: () => Promise<ApiResponse<SelectItemsDTO>>;
  createUser: (dto: CreateUserDTO) => Promise<ApiResponse<StatusDTO>>;
  updateUser: (userId: number, dto: UpdateUserDTO) => Promise<ApiResponse<StatusDTO>>;
  deleteUser: (userId: number) => Promise<ApiResponse<StatusDTO>>;
  searchAccounts: (search: string) => Promise<ApiResponse<SupportAccountsDTO>>;
  searchSubscriptions: (search: string) => Promise<ApiResponse<SupportSubscriptionsDTO>>;
  searchInvoices: (search: string) => Promise<ApiResponse<InvoicesDTO>>;
  searchReports: (search: string) => Promise<ApiResponse<SupportReportsDTO>>;
  loginAs: (entity: SupportEntityType, id: string) => Promise<ApiResponse<LoginResponseDTO>>;
  getBlacklistCustomers: () => Promise<ApiResponse<AdminCustomersDTO>>;
  addBlacklistCustomer: (ids: IdsDTO) => Promise<ApiResponse<StatusDTO>>;
  removeBlacklistCustomer: (ids: IdsDTO) => Promise<ApiResponse<StatusDTO>>;
  getJobs: () => Promise<ApiResponse<JobsDTO>>;
  executeJob: (jobName: string, jobGroup: string) => Promise<ApiResponse<StatusDTO>>;
  getApplicationStatistics: (params: {
    from: string;
    to: string;
    instance: string;
    series: string;
    group: string;
    aggr: string;
  }) => Promise<ApiResponse<ApplicationDataDTO>>;
  getUserStatistics: (params: {
    from: string;
    to: string;
    category: string;
    instance: string;
    user?: string;
    description?: string;
  }) => Promise<ApiResponse<UserDataDTO>>;
  getAdminStatistics: (params: {
    from: string;
    to: string;
    category: string;
    instance: string;
    user?: string;
    description?: string;
  }) => Promise<ApiResponse<UserDataDTO>>;
  getInstances: () => Promise<ApiResponse<SelectItemsDTO>>;
  getStatisticsCategories: () => Promise<ApiResponse<SelectItemsDTO>>;
  getApplicationStatisticsSeries: () => Promise<ApiResponse<SelectItemsDTO>>;
  getApplicationStatisticsGrouping: () => Promise<ApiResponse<SelectItemsDTO>>;
  getAdministrator: () => Promise<ApiResponse<AdministratorDTO>>;
  getAdministrators: () => Promise<ApiResponse<AdministratorsDTO>>;
  getAdminCustomers: (adminId?: number) => Promise<ApiResponse<AdminCustomersDTO>>;
  getAdminBlacklistCustomers: (adminId?: number) => Promise<ApiResponse<AdminCustomersDTO>>;
  createAdministrator: (dto: CreateAdministratorDTO) => Promise<ApiResponse<StatusDTO>>;
  updateAdministrator: (adminId: number, dto: CreateAdministratorDTO) => Promise<ApiResponse<StatusDTO>>;
  deleteAdministrator: (adminId: number) => Promise<ApiResponse<StatusDTO>>;
  triggerCrmSync: (dto: TriggerCRMSyncDTO) => Promise<ApiResponse<StatusDTO>>;
}

const toQueryParams = (params: Record<string, string | undefined>): string => {
  return toPairs(params)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`)
    .join('&');
};

export const client = (config: ClientConfig = {}): Api => ({
  login: (username, password) => post('/auth/login', { username, password }, config),
  logout: (lang: string, forceDestroy: boolean) => post('/auth/logout', { lang, forceDestroy }, config),
  changePassword: (oldPassword, newPassword) => post('/api/v1/password', { oldPassword, newPassword }, config),
  forgottenPassword: identification => post('/api/v1/services/forgottenPassword', { identification }, config),
  resetPassword: (newPassword, uuid) => post('/api/v1/services/resetPassword', { newPassword, uuid }, config),
  getOverview: (account, invoicePeriod) => get(`/api/v1/overview?${toQueryParams({ account, invoicePeriod })}`, config),
  getCostCenters: () => get('/api/v1/costcenter', config),
  createCostCenter: dto => post('/api/v1/costcenter', dto, config),
  updateCostCenter: (costCenterId, dto) => patch(`/api/v1/costcenter/${costCenterId}`, dto, config),
  getCostCenter: costCenterId => get(`/api/v1/costcenter/${costCenterId}`, config),
  deleteCostCenter: costCenterId => del(`/api/v1/costcenter/${costCenterId}`, undefined, config),
  attachCostCenter: dto => post('/api/v1/costcenter/attach', dto, config),
  getCostCenterAccounts: ccId => get(`/api/v1/costcenter/${ccId}/accounts`, config),
  assignAccountsToCostCenter: (ccId, accountIds) =>
    post(`/api/v1/costcenter/${ccId}/accounts`, { ids: accountIds }, config),
  removeAccountsFromCostCenter: (ccId, accountIds) =>
    del(`/api/v1/costcenter/${ccId}/accounts`, { ids: accountIds }, config),
  getCostCenterSubscriptions: ccId => get(`/api/v1/costcenter/${ccId}/subscriptions`, config),
  assignSubscriptionsToCostCenter: (ccId, subscriptionIds) =>
    post(`/api/v1/costcenter/${ccId}/subscriptions`, { ids: subscriptionIds }, config),
  removeSubscriptionsFromCostCenter: (ccId, subscriptionIds) =>
    del(`/api/v1/costcenter/${ccId}/subscriptions`, { ids: subscriptionIds }, config),
  getCostCenterUsers: ccId => get(`/api/v1/costcenter/${ccId}/users`, config),
  assignUsersToCostCenter: (ccId, userIds) => post(`/api/v1/costcenter/${ccId}/users`, { ids: userIds }, config),
  removeUsersFromCostCenter: (ccId, userIds) => del(`/api/v1/costcenter/${ccId}/users`, { ids: userIds }, config),
  downloadAssignments: (handleFailure, setWaitingState) =>
    getDownload(`/api/v1/costcenter/assignments`, config, handleFailure, setWaitingState),
  uploadAssignments: file => post(`/api/v1/costcenter/assignments/upload`, toFormData(file), config),
  applyAssignments: dto => post(`/api/v1/costcenter/assignments/apply`, dto, config),
  getInvoicePeriods: accountName => get(`/api/v1/invoice/periods?${toQueryParams({ accountName })}`, config),
  getAllInvoicePeriods: () => get(`/api/v1/invoice/periods`, config),
  getYears: () => get('/api/v1/invoice/years', config),
  getAccounts: () => get('/api/v1/account', config),
  getUser: () => get('/api/v1/user', config),
  getDrilldownLevels: () => get('/api/v1/query/levels', config),
  getFields: (entity, fieldProperty) =>
    get(`/api/v1/query/entities/${entity}/fields${fieldProperty ? `?p=${fieldProperty}` : ''}`, config),
  getFieldValues: (entity, field) => get(`/api/v1/query/entities/${entity}/fields/${field}/values`, config),
  updateFieldStyles: (entity, updateFields) => post(`/api/v1/query/entities/${entity}/fields`, updateFields, config),
  query: queryObject => post('/api/v1/query', queryObject, config),
  chartQuery: queryObject => post('/api/v1/query/chart', queryObject, config),
  createTableExportFile: (queryObject, format, fileName) =>
    post(`/api/v1/query/export/${format}?${toQueryParams({ fileName })}`, queryObject, config),
  downloadTableExportFile: (format, directoryName, handleFailure, setWaitingState) =>
    getDownload(`/api/v1/query/export/${format}/${directoryName}`, config, handleFailure, setWaitingState),
  getCustomReports: entity => get(`/api/v1/report/all/${entity}`, config),
  saveCustomReport: (entity, report) => post(`/api/v1/report/custom/${entity}`, report, config),
  deleteCustomReport: (entity, reportId) => del(`/api/v1/report/custom/${entity}/${reportId}`, undefined, config),
  sendCustomReport: (entity, reportId) => post(`/api/v1/report/custom/${entity}/${reportId}`, undefined, config),
  getSystemReports: entity => get(`/api/v1/report/system/${entity}`, config),
  getAllReports: entity => get(`/api/v1/report/all/${entity}`, config),
  getMailTemplate: name => get(`/api/v1/report/template?name=${name}`, config),
  getTagGroups: () => get(`/api/v1/configuration/taggroups`, config),
  getTagsOfTagGroup: tagGroupId => get(`/api/v1/configuration/taggroups/${tagGroupId}`, config),
  calculateValidBaseFields: dto => post(`/api/v1/configuration/basefields`, dto, config),
  getEntities: () => get(`/api/v1/query/entities`, config),
  uploadTagValues: (tagGroupId, file, format) =>
    post(`/api/v1/configuration/taggroups/${tagGroupId}/upload?format=${format}`, toFormData(file), config),
  downloadAllTagValues: (handleFailure, setWaitingState) =>
    getDownload('/api/v1/configuration/taggroups/download', config, handleFailure, setWaitingState),
  downloadTagValues: (tagGroupId, format, handleFailure, setWaitingState) =>
    getDownload(
      `/api/v1/configuration/taggroups/${tagGroupId}/download?format=${format}`,
      config,
      handleFailure,
      setWaitingState
    ),
  deleteTagGroup: tagGroupId => del(`/api/v1/configuration/taggroups/${tagGroupId}`, undefined, config),
  deleteTag: (tagGroupId, tagId) =>
    del(`/api/v1/configuration/taggroups/${tagGroupId}/tags/${tagId}`, undefined, config),
  createTagGroup: dto => post(`/api/v1/configuration/taggroups`, dto, config),
  updateTagGroup: (tagGroupId, dto) => patch(`/api/v1/configuration/taggroups/${tagGroupId}`, dto, config),
  addTags: (tagGroupId, dto) => post(`/api/v1/configuration/taggroups/${tagGroupId}/tags`, dto, config),
  getBaseValues: (tagGroupId, baseField) =>
    get(`/api/v1/configuration/taggroups/${tagGroupId}/basevalues/${baseField}`, config),
  updateTagValue: (tagGroupId, tagId, value) =>
    patch(`/api/v1/configuration/taggroups/${tagGroupId}/tags/${tagId}`, { value }, config),
  downloadInvoice: (entityName, accountId, invoiceNo, handleFailure, setWaitingState) =>
    postDownload(
      '/api/v1/invoice/download',
      { entityName, accountId, invoiceNo },
      config,
      handleFailure,
      setWaitingState
    ),
  downloadManual: (handleFailure, setWaitingState) =>
    postDownload('/api/v1/manual', {}, config, handleFailure, setWaitingState),
  getDistinctValues: (queryObject, filterField) =>
    post('/api/v1/report/filtervalues', { queryObject, filterField }, config),
  getViews: () => get('/api/v1/query/views', config),
  getAdminViews: () => get('/api/v1/query/adminViews', config),
  getAdditionalProfileMenuItems: () => get('/api/v1/query/profileMenuItems', config),
  checkHeartbeat: (timeout: number) => get('/api/v1/health', config, timeout),
  getStatusMessage: () => get(`/api/v1/statusmessage`, config),
  setStatusMessage: dto => post('/api/v1/statusmessage', dto, config),
  getStatusMessageText: () => get('/api/v1/statusmessage/text', config),
  logError: (dto: EnhancedErrorDTO, timeout?: number) => post('/api/v1/log', dto, config, timeout),
  // admin
  searchCustomers: search => get(`/adminapi/v1/support/customers?q=${search}`, config),
  searchUsers: search => get(`/adminapi/v1/support/users?q=${search}`, config),
  getPrivilegeLevels: () => get(`/adminapi/v1/support/privilegelevels`, config),
  createUser: dto => post('/adminapi/v1/support/users', dto, config),
  updateUser: (userId, dto) => post(`/adminapi/v1/support/users/${userId}`, dto, config),
  deleteUser: userId => del(`/adminapi/v1/support/users/${userId}`, undefined, config),
  searchAccounts: search => get(`/adminapi/v1/support/accounts?q=${search}`, config),
  searchSubscriptions: search => get(`/adminapi/v1/support/subscriptions?q=${search}`, config),
  searchInvoices: search => get(`/adminapi/v1/support/invoices?q=${search}`, config),
  searchReports: search => get(`/adminapi/v1/support/reports?q=${search}`, config),
  loginAs: (entity, id) => post(`/auth/loginas`, { entity, id }, config),
  getBlacklistCustomers: () => get('/adminapi/v1/blacklist', config),
  addBlacklistCustomer: ids => post('/adminapi/v1/blacklist', ids, config),
  removeBlacklistCustomer: ids => del('/adminapi/v1/blacklist', ids, config),
  getJobs: () => get('/adminapi/v1/job', config),
  executeJob: (jobName, jobGroup) => post('/adminapi/v1/job', { jobName, jobGroup }, config),
  getApplicationStatistics: params => get(`/adminapi/v1/statistics/application?${toQueryParams(params)}`, config),
  getUserStatistics: params => get(`/adminapi/v1/statistics/user?${toQueryParams(params)}`, config),
  getAdminStatistics: params => get(`/adminapi/v1/statistics/admin?${toQueryParams(params)}`, config),
  getInstances: () => get('/adminapi/v1/statistics/instances', config),
  getStatisticsCategories: () => get('/adminapi/v1/statistics/categories', config),
  getApplicationStatisticsSeries: () => get('/adminapi/v1/statistics/series', config),
  getApplicationStatisticsGrouping: () => get('/adminapi/v1/statistics/groups', config),
  getAdministrator: () => get('/adminapi/v1/administrator', config),
  getAdministrators: () => get('/adminapi/v1/administrators', config),
  getAdminCustomers: adminId => get(`/adminapi/v1/administrators/${adminId ?? '-'}/customers`, config),
  getAdminBlacklistCustomers: adminId =>
    get(`/adminapi/v1/administrators/${adminId ?? '-'}/blacklistcustomers`, config),
  createAdministrator: dto => post('/adminapi/v1/administrators', dto, config),
  updateAdministrator: (adminId, dto) => patch(`/adminapi/v1/administrators/${adminId}`, dto, config),
  deleteAdministrator: adminId => del(`/adminapi/v1/administrators/${adminId}`, undefined, config),
  triggerCrmSync: dto => post('/adminapi/v1/crm', dto, config),
});
