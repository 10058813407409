import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export const loadingTabState = atom<string>({
  key: 'loadingTab',
  default: '',
});

export const useSetLoadingTabState = () => useSetRecoilState(loadingTabState);

export const useUnsetLoadingTabState = () => {
  const setLoadingState = useSetRecoilState(loadingTabState);

  return () => {
    setLoadingState('');
  };
};

export const useGetLoadingTabState = () => useRecoilValue(loadingTabState);

export const useLoadingTabState = () => useRecoilState(loadingTabState);

export const loadingAnalysisState = atom<string>({
  key: 'loadingAnalysis',
  default: '',
});

export const useUnsetLoadingAnalysisState = (): [string, () => void] => {
  const [loadingAnalysisState, setLoadingAnalysisState] = useLoadingAnalysisState();

  const unsetLoadingAnalysisState = () => {
    setLoadingAnalysisState('');
  };

  return [loadingAnalysisState, unsetLoadingAnalysisState];
};

export const useLoadingAnalysisState = () => useRecoilState(loadingAnalysisState);
