import React, { useCallback, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';
import AddIcon from '@mui/icons-material/Add';
import ContentCard from '../ContentCard';
import DataGrid from '../DataGrid';
import { ActionButton, IconButton } from '../Button';
import { useAdministrators, useApi, useRefreshAdministrators } from '../../globalState';
import { AdministratorDTO } from '../../dto';
import AdministratorDialog from './AdministratorDialog';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import { checkForApiError, displayName } from '../../util';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { toError, useSetEncounteredError } from '../Error';
import { ActionStack } from '../Stack';

interface RowType extends AdministratorDTO, GridValidRowModel {
  rowId: number;
}

const AdministratorTable: React.FC = () => {
  const administrators = useAdministrators();
  const refreshAdministrators = useRefreshAdministrators();
  const api = useApi();
  const setEncounteredError = useSetEncounteredError();
  const { t } = useTranslation();
  const [editing, setEditing] = useState<{ open: boolean; admin?: AdministratorDTO }>({ open: false });
  const [deleting, setDeleting] = useState<{ open: boolean; admin?: AdministratorDTO }>({ open: false });

  const handleAdd = useCallback(() => setEditing({ open: true }), [setEditing]);
  const handleEditClose = useCallback(() => setEditing({ open: false }), [setEditing]);
  const handleDeleteCancel = useCallback(() => setDeleting({ open: false }), []);
  const handleDeleteConfirm = useCallback(async () => {
    try {
      const response = await api.deleteAdministrator((deleting.admin as AdministratorDTO).id);
      checkForApiError(response);
      refreshAdministrators();
    } catch (err) {
      setEncounteredError(
        t('admin.administrators.administrators.error', { action: t('action.delete') }),
        toError(err),
        { api: 'deleteAdministrator', deleteId: (deleting.admin as AdministratorDTO).id }
      );
    } finally {
      setDeleting({ open: false });
    }
  }, [api, deleting.admin, refreshAdministrators, setEncounteredError, t]);
  const rows = useMemo(() => administrators.map((a, i) => ({ rowId: `${i + 1}`, ...a })), [administrators]);
  const columns: GridColDef<RowType>[] = useMemo(() => {
    return [
      {
        field: '__ACTIONS__',
        headerName: '',
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        width: 80,
        renderCell: ({ row }) => (
          <ActionStack>
            <IconButton onClick={() => setEditing({ open: true, admin: row })}>
              <EditOutlined fontSize="small" />
            </IconButton>
            <IconButton onClick={() => setDeleting({ open: true, admin: row })}>
              <DeleteOutlined fontSize="small" />
            </IconButton>
          </ActionStack>
        ),
      },
      {
        field: 'name',
        headerName: t('admin.administrators.administrators.table.name'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'firstName',
        headerName: t('admin.administrators.administrators.table.firstName'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'lastName',
        headerName: t('admin.administrators.administrators.table.lastName'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'mail',
        headerName: t('admin.administrators.administrators.table.mail'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'systemAdministrator',
        headerName: t('admin.administrators.administrators.table.systemAdministrator'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
        valueFormatter: ({ value }) => (!!value ? t('dialog.yes') : t('dialog.no')),
      },
      {
        field: 'assignedCustomers',
        headerName: t('admin.administrators.administrators.table.assignedCustomers'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'assignedBlackListCustomers',
        headerName: t('admin.administrators.administrators.table.assignedBlackListCustomers'),
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        flex: 1,
      },
    ];
  }, [t]);

  return (
    <ContentCard flexGrow={1} padding={0}>
      <Stack direction="row" gap={3} marginTop={4}>
        <ActionButton variant="outlined" startIcon={<AddIcon />} onClick={handleAdd}>
          {t('admin.administrators.administrators.addAdministratorButton')}
        </ActionButton>
      </Stack>
      <DataGrid
        columns={columns as never}
        rows={rows}
        hideFooter={true}
        columnHeaderHeight={36}
        rowHeight={30}
        disableRowSelectionOnClick={true}
        customMarginTop={4}
      />
      {editing.open && <AdministratorDialog admin={editing.admin} onClose={handleEditClose} />}
      {deleting.open && (
        <ConfirmationDialog
          title={t('admin.administrators.administrators.confirmDeleting.title')}
          text={t('admin.administrators.administrators.confirmDeleting.text', { name: deleting.admin?.name })}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
    </ContentCard>
  );
};

displayName(AdministratorTable, 'AdministratorTable');

export default AdministratorTable;
