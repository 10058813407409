import React from 'react';
import TransferList, { Change, ColumnDef } from '../TransferList';
import { useCostCenterContext } from '../../pages/user/CostCenters';
import { useApi, useCostCenterUsers, useRefreshAllCostCenters } from '../../globalState';
import { CostCenterUserDTO } from '../../dto';
import { checkForApiError, displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import { toError, useSetEncounteredError } from '../Error';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { ActionButton } from '../Button';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useFlags } from '../../TenantContext';

const CostCenterUsers: React.FC = () => {
  const api = useApi();
  const { t } = useTranslation();
  const { selectedCostCenter } = useCostCenterContext();
  const { externalUserCreation } = useFlags();
  const setEncounteredError = useSetEncounteredError();
  const { availableUsers, assignedUsers } = useCostCenterUsers(selectedCostCenter.costCenterId);
  const refreshAllCostCenters = useRefreshAllCostCenters();
  const theme = useTheme();

  const columns: ColumnDef[] = [
    {
      field: 'lastName',
      headerName: t('costCenters.definition.responsible.lastName'),
    },
    {
      field: 'firstName',
      headerName: t('costCenters.definition.responsible.name'),
    },
    {
      field: 'level',
      headerName: t('costCenters.definition.responsible.level'),
    },
  ];
  const updateCostCenter = async ({ added, removed }: Change<CostCenterUserDTO>) => {
    try {
      if (added.length > 0) {
        const response = await api.assignUsersToCostCenter(
          selectedCostCenter.costCenterId,
          added.map(u => u.id)
        );
        checkForApiError(response);
        refreshAllCostCenters();
      } else if (removed.length > 0) {
        const response = await api.removeUsersFromCostCenter(
          selectedCostCenter.costCenterId,
          removed.map(u => u.id)
        );
        checkForApiError(response);
        refreshAllCostCenters();
      }
    } catch (err) {
      setEncounteredError(
        added.length > 0 ? t('costCenters.users.error.add') : t('costCenters.users.error.remove'),
        toError(err),
        { action: added.length > 0 ? 'assignUsersToCostCenter' : 'removeUsersFromCostCenter' }
      );
    }
  };

  return (
    <>
      <Stack sx={{ alignItems: 'center' }} direction="row" gap={10}>
        {externalUserCreation() && (
          <ActionButton
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => window.open(t('costCenters.users.addUser.link'))}
          >
            {t('costCenters.users.addUser.label')}
          </ActionButton>
        )}
        <Box gap={2} display={'flex'} alignItems={'center'}>
          <InfoOutlinedIcon sx={{ fill: theme.palette.info.main, fontSize: '16px' }} />
          <Typography sx={{ color: theme.palette.info.main, fontSize: '14px' }}>
            {t('costCenters.users.info')}
          </Typography>
        </Box>
      </Stack>
      <TransferList
        marginTop={4}
        columns={columns}
        availableTitle={t('costCenters.users.availableTitle')}
        availableItems={availableUsers}
        assignedTitle={t('costCenters.assignedTitle', { selectedCostCenterName: selectedCostCenter.name })}
        assignedItems={assignedUsers}
        onChange={updateCostCenter}
      />
    </>
  );
};

displayName(CostCenterUsers, 'CostCenterUsers');

export default CostCenterUsers;
