import React, { Suspense } from 'react';
import { Box, Stack, Tooltip, useTheme } from '@mui/material';
import Spinner from './components/Spinner';
import { displayName } from './util';
import WifiTetheringErrorIcon from '@mui/icons-material/WifiTetheringError';
import { useHeartbeatFailure } from './components/Error';
import { useTranslation } from 'react-i18next';
import { useChangingLanguage } from './i18nContext';
import { useImages } from './ImagesContext';

interface Props {
  menu: React.ReactElement;
  navigation: React.ReactElement;
  children?: React.ReactNode;
}

const MainLayout: React.FC<Props> = ({ menu, navigation, children }) => {
  const heartbeatFailure = useHeartbeatFailure();
  const changingLanguage = useChangingLanguage();
  const { t } = useTranslation();
  const theme = useTheme();
  const { getImage } = useImages();

  return (
    <Stack direction="column" padding={4} height="100%" boxSizing="border-box">
      <Stack direction="row" justifyContent={'space-between'} height={theme => theme.spacing(6)}>
        {navigation}
        {heartbeatFailure && (
          <Stack alignSelf={'center'}>
            <Tooltip title={t('backgroundWorker.heartbeatFailure')}>
              <WifiTetheringErrorIcon fontSize={'large'} sx={{ color: theme.palette.error.main, marginLeft: '10px' }} />
            </Tooltip>
          </Stack>
        )}
        <Stack direction="row" width="388px" height={theme => theme.spacing(6)} flexShrink={0} justifyContent="right">
          <Box component="img" src={getImage('logo')} width="190px" height="67px" marginRight={10} />
          {changingLanguage ? <Spinner /> : menu}
        </Stack>
      </Stack>
      <Suspense fallback={<Spinner />}>{children}</Suspense>
    </Stack>
  );
};

displayName(MainLayout, 'MainLayout');

export default MainLayout;
