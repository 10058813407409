import React, { useEffect, useMemo } from 'react';
import { displayName } from '../../../util';
import { GridColumnsManagement } from './GridsColumnsManagement';
import { useAllAnalysisTableFields } from '../../../globalState';
import { fromPairs } from 'lodash';
import { GridPanelWrapper, useGridRootProps, GridColumnsPanelProps } from '@mui/x-data-grid-pro';
import { useSetColumnPanelOrderModel, useSetColumnPanelVisibilityModel } from '../../../globalState/analysis';

export const CustomColumnsPanel: React.FC<GridColumnsPanelProps> = props => {
  const rootProps = useGridRootProps();
  const allFields = useAllAnalysisTableFields();
  const setColumnPanelVisibilityModel = useSetColumnPanelVisibilityModel();
  const setColumnPanelOrderModel = useSetColumnPanelOrderModel();

  const initialColumnVisibilityModel = useMemo(() => {
    return fromPairs(allFields.map(f => [f.name, f.visible]));
  }, [allFields]);

  const initialColumnOrderModel = useMemo(() => {
    return allFields.map(f => f.name);
  }, [allFields]);

  useEffect(() => {
    setColumnPanelVisibilityModel(initialColumnVisibilityModel);
    setColumnPanelOrderModel(initialColumnOrderModel);
  }, [initialColumnOrderModel, initialColumnVisibilityModel, setColumnPanelOrderModel, setColumnPanelVisibilityModel]);

  return (
    <GridPanelWrapper {...props}>
      <GridColumnsManagement
        initialColumnVisibilityModel={initialColumnVisibilityModel}
        initialColumnOrderModel={initialColumnOrderModel}
        {...rootProps.slotProps?.columnsPanel}
      />
    </GridPanelWrapper>
  );
};

displayName(CustomColumnsPanel, 'CustomColumnsPanel');
