import React, { useCallback, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { Stack, Typography } from '@mui/material';
import SubmitButton from '../../components/formik/SubmitButton';
import { useApi } from '../../globalState';
import Text from '../../components/Text';
import { useLanguage } from '../../i18nContext';
import { displayName } from '../../util';
import useDocumentTitle from '../../useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../components/Button';
import { useNavigate } from 'react-router';
import { InformationText, useCreateError, useLogError, ValidationError } from '../../components/Error';
import { PageCard } from '../../components/Container';
import FormikTextInput from '../../components/formik/FormikTextInput';
import { SuspendedSpinner } from '../../components/Spinner';

interface FormData {
  identification: string;
}

type ValidationErrors = Partial<Record<keyof FormData, ValidationError>>;

const ForgottenPassword: React.FC = () => {
  const { t } = useTranslation();
  const api = useApi();
  const language = useLanguage();
  const navigate = useNavigate();
  const createError = useCreateError();
  const logError = useLogError();
  const [informationText, setInformationText] = useState<InformationText>();
  const [suspended, setSuspended] = useState(false);

  useDocumentTitle(t('application.shortName') + ' - ' + t('forgottenPassword.title'));
  const initialData: FormData = useMemo(() => {
    return {
      identification: '',
    };
  }, []);
  const validate = (values: FormData): ValidationErrors => {
    const errors: ValidationErrors = {};
    if (!values.identification || values.identification.trim().length === 0) {
      errors.identification = { message: t('changePassword.validation.emptyField') };
    }
    return errors;
  };
  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        setSuspended(true);
        const response = await api.forgottenPassword(values.identification);
        setSuspended(false);
        if (response.data?.status === 'OK') {
          setInformationText({ message: t('forgottenPassword.information.success'), status: 'success' });
        } else {
          setInformationText({ message: t('forgottenPassword.information.failure'), status: 'error' });
          await logError(createError(response.error));
        }
      } catch (error) {
        setInformationText({ message: t('forgottenPassword.information.failure'), status: 'error' });
        await logError(createError(error));
      }
    },
    [api, createError, logError, t]
  );
  const onBackClick = useCallback(() => {
    navigate(`/${language}/login`);
  }, [language, navigate]);

  return (
    <Stack direction="column" alignItems="center">
      <Stack height={theme => theme.spacing(10)} flexShrink={0} />
      <PageCard data-cy="login">
        <Stack direction="column" padding={4}>
          <Typography variant="h1">{t('application.title')}</Typography>
          <Stack marginTop={3}>
            <Text weight="700">{t('forgottenPassword.title')}</Text>
          </Stack>
          <Stack marginTop={3}>
            <Typography>{t('forgottenPassword.description')}</Typography>
          </Stack>
          <Formik initialValues={initialData} validate={validate} onSubmit={handleSubmit}>
            <Stack direction="column" marginTop={4}>
              <Form>
                <FormikTextInput
                  label={t('forgottenPassword.label.identification')}
                  fieldName="identification"
                  type="text"
                  autoFocus={true}
                  boldLabel={true}
                />
              </Form>
              {informationText && (
                <Stack marginTop={4}>
                  <Typography
                    sx={{
                      color: theme =>
                        informationText?.status === 'error' ? theme.palette.error.main : theme.palette.success.main,
                    }}
                    data-cy="errorMessage"
                  >
                    {informationText.message}
                  </Typography>
                </Stack>
              )}
              <SuspendedSpinner suspended={suspended} />
              <Stack direction="row" justifyContent="space-between" marginTop={4} data-cy="action">
                <ActionButton variant="outlined" onClick={onBackClick}>
                  {t('forgottenPassword.label.back')}
                </ActionButton>
                <SubmitButton id="submit">{t('forgottenPassword.label.reset')}</SubmitButton>
              </Stack>
            </Stack>
          </Formik>
        </Stack>
      </PageCard>
    </Stack>
  );
};

displayName(ForgottenPassword, 'ForgottenPassword');

export default ForgottenPassword;
