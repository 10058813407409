import React, { useCallback, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { Backdrop, Stack, Typography } from '@mui/material';
import SubmitButton from '../formik/SubmitButton';
import { useApi } from '../../globalState';
import { displayName, usePasswordValidation } from '../../util';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../Button';
import { InformationText, useCreateError, useLogError, ValidationError } from '../Error';
import { PageCard } from '../Container';
import { UList, UListItem } from '../List';
import FormikTextInput from '../formik/FormikTextInput';

interface FormData {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

type ValidationErrors = Partial<Record<keyof FormData, ValidationError>>;

interface Props {
  open: boolean;
  handleClose: () => void;
}

const ChangePasswordOverlay: React.FC<Props> = ({ open, handleClose }) => {
  const api = useApi();
  const validatePassword = usePasswordValidation();
  const createError = useCreateError();
  const logError = useLogError();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<InformationText>();

  const initialData: FormData = useMemo(() => {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
  }, []);
  const validate = (values: FormData): ValidationErrors => {
    const errors: ValidationErrors = {};
    if (!values.currentPassword || values.currentPassword.trim().length === 0) {
      errors.currentPassword = { message: t('changePassword.validation.emptyField') };
    }
    if (!values.newPassword || values.newPassword.trim().length === 0) {
      errors.newPassword = { message: t('changePassword.validation.emptyField') };
    }
    if (!values.confirmPassword || values.confirmPassword.trim().length === 0) {
      errors.confirmPassword = { message: t('changePassword.validation.emptyField') };
    } else if (values.confirmPassword !== values.newPassword) {
      errors.newPassword = {};
      errors.confirmPassword = { message: t('changePassword.validation.unequalPasswords') };
    } else {
      const weakPasswordMessage = validatePassword(values.newPassword);
      if (weakPasswordMessage) {
        errors.newPassword = {};
        errors.confirmPassword = { message: weakPasswordMessage };
      }
    }
    return errors;
  };
  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        const response = await api.changePassword(values.currentPassword, values.newPassword);
        if (response.data?.status === 'OK') {
          setErrorMessage({ message: t('changePassword.information.success'), status: 'success' });
        } else {
          if (response.error?.message === 'wrongCredentials' || response.error?.message === 'Unauthorized') {
            setErrorMessage({ message: t('changePassword.information.failure.unauthorized'), status: 'error' });
          } else {
            setErrorMessage({ message: t('changePassword.information.failure.serverError'), status: 'error' });
          }
          logError(createError(response.error));
        }
      } catch (error) {
        setErrorMessage({ message: t('error.login.serverError'), status: 'error' });
        await logError(createError(error));
      }
    },
    [api, createError, logError, t]
  );

  return (
    <Backdrop open={open}>
      <Stack direction="row" alignItems="center">
        <Stack height={theme => theme.spacing(10)} flexShrink={0} />
        <PageCard>
          <Stack direction="column" padding={4}>
            <Stack>
              <Typography variant={'h2'}>{t('changePassword.title')}</Typography>
            </Stack>
            <Stack marginTop={3}>
              <Typography>{t('changePassword.requirements.text')}</Typography>
              <UList>
                <UListItem text={t('changePassword.requirements.list.1')} />
                <UListItem text={t('changePassword.requirements.list.2')} />
                <UListItem text={t('changePassword.requirements.list.3')} />
                <UListItem text={t('changePassword.requirements.list.4')} />
              </UList>
            </Stack>
            <Formik initialValues={initialData} validate={validate} onSubmit={handleSubmit}>
              <Stack direction="column" gap={0} marginTop={3}>
                <Form>
                  <FormikTextInput
                    type="password"
                    fieldName="currentPassword"
                    label={t('changePassword.label.password.current')}
                    boldLabel={true}
                  />
                  <Stack flexGrow={1} sx={{ minHeight: '20px' }} />
                  <FormikTextInput
                    label={t('changePassword.label.password.new')}
                    fieldName="newPassword"
                    fieldCypressId="newPassword"
                    type="password"
                    boldLabel={true}
                  />
                  <FormikTextInput
                    label={t('changePassword.label.password.confirm')}
                    fieldName="confirmPassword"
                    fieldCypressId="confirmPassword"
                    type="password"
                    boldLabel={true}
                  />
                </Form>
                {errorMessage && (
                  <Stack marginTop={3}>
                    <Typography
                      sx={{
                        color: theme =>
                          errorMessage?.status === 'error' ? theme.palette.error.main : theme.palette.success.main,
                      }}
                      data-cy="errorMessage"
                    >
                      {errorMessage.message}
                    </Typography>
                  </Stack>
                )}
                <Stack direction="row" justifyContent="space-between" marginTop={3} data-cy="action">
                  <ActionButton variant="outlined" onClick={handleClose} sx={{ marginRight: '5px' }}>
                    {t('dialog.cancel')}
                  </ActionButton>
                  <SubmitButton id="submit">{t('changePassword.label.change')}</SubmitButton>
                </Stack>
              </Stack>
            </Formik>
          </Stack>
        </PageCard>
      </Stack>
    </Backdrop>
  );
};

displayName(ChangePasswordOverlay, 'ChangePasswordOverlay');

export default ChangePasswordOverlay;
