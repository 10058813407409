import React, { useCallback, useState } from 'react';
import { Checkbox, Stack } from '@mui/material';
import WizardStepLayout from '../WizardStepLayout';
import FormItem from '../FormItem';
import { displayName } from '../../util';
import { useFormikContext } from 'formik';
import { CustomReportData, ReportRecipient, SendEmailButton } from './CustomReportWizard';
import Text from '../Text';
import { useTranslation } from 'react-i18next';
import FormikTextInput from '../formik/FormikTextInput';
import EmailStatusTable from './EmailStatusTable';
import { ReportRecipientDTO } from '../../dto';

interface Props {
  sendEmail: boolean;
  onSendEmailChange: (sendEmail: boolean) => void;
  isBatchMail: boolean;
  disabledSendEmail: boolean;
  triggerSendEmail: () => Promise<void>;
  batchField?: string;
  recipientDTOs?: ReportRecipientDTO[];
}

const CustomReportWizardStep1: React.FC<Props> = ({
  sendEmail,
  onSendEmailChange,
  isBatchMail,
  disabledSendEmail,
  triggerSendEmail,
  batchField,
  recipientDTOs,
}) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext<CustomReportData>();
  const [recipients] = useState<ReportRecipient[]>(
    recipientDTOs?.map((r, i) => ({
      ...r,
      id: `${i + 1}`,
      status: r.status ?? t('sidebar.customReport.step2.reportRecipient.new'),
    })) ?? []
  );

  const handleSendEmailChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFieldValue('dispatchType', checked ? 'SINGLE' : 'NONE');
      onSendEmailChange(checked);
    },
    [onSendEmailChange, setFieldValue]
  );

  return (
    <WizardStepLayout title={t('sidebar.customReport.step1.title')}>
      <Stack direction="column" gap={4}>
        <FormikTextInput
          label={t('sidebar.customReport.step1.nameLabel')}
          fieldName={'reportName'}
          textAlignEnd={true}
          boldLabel={true}
          textWidth={10}
          inputWidth={12}
          gap={4}
        />
        <FormItem label={t('sidebar.customReport.step1.dispatchLabel')}>
          <Checkbox checked={sendEmail} onChange={handleSendEmailChange} sx={{ padding: 0 }} />
          <Text>{t('sidebar.customReport.step1.dispatchText')}</Text>
        </FormItem>
        {sendEmail && (
          <FormItem>
            <SendEmailButton disabled={disabledSendEmail} triggerSendEmail={triggerSendEmail} />
          </FormItem>
        )}
        {sendEmail && recipientDTOs && recipientDTOs.length !== 0 && (
          <>
            <Text weight={'700'}>{t('sidebar.customReport.step1.informationLabel')}</Text>
            <EmailStatusTable isBatchMail={isBatchMail} batchField={batchField} recipients={recipients} />
          </>
        )}
      </Stack>
    </WizardStepLayout>
  );
};

displayName(CustomReportWizardStep1, 'CustomReportWizardStep1');

export default CustomReportWizardStep1;
