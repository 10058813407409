import React, { useMemo, useRef } from 'react';
import { useTheme } from '@mui/material';
import { displayName } from '../../util';
import { ChartDownloadComponent, DownloadContainer } from './ChartDownloadComponent';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ChartProps } from '../analysis/AnalysisChart';

interface AdditionalProps {
  filled: boolean;
}

const LineChart: React.FC<ChartProps & AdditionalProps> = ({ data, valueAxisLabel, valueFormatter, filled, title }) => {
  const theme = useTheme();

  const chart = useRef<HTMLDivElement | null>(null);
  const lineData = useMemo(() => data.map(item => ({ y: item.value, name: item.category })), [data]);
  const categories = useMemo(() => data.map(item => item.category), [data]);

  const options = {
    chart: {
      spacingTop: 15,
    },
    legend: {
      enabled: false,
    },
    colors: [theme.palette.primary.main],
    title: {
      text: title,
      style: {
        fontSize: theme?.typography.h1?.fontSize,
        fontWeight: theme?.typography.h1?.fontWeight,
        lineHeight: theme?.typography.h1?.lineHeight,
        fontFamily: theme?.typography.fontFamily,
      },
    },
    tooltip: {
      formatter: function (this: Highcharts.Point): string {
        const finalValue = this.y ? valueFormatter(this.y) : '0';
        return `<span>${valueAxisLabel}</span><br><span style="color:${this.color}">${this.key}: </span><b>${finalValue}</b>`;
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: theme?.typography.body1?.fontSize,
          fontWeight: theme?.typography.body1?.fontWeight,
          lineHeight: theme?.typography.body1?.lineHeight,
          fontFamily: theme?.typography.fontFamily,
        },
      },
    },
    yAxis: {
      title: {
        text: valueAxisLabel,
        style: {
          fontSize: theme?.typography.body1?.fontSize,
          fontWeight: theme?.typography.body1?.fontWeight,
          lineHeight: theme?.typography.body1?.lineHeight,
          fontFamily: theme?.typography.fontFamily,
        },
      },
      labels: {
        style: {
          fontSize: theme?.typography.body1?.fontSize,
          fontWeight: theme?.typography.body1?.fontWeight,
          lineHeight: theme?.typography.body1?.lineHeight,
          fontFamily: theme?.typography.fontFamily,
        },
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject): string {
          return typeof this.value == 'number' ? valueFormatter(this.value) : this.value;
        },
      },
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: [
          {
            enabled: true,
            distance: 20,
            style: {
              fontSize: theme?.typography.body1?.fontSize,
              lineHeight: theme?.typography.body1?.lineHeight,
              fontFamily: theme?.typography.fontFamily,
            },
            formatter: function (this: Highcharts.Point): string {
              return this.y ? valueFormatter(this.y) : '0';
            },
          },
        ],
      },
    },
    series: [
      {
        name: valueAxisLabel,
        data: lineData,
        type: filled ? 'area' : 'line',
      },
    ],
  };

  return (
    <DownloadContainer ref={chart}>
      <ChartDownloadComponent chart={chart} />
      <HighchartsReact highcharts={Highcharts} options={options} />
    </DownloadContainer>
  );
};

displayName(LineChart, 'LineChart');

export default LineChart;
