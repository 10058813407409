import React, { useEffect } from 'react';
import { Box, ToggleButtonGroup } from '@mui/material';
import { AnalysisViewType, useAnalysisTab } from '../../globalState';
import { displayName, useEntity } from '../../util';
import ToggleButton from '../ToggleButton';
import { useTranslation } from 'react-i18next';
import HeaderItem from '../HeaderItem';
import Spinner from '../Spinner';
import { useLoadingAnalysisState } from '../../globalState/loading';

export const useUpdateTabState = (targetTab: AnalysisViewType) => {
  const [tab, setTab] = useAnalysisTab(useEntity());
  useEffect(() => {
    if (tab !== targetTab) {
      setTab(targetTab);
    }
  }, [tab, setTab, targetTab]);
};

interface Props {
  analysisTab: string;
  handleToggleChanged: (_: React.MouseEvent, value: string) => void;
}

const AnalysisToggle: React.FC<Props> = ({ analysisTab, handleToggleChanged }) => {
  const { t } = useTranslation();
  const [loadingAnalysisState, setLoadingAnalysisState] = useLoadingAnalysisState();

  return (
    <HeaderItem label={t('analysis.header.view')}>
      <ToggleButtonGroup color="primary" exclusive={true} value={analysisTab} onChange={handleToggleChanged}>
        <ToggleButton value="table" onClick={() => setLoadingAnalysisState('table')}>
          <AnalysisToggleValue type="table" loadingChartPage={loadingAnalysisState} />
        </ToggleButton>
        <ToggleButton value="chart" onClick={() => setLoadingAnalysisState('chart')}>
          <AnalysisToggleValue type="chart" loadingChartPage={loadingAnalysisState} />
        </ToggleButton>
      </ToggleButtonGroup>
    </HeaderItem>
  );
};

displayName(AnalysisToggle, 'AnalysisToggle');

export default AnalysisToggle;

interface AnalysisToggleValueProps {
  type: 'chart' | 'table';
  loadingChartPage: string;
}

const AnalysisToggleValue: React.FC<AnalysisToggleValueProps> = ({ type, loadingChartPage }) => {
  const { t } = useTranslation();

  if (loadingChartPage === type) {
    return (
      <Box>
        <Spinner size={1.1} />
        <div style={{ display: 'hidden', height: 0, color: 'transparent', userSelect: 'none' }}>
          {t(`analysis.header.${type}`)}
        </div>
      </Box>
    );
  } else {
    return t(`analysis.header.${type}`);
  }
};
