import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Tab, Tabs } from '@mui/material';
import { displayName } from '../util';
import Spinner from './Spinner';
import { useGetLoadingTabState } from '../globalState/loading';

const StyledSpinner = styled(Spinner)`
  & > span {
    color: ${({ theme }) => theme.palette.tabSpinner.main};
  }
`;

const StyledTabs = styled(Tabs)`
  flex-shrink: 0;
  span.MuiTabs-indicator {
    display: none;
  }
`;

const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme.palette.text.primary};
  background: ${({ theme }) => theme.palette.grey['200']};
  text-transform: none;
  margin-right: 2px;
  &.Mui-selected {
    background: ${({ theme }) => theme.palette.primary.main};
    color: white;
  }
  &:hover {
    background: ${({ theme }) => theme.palette.hover.main};
    color: white;
  }
  &:active {
    background: ${({ theme }) => theme.palette.pressed.main};
    color: white;
  }
`;

export interface TabItem {
  label: string;
  path: string;
}

interface Props {
  tabs: TabItem[];
  path: string;
  onChange: (path: string) => Promise<[string, string]>;
}

const NavigationTabs: React.FC<Props> = ({ tabs, path, onChange }) => {
  const loadingTabState = useGetLoadingTabState();
  const [escapedPath, setEscapedPath] = useState(['', '']);

  const determineLabel = useCallback(
    (tab: TabItem) => {
      if (tab.path === escapedPath[0] && loadingTabState === escapedPath[1]) {
        return (
          <Box>
            <StyledSpinner size={'1.5'} />
            <div style={{ display: 'hidden', height: 0, color: 'transparent', userSelect: 'none' }}>{tab.label}</div>
          </Box>
        );
      } else {
        return tab.label;
      }
    },
    [loadingTabState, escapedPath]
  );

  return (
    <StyledTabs value={path} onChange={(_, path: string) => onChange(path).then(res => setEscapedPath(res))}>
      {tabs.map((tab, i) => (
        <StyledTab key={i} label={determineLabel(tab)} value={tab.path}></StyledTab>
      ))}
    </StyledTabs>
  );
};

displayName(NavigationTabs, 'NavigationTabs');

export default NavigationTabs;
