import { selector, useRecoilRefresher_UNSTABLE } from 'recoil';
import { OverviewDTO } from '../dto';
import { ApiResponse } from '../api';
import { apiClient } from './apiClient';
import { useLoadableApiValue } from './util';
import { newestInvoicePeriodState } from './invoice';
import { accountState } from './user';
import { clientLanguageState } from './telcobill';

const overviewQuery = selector<ApiResponse<OverviewDTO>>({
  key: 'overview',
  get: async ({ get }) => {
    const newestPeriod = get(newestInvoicePeriodState);
    const account = get(accountState);
    const lang = get(clientLanguageState);
    return await get(apiClient(lang)).getOverview(
      account.name + '',
      newestPeriod.id === 'all' ? undefined : newestPeriod.id
    );
  },
});

export const useOverview = () => useLoadableApiValue(overviewQuery);

export const useRefreshOverview = () => useRecoilRefresher_UNSTABLE(overviewQuery);
