import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, MenuItem, SelectChangeEvent, Stack, Typography } from '@mui/material';
import FilterPanel from '../filter/FilterPanel';
import DefaultSelect from '../Select';
import { IconButton } from '../Button';
import CustomReportWizard, { CustomReportData } from './CustomReportWizard';
import { keyBy } from 'lodash';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import { displayName, throwApiError, throwError, useEntity } from '../../util';
import { DeleteOutlined, EditOutlined, UndoOutlined } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTranslation } from 'react-i18next';
import { CustomReportDTO, ReportDispatchType, ReportRecipientDTO } from '../../dto';
import { toError, useSetEncounteredError } from '../Error';
import { useSetSuccessMessage } from '../Success';
import EmailStatusTable from './EmailStatusTable';
import { useQueryErrorMessage } from '../../globalState/reports';
import {
  MAX_CUSTOM_REPORT_NAME_LENGTH,
  MAX_ERROR_CUSTOM_REPORT_NAME_LENGTH,
  SELECT_TIMEOUT,
  useApi,
  useApplyReportWithoutEntity,
  useCustomReports,
  useDefaultReport,
  useRefreshCustomReports,
  useResetAndRefreshCustomReports,
  useSelectedCustomReport,
  useSetQueryErrorMessage,
} from '../../globalState';

interface WizardState {
  open: boolean;
  initialValues?: Partial<CustomReportData>;
}

export type SendingEmailProcessType = 'setup' | 'confirm';

export interface EmailInformation {
  id: number;
  reportName: string;
  batchField: string;
  recipients: ReportRecipientDTO[];
  dispatchType: ReportDispatchType;
}

export interface EmailProcessState {
  state: SendingEmailProcessType;
  emailInformation?: EmailInformation;
}

export interface EmailProcess {
  process: EmailProcessState;
}

const StyledMenuItem = styled(MenuItem)`
  &:hover {
    background-color: ${({ theme }) => theme.palette.hoverGrey.main} !important;
  }
  &:hover > * {
    color: ${({ theme }) => theme.palette.hover.main} !important;
  }
`;

const Reports: React.FC = () => {
  const customReports = useCustomReports();
  const defaultReport = useDefaultReport();
  const entity = useEntity();
  const applyReport = useApplyReportWithoutEntity();
  const setEncounteredError = useSetEncounteredError();
  const setSuccessMessage = useSetSuccessMessage();
  const api = useApi();
  const resetAndRefreshCustomReports = useResetAndRefreshCustomReports();
  const refreshCustomReports = useRefreshCustomReports();
  const setQueryErrorMessage = useSetQueryErrorMessage();
  const [customReport, setCustomReport] = useSelectedCustomReport();
  const [wizardState, setWizardState] = useState<WizardState>({ open: false });
  const [confirmingDeleting, setConfirmingDeleting] = useState(false);
  const [sendingEmailProcess, setSendingEmailProcess] = useState<EmailProcess>({
    process: { state: 'setup' },
  });
  const [selectedErrorReport, setSelectedErrorReport] = useState<CustomReportDTO | undefined>(undefined);
  const [confirmSendingEmail, setConfirmSendingEmail] = useState(false);
  const [confirmSendingEmailBody, setConfirmSendingEmailBody] = useState<React.ReactElement>(<></>);
  const [isCreatingReport, setIsCreatingReport] = useState(true);
  const [beforeEmailWasSentReportName, setBeforeEmailWasSentReportName] = useState<string | undefined>();
  const { t } = useTranslation();

  const customReportsById = useMemo(() => keyBy(customReports, 'id'), [customReports]);
  const customReportsByName = useMemo(() => keyBy(customReports, 'name'), [customReports]);

  const runCustomAnalysis = useCallback(
    (report: CustomReportDTO) =>
      setTimeout(() => {
        applyReport(report, report.queryObject).catch(console.error);
      }, SELECT_TIMEOUT),
    [applyReport]
  );

  useEffect(() => {
    //When an email is sent, all the reports are loaded once again. While sending an email, a report gets a new id, which is
    //why the previously selected report can no longer be found by the previous id. Thus, we save the name of the report
    //and then load the report by the name, hoping a second report with the same name does not exist.
    if (beforeEmailWasSentReportName !== undefined) {
      const previouslySelectedReport = customReports.filter(it => it.name === beforeEmailWasSentReportName)[0];
      setCustomReport(previouslySelectedReport);
      setBeforeEmailWasSentReportName(undefined);
    }
  }, [beforeEmailWasSentReportName, customReports, setCustomReport]);

  useEffect(() => {
    const confirmSendingEmailBody = sendingEmailProcess.process.emailInformation ? (
      <Stack gap={4}>
        <Typography component="div">
          <Box sx={{ fontWeight: 900, display: 'inline' }}>{t('sidebar.customReport.confirmSending.caution')}</Box>
          {t('sidebar.customReport.confirmSending.text', {
            name: sendingEmailProcess.process.emailInformation.reportName,
            recipientsCount: sendingEmailProcess.process.emailInformation.recipients.length,
          })}
        </Typography>
        <EmailStatusTable
          isBatchMail={sendingEmailProcess.process.emailInformation.dispatchType === 'BATCH'}
          batchField={sendingEmailProcess.process.emailInformation.batchField}
          recipients={
            sendingEmailProcess.process.emailInformation.recipients.map((r, i) => ({
              ...r,
              id: `${i + 1}`,
              status: r.status ?? t('sidebar.customReport.step2.reportRecipient.new'),
            })) ?? []
          }
        />
      </Stack>
    ) : (
      <></>
    );
    setConfirmSendingEmail(sendingEmailProcess.process.state === 'confirm');
    setConfirmSendingEmailBody(confirmSendingEmailBody);
  }, [t, sendingEmailProcess]);

  const handleUndo = useCallback(() => {
    applyReport(customReport, customReport.queryObject).catch(console.log);
  }, [applyReport, customReport]);
  const handleEdit = useCallback(() => {
    setWizardState({
      open: true,
      initialValues: {
        id: customReport.id,
        reportName: customReport.name,
        dispatchType: customReport.dispatchType ?? undefined,
        batchField: customReport.batchField ?? undefined,
        documentType: customReport.documentType ?? undefined,
        reportLanguage: customReport.language ?? undefined,
        emailAddresses: customReport.emailAddresses ?? undefined,
        customText: customReport.customText ?? undefined,
        zipPassword: customReport.password ?? undefined,
        encrypt: customReport.password ? ['encrypt'] : undefined,
        recipients: customReport.recipients,
        showDefaultText: customReport.showDefaultText,
      },
    });
    setIsCreatingReport(false);
  }, [setWizardState, customReport]);
  const handleDelete = useCallback(() => setConfirmingDeleting(true), [setConfirmingDeleting]);
  const handleDeleteCancel = useCallback(() => setConfirmingDeleting(false), [setConfirmingDeleting]);
  const handleConfirmSendingEmail = useCallback(() => {
    const asyncAction = async () => {
      try {
        if (sendingEmailProcess.process.emailInformation?.id) {
          const id = sendingEmailProcess.process.emailInformation?.id ?? -1;
          const response = await api.sendCustomReport(entity, id);
          setBeforeEmailWasSentReportName(customReport.name);
          refreshCustomReports();
          if (response.error === null && response.data !== undefined) {
            setSuccessMessage(t('sidebar.customReport.sendingEmail.success'));
          } else {
            throwApiError(response.error);
          }
        } else {
          throwError('The email information lacks an id.');
        }
      } catch (err) {
        setEncounteredError(t('sidebar.customReport.sendingEmail.failure'), toError(err), {
          api: 'sendCustomReport',
          id: sendingEmailProcess.process.emailInformation?.id ?? -1,
        });
      } finally {
        setConfirmSendingEmail(false);
        setSendingEmailProcess({ process: { state: 'setup' } });
      }
    };
    asyncAction().then();
  }, [
    api,
    customReport.name,
    entity,
    refreshCustomReports,
    sendingEmailProcess.process.emailInformation?.id,
    setEncounteredError,
    setSuccessMessage,
    t,
  ]);
  const handleCancelSendingEmail = useCallback(() => {
    setSendingEmailProcess({ process: { state: 'setup' } });
  }, []);

  const handleDeleteErrorReport = useCallback(async () => {
    try {
      if (selectedErrorReport) {
        await api.deleteCustomReport(entity, selectedErrorReport.id);
        refreshCustomReports();
      }
    } catch (error) {
      setEncounteredError(t('sidebar.customReport.error.deleting'), toError(error), {
        api: 'deleteCustomReport',
        reportId: customReport.id,
      });
    } finally {
      setSelectedErrorReport(undefined);
    }
  }, [selectedErrorReport, api, entity, refreshCustomReports, setEncounteredError, t, customReport.id]);
  const handleCancelDeleteErrorReport = useCallback(() => {
    setSelectedErrorReport(undefined);
  }, []);

  const deleteReport = useCallback(async () => {
    try {
      await api.deleteCustomReport(entity, customReport.id);
      resetAndRefreshCustomReports();
    } catch (error) {
      setEncounteredError(t('sidebar.customReport.error.deleting'), toError(error), {
        api: 'deleteCustomReport',
        reportId: customReport.id,
      });
    } finally {
      setConfirmingDeleting(false);
      await applyReport(defaultReport, defaultReport.queryObject);
    }
  }, [api, entity, customReport.id, resetAndRefreshCustomReports, setEncounteredError, t, applyReport, defaultReport]);

  const selectReportAction = (e: SelectChangeEvent<unknown>) => {
    const selectedReport = customReportsById[e.target.value + ''];
    if (selectedReport.errorMessage !== null) {
      setQueryErrorMessage(selectedReport.errorMessage);
      setSelectedErrorReport(selectedReport);
    } else {
      runCustomAnalysis(selectedReport);
    }
  };

  return (
    <FilterPanel name="reports" title={t('sidebar.customReport.title')} paddingBottom="8px">
      <Stack width="100%" spacing={3}>
        <Stack alignItems={'center'} sx={{ flexDirection: 'row', width: '100%' }}>
          <DefaultSelect
            sx={{ maxWidth: '216px' }}
            value={customReport?.id}
            onChange={e => selectReportAction(e)}
            fill={true}
            renderValue={selected => {
              let item: CustomReportDTO = customReports[0];
              if (customReports[0].id !== selected) {
                const foundItem = customReports.find(({ id: v }) => v === selected);
                if (foundItem !== undefined) {
                  item = foundItem;
                }
              }
              return (
                <Box display={'flex'}>
                  <ReportMenuItem
                    text={item?.name}
                    isSelect={true}
                    hasError={item.errorMessage !== null}
                    hasUser={item.user !== undefined}
                  />
                </Box>
              );
            }}
          >
            {customReports.map((report, i) => (
              <StyledMenuItem
                key={i}
                value={report.id}
                title={
                  report.errorMessage
                    ? t('sidebar.customReport.queryErrorTooltip', { errorMessages: report.errorMessage })
                    : undefined
                }
              >
                <ReportMenuItem
                  text={report.name}
                  isSelect={false}
                  hasError={report.errorMessage !== null}
                  hasUser={report.user !== undefined}
                />
              </StyledMenuItem>
            ))}
          </DefaultSelect>
          <IconButton
            disabled={!customReport.user}
            onClick={handleEdit}
            sx={{ marginLeft: '2px', width: '26px', height: '26px' }}
          >
            <EditOutlined fontSize="small" />
          </IconButton>
          <IconButton onClick={handleUndo} sx={{ marginLeft: '2px', width: '26px', height: '26px' }}>
            <UndoOutlined fontSize="small" />
          </IconButton>
          {wizardState.open && (
            <CustomReportWizard
              onClose={() => setWizardState({ open: false })}
              initialValues={wizardState.initialValues}
              setProcessState={setSendingEmailProcess}
              existingCustomReportNames={customReportsByName}
              creatingReport={isCreatingReport}
            />
          )}
          {confirmingDeleting && (
            <ConfirmationDialog
              title={t('sidebar.customReport.confirmDeleting.title')}
              text={t('sidebar.customReport.confirmDeleting.text', { analyse: customReport.name })}
              onConfirm={deleteReport}
              onCancel={handleDeleteCancel}
            />
          )}
          {confirmSendingEmail && (
            <ConfirmationDialog
              title={t('sidebar.customReport.confirmSending.title')}
              text={''}
              onConfirm={handleConfirmSendingEmail}
              onCancel={handleCancelSendingEmail}
              customButtonText={t('sidebar.customReport.confirmSending.buttonLabel')}
              customBody={confirmSendingEmailBody}
            />
          )}
          {selectedErrorReport && (
            <ConfirmationDialog
              title={t('sidebar.customReport.errorReport.title')}
              text={'test'}
              onConfirm={handleDeleteErrorReport}
              onCancel={handleCancelDeleteErrorReport}
              customButtonText={t('sidebar.customReport.errorReport.buttonLabel')}
              customBody={<ErrorMenuBody />}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={2}>
          <IconButton
            sx={{ width: '20px' }}
            onClick={() => {
              setIsCreatingReport(true);
              setWizardState({ open: true });
            }}
          >
            <AddCircleOutlineIcon fontSize="small" />
          </IconButton>
          <Typography sx={{ width: '80%' }}>{t('sidebar.customReport.createLabel')}</Typography>
          <IconButton disabled={!customReport.user} onClick={handleDelete} sx={{ width: '26px', height: '26px' }}>
            <DeleteOutlined fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>
    </FilterPanel>
  );
};

displayName(Reports, 'Reports');

export default Reports;

interface ReportMenuItemProps {
  text: string;
  isSelect: boolean;
  hasError: boolean;
  hasUser?: boolean;
}

const ReportMenuItem: React.FC<ReportMenuItemProps> = ({ text, isSelect, hasError, hasUser }) => {
  return hasError ? (
    <>
      <WarningAmberIcon sx={{ fill: 'orange', marginRight: '5px' }} />
      <ReportMenuTypography text={text} isSelect={isSelect} hasError={hasError} hasUser={hasUser} />
    </>
  ) : (
    <ReportMenuTypography text={text} isSelect={isSelect} hasError={hasError} hasUser={hasUser} />
  );
};

interface ReportMenuTypographyProps {
  text: string;
  isSelect: boolean;
  hasError: boolean;
  hasUser?: boolean;
}

const ReportMenuTypography: React.FC<ReportMenuTypographyProps> = ({ text, isSelect, hasError, hasUser }) => {
  const maxWidth = hasError && isSelect ? MAX_ERROR_CUSTOM_REPORT_NAME_LENGTH : MAX_CUSTOM_REPORT_NAME_LENGTH;

  return (
    <Typography
      sx={{
        maxWidth: `${maxWidth}ch`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {hasUser ? <>{text}</> : <em>{text}</em>}
    </Typography>
  );
};

const ErrorMenuBody: React.FC = () => {
  const { t } = useTranslation();
  const queryErrorMessage = useQueryErrorMessage();

  return (
    <>
      <Typography>{t('sidebar.customReport.errorReport.text1')}</Typography>
      <Typography sx={{ whiteSpace: 'pre-line' }}>
        {t('sidebar.customReport.errorReport.errorMessage', { errorMessages: queryErrorMessage })}
      </Typography>
      <Typography>{t('sidebar.customReport.errorReport.text2')}</Typography>
    </>
  );
};
