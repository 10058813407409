import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license';
import { displayName } from './util';
import { useTenant } from './TenantContext';
import { SUNRISE_THEME } from './themes/sunrise/SunriseTheme';
import { VERSATEL_THEME } from './themes/versatel/VersatelTheme';
import { TELENET_THEME } from './themes/telenet/TelenetTheme';

LicenseInfo.setLicenseKey(
  '8d5e17dfe7bd0f0f45af481ebaf1b0e7Tz0xMDg2NTAsRT0xNzcyMjM2Nzk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI='
);

interface ThemeProviderProps {
  children?: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const tenant = useTenant();

  let theme = SUNRISE_THEME;

  switch (tenant) {
    case 'sunrise':
      theme = SUNRISE_THEME;
      break;
    case 'versatel':
      theme = VERSATEL_THEME;
      break;
    case 'telenet':
      theme = TELENET_THEME;
      break;
    default:
      throw Error(`No theme exists for tenant: ${tenant}`);
  }

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

displayName(ThemeProvider, 'ThemeProvider');
