import React, { useEffect, useState } from 'react';
import { HEARTBEAT_INTERVAL_MILLISECONDS, MAX_FAILED_HEARTBEATS, useApi } from '../globalState';
import { toError, useCreateError, useLogError, useSetEncounteredError, useSetHeartbeatFailure } from './Error';
import { useTranslation } from 'react-i18next';
import { customToaster } from './Toast';
import { noop } from 'lodash';
import { useToErrorBoundary } from '../util';

export const HealthChecker: React.FC = () => {
  const api = useApi();
  const logError = useLogError();
  const createError = useCreateError();
  const setHeartbeatFailure = useSetHeartbeatFailure();
  const toErrorBoundary = useToErrorBoundary();
  const setEncounteredError = useSetEncounteredError();
  const [, setLastUpdatedTimestamp] = useState(Date.now());
  const [errorsEncountered, setErrorsEncountered] = useState(0);
  const [userIsNotified, setUserIsNotified] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const checkHeartbeat = async () => {
      try {
        const response = await api.checkHeartbeat(HEARTBEAT_INTERVAL_MILLISECONDS);
        if (response.error !== undefined && response.error !== null) {
          throw response.error;
        } else {
          setHeartbeatFailure(false);
          setErrorsEncountered(0);
          setUserIsNotified(false);
        }
      } catch (err) {
        const error = toError(err);

        if (error.message === 'Unauthorized') {
          setEncounteredError('Unauthorized', err, undefined, false);
          toErrorBoundary(error);
        }

        setErrorsEncountered(errorsEncountered + 1);
        if (!userIsNotified && errorsEncountered >= MAX_FAILED_HEARTBEATS) {
          try {
            await logError(
              createError(`${MAX_FAILED_HEARTBEATS} consecutive heartbeat failures`),
              HEARTBEAT_INTERVAL_MILLISECONDS / 2
            );
          } catch (err) {}
          setHeartbeatFailure(true);
          customToaster.error(t('backgroundWorker.heartbeatFailure'), true);
          setUserIsNotified(true);
        }
      } finally {
        const newTimestamp = Date.now();
        setLastUpdatedTimestamp(newTimestamp);
      }
    };
    const timer = setTimeout(() => checkHeartbeat().catch(noop), HEARTBEAT_INTERVAL_MILLISECONDS);
    return () => {
      clearTimeout(timer);
    };
  });

  return <></>;
};
