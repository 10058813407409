import { createTheme } from '@mui/material/styles';
import { deDE } from '@mui/x-data-grid-pro/locales';

export const VERSATEL_THEME = createTheme(
  {
    palette: {
      primary: {
        main: '#134095',
      },
      hover: {
        main: '#0F3376',
      },
      hoverGrey: {
        main: '#f1f1f1',
      },
      pressed: {
        main: '#335DA9',
      },
      disabled: {
        main: '#e4e3df',
      },
      secondary: {
        main: '#757575',
      },
      grey: {
        '100': '#F7F6F5',
        '200': '#E6E3DF',
        '300': '#B3AEAA',
        '400': '#86807C',
        '500': '#5F5955',
        '600': '#3D3935',
        '700': '#616161',
        '800': '#424242',
        '900': '#212121',
      },
      error: {
        main: '#BF0760',
      },
      success: {
        main: '#8FD200',
      },
      warning: {
        main: '#FFC805',
      },
      info: {
        main: '#2E6199', //Dark Blue Haze
      },
      background: {
        paper: '#FAFAFA',
      },
      text: {
        primary: '#3D3935',
      },
      tabSpinner: {
        main: '#ffffff ',
      },
    },
    table: {
      row: {
        height: '47px',
        even: 'white',
        odd: 'white',
      },
    },
    custom: {
      additionalFilters: {
        customHeight: 5,
      },
      chartColors: [
        '#134095',
        '#0d4168',
        '#252595',
        '#13955d',
        '#0d6841',
        '#955413',
        '#683b0d',
        '#95134d',
        '#680d36',
        '#958813',
        '#5f5955',
        '#e6e3df',
        '#86807c',
        '#f7f6f5',
      ],
    },
    typography: {
      fontFamily: 'IBM Plex Sans, Tahoma',
      h1: {
        fontSize: '34px',
        lineHeight: '42px',
        fontWeight: 'bold',
        color: '#3D3935',
      },
      h2: {
        fontSize: '20px',
        lineHeight: '32px',
        fontWeight: 'bold',
        color: '#3D3935',
      },
      button: {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: '500',
      },
      body1: {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 'normal',
        color: '#3D3935',
      },
    },
    spacing: (s: number) => (Number.isInteger(s) ? [0, 2, 4, 8, 16, 32, 48, 64, 80, 96, 128, 192, 240][s] : 16 * s),
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '14px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            height: '32px',
            fontSize: '14px',
            boxSizing: 'border-box',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: '4px 8px',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            padding: '0',
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            zIndex: 999,
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            lineHeight: '24px',
            padding: '6px 8px',
            fontWeight: 700,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '8px 16px',
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: '0px 16px 16px 16px ',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '25px',
          },
        },
      },
    },
  },
  deDE
);
