import React, { useMemo } from 'react';
import { GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import DataGrid from '../DataGrid';
import { displayName } from '../../util';
import { useTranslation } from 'react-i18next';
import { useAnalysisFilterFields } from '../../globalState';
import { keyBy } from 'lodash';
import { ReportRecipient } from './CustomReportWizard';
import { EMAIL_SEPARATOR_REGEX } from '../../globalState/variables';

interface Props {
  isBatchMail: boolean;
  batchField?: string;
  recipients: ReportRecipient[];
}

const EmailStatusTable: React.FC<Props> = ({ isBatchMail, batchField, recipients }) => {
  const filterFields = useAnalysisFilterFields()
    .fields.slice()
    .sort((a, b) => a.label.localeCompare(b.label));
  const filterFieldsByName = useMemo(() => keyBy(filterFields, 'name'), [filterFields]);
  const { t } = useTranslation();

  const splitRecipients = (recipient: ReportRecipient): ReportRecipient[] => {
    const splitEmails = recipient.email.split(EMAIL_SEPARATOR_REGEX);
    return splitEmails.map((e, i) => ({ ...recipient, email: e, id: `${e}-${i}` }));
  };

  const mappedRecipients = recipients.flatMap(r => splitRecipients(r));

  const columns = useMemo((): GridColDef[] => {
    if (isBatchMail && batchField) {
      return [
        {
          field: 'value',
          headerName: filterFieldsByName[batchField].label,
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
        },
        {
          field: 'email',
          headerName: t('sidebar.customReport.step2.reportRecipient.email'),
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
        },
        {
          field: 'status',
          headerName: t('sidebar.customReport.step2.reportRecipient.status'),
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
        },
      ];
    } else {
      return [
        {
          field: 'email',
          headerName: t('sidebar.customReport.step2.reportRecipient.email'),
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
        },
        {
          field: 'status',
          headerName: t('sidebar.customReport.step2.reportRecipient.status'),
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
        },
      ];
    }
  }, [batchField, filterFieldsByName, isBatchMail, t]);

  return (
    <DataGrid
      columns={columns}
      rows={mappedRecipients}
      columnHeaderHeight={36}
      rowHeight={30}
      hideFooter={true}
      disableRowSelectionOnClick={true}
      getRowId={(row: GridRowModel) => row.id}
    />
  );
};

displayName(EmailStatusTable, 'EmailStatusTable');

export default EmailStatusTable;
