import React, { useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Card, Stack } from '@mui/material';
import AnalysisChartHeader from './AnalysisChartHeader';
import { useAnalysisChart, useAnalysisGroupFields, useChartAggregateFields, useChartSeries } from '../../globalState';
import { keyBy } from 'lodash';
import moment from 'moment';
import { useUpdateTabState } from './AnalysisToggle';
import { displayName } from '../../util';
import { useGetAnalysisChartGroup, useGetAnalysisChartGroupCategory } from '../../globalState/analysis';
import { useTranslation } from 'react-i18next';
import PieChart from '../chart/PieChart';
import BarChart from '../chart/BarChart';
import LineChart from '../chart/LineChart';
import { ChartItemDTO } from '../../dto';
import { useUnsetLoadingAnalysisState } from '../../globalState/loading';
import Spinner from '../Spinner';
import { ErrorOverlay } from '../Overlay';

const Container = styled(Card)`
  margin-top: ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${({ theme }) => theme.palette.grey['200']};
`;

const ChartContainer = styled(Stack)`
  background: white;
  height: 10px;
`;

const AnalysisChart: React.FC = () => {
  const response = useAnalysisChart();
  const aggregateFields = useChartAggregateFields();
  const { series } = useChartSeries();
  const groupFields = useAnalysisGroupFields();
  const grouping = useGetAnalysisChartGroup();
  const categoryGrouping = useGetAnalysisChartGroupCategory();
  const { t } = useTranslation();
  const [loadingAnalysisState, unsetLoadingAnalysisState] = useUnsetLoadingAnalysisState();
  useUpdateTabState('chart');

  useEffect(() => {
    if (!response.loading) {
      if (loadingAnalysisState === 'chart') {
        unsetLoadingAnalysisState();
      }
    }
  }, [loadingAnalysisState, response.loading, unsetLoadingAnalysisState]);

  const aggregateFieldsByName = useMemo(() => keyBy(aggregateFields.fields, 'name'), [aggregateFields.fields]);
  const valueFormatter = useMemo(() => {
    const field = aggregateFieldsByName[series[0].select];

    switch (field.formattingType) {
      case 'TIME':
        return (value: number) => moment.utc(value).format('HH:mm:ss');
      case 'MONEY_DOUBLE':
      case 'MONEY':
        return (value: number) => `${value.toString()} CHF`;
      case 'MONEY_4':
        return (value: number) => `${value.toFixed(4)} CHF`;
      case 'MONEY_5':
        return (value: number) => `${value.toFixed(5)} CHF`;
      case 'DATA_3':
        return (value: number) => `${value.toFixed(3)}`;
      default:
        return (value: number) => value.toString();
    }
  }, [aggregateFieldsByName, series]);
  const valueAxisLabel =
    aggregateFieldsByName[series[0].select].unitLabel ?? aggregateFieldsByName[series[0].select].label;
  const groupNameToLabel = new Map<string, string>();
  groupFields.fields.map(it => groupNameToLabel.set(it.name, it.label));
  let addition = '';
  if (groupNameToLabel.has(grouping)) {
    addition += t('analysis.chart.title.groupedBy', { grouping: groupNameToLabel.get(grouping) });
  }
  if (categoryGrouping !== '--' && groupNameToLabel.has(categoryGrouping)) {
    addition += t('analysis.chart.title.categoryGrouping', {
      categoryGrouping: groupNameToLabel.get(categoryGrouping),
    });
  }
  const title = valueAxisLabel + addition;

  if (response.loading) {
    return <Spinner size={5} />;
  } else if (response.error || response.data === undefined) {
    return <ErrorOverlay error={response.error ?? { message: t('overlay.error') }} />;
  } else {
    return (
      <Container elevation={0}>
        <AnalysisChartHeader />
        <Stack flexGrow={1}>
          <ChartContainer direction="column" justifyContent="center" alignItems="center" flexGrow={1}>
            {series[0].type === 'barVertical' && (
              <BarChart
                orientation={'vertical'}
                data={response.data.items}
                valueAxisLabel={valueAxisLabel}
                valueFormatter={valueFormatter}
                title={title}
              />
            )}
            {series[0].type === 'barHorizontal' && (
              <BarChart
                orientation={'horizontal'}
                data={response.data.items}
                valueAxisLabel={valueAxisLabel}
                valueFormatter={valueFormatter}
                title={title}
              />
            )}
            {series[0].type === 'line' && (
              <LineChart
                data={response.data.items}
                valueAxisLabel={valueAxisLabel}
                valueFormatter={valueFormatter}
                filled={false}
                title={title}
              />
            )}
            {series[0].type === 'area' && (
              <LineChart
                data={response.data.items}
                valueAxisLabel={valueAxisLabel}
                valueFormatter={valueFormatter}
                filled={true}
                title={title}
              />
            )}
            {series[0].type === 'pie' && (
              <PieChart
                data={response.data.items}
                valueAxisLabel={valueAxisLabel}
                valueFormatter={valueFormatter}
                title={title}
              />
            )}
          </ChartContainer>
        </Stack>
      </Container>
    );
  }
};

displayName(AnalysisChart, 'AnalysisChart');

export default AnalysisChart;

type FormatterType = (value: number) => string;

export interface ChartProps {
  data: ChartItemDTO[];
  valueAxisLabel: string;
  valueFormatter: FormatterType;
  title?: string;
  className?: string;
}
