/* eslint-disable prettier/prettier */
import { ApiResponse, FieldPropertyType } from '../api';
import { apiClient, useApi } from './apiClient';
import { dataOrThrow, useLoadableApiValue } from './util';
import { useEntity } from '../util';
import { keyBy, noop, range } from 'lodash';
import { availableInvoicePeriodsQuery, descPeriodComparator, useAllUnorderedInvoicePeriods } from './invoice';
import moment from 'moment';
import { costCentersQuery } from './costcenter';
import { GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid-pro';
import { GridSortItem } from '@mui/x-data-grid/models/gridSortModel';
import { useMemo } from 'react';
import { clientLanguageState, viewsState } from './telcobill';
import { currentPageState } from './pagination';
import { ANALYSIS_TABLE_PAGE_SIZE } from './variables';
import { toError } from '../components';
import { createDetailedError } from '../components/Error';
import { useRefreshOverview } from './overview';
import {
  customReportsQuery,
  useApplyReportWithEntity,
  useApplyReportWithEntityAndQuery,
  useLoadDefaultReport,
  useLoadSelectedCustomReport,
} from './reports';
import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  SetterOrUpdater,
  useGetRecoilValueInfo_UNSTABLE,
  useRecoilCallback,
  useRecoilState,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  AnalysisChartDTO,
  ChartExportFormatType,
  ConditionElementDTO,
  CostCenterDTO,
  FieldDTO,
  FieldsDTO,
  FormattedValuesDTO,
  InvoicePeriodDTO,
  QueryMetaInformationDTO,
  QueryObjectDTO,
  QueryResultDTO,
  TableExportFormatType,
} from '../dto';

export type AnalysisViewType = 'table' | 'pivot' | 'chart';

const tabState = atomFamily<AnalysisViewType, string>({
  key: 'analysisTab',
  default: 'table',
});

export const useAnalysisTab = (entity: string) => useRecoilState(tabState(entity));

export const useGetAnalysisTab = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      (entity: string) =>
        snapshot.getPromise(tabState(entity))
  );

export const filterPanelState = atomFamily<boolean, string>({
  key: 'filterPanel',
  default: name => !(name === 'reports' || name.startsWith('additional-')),
});

export const useFilterPanel = (name: string) => useRecoilState(filterPanelState(name));

interface FieldQueryParams extends Readonly<Record<string, string | undefined>> {
  entity: string;
  property?: FieldPropertyType;
}

export const fieldsQuery = selectorFamily<FieldsDTO, FieldQueryParams>({
  key: 'defaultFieldsQuery',
  get:
    ({ entity, property }) =>
    async ({ get }) => {
      const lang = get(clientLanguageState);
      const result = await get(apiClient(lang)).getFields(entity, property);
      return dataOrThrow(result);
    },
});

export type GroupIndexType = '1' | '2' | '3';

interface GroupParams extends Readonly<Record<string, string>> {
  entity: string;
  index: GroupIndexType;
}

const defaultChartGroupQuery = selectorFamily<string, string>({
  key: 'defaultGroup',
  get:
    entity =>
    ({ get }) => {
      return get(fieldsQuery({ entity, property: 'group' })).fields[0].name ?? '';
    },
});

export const tableGroupState = atomFamily<string, GroupParams>({
  key: 'group',
  default: '--',
});

interface PivotGroupState {
  group1: string;
  group2: string;
  aggr: string;
}

const pivotAggrFieldsQuery = selectorFamily<FieldDTO[], string>({
  key: 'pivotAggrFields',
  get:
    entity =>
    ({ get }) => {
      return get(fieldsQuery({ entity, property: 'chartAggregate' })).fields;
    },
});

export const usePivotAggrFields = () => {
  const entity = useEntity();
  return useRecoilValue(pivotAggrFieldsQuery(entity));
};

const defaultPivotGroupQuery = selectorFamily<PivotGroupState, string>({
  key: 'defaultPivotGroup',
  get:
    entity =>
    ({ get }) => {
      const fields = get(fieldsQuery({ entity, property: 'group' })).fields;
      const aggrFields = get(pivotAggrFieldsQuery(entity));
      return { group1: fields?.[0]?.name ?? '', group2: fields?.[1]?.name ?? '', aggr: aggrFields[0].name };
    },
});

const pivotGroupState = atomFamily<PivotGroupState, string>({
  key: 'pivotGroup',
  default: defaultPivotGroupQuery,
});

export const usePivotGroup = () => {
  const entity = useEntity();
  return useRecoilState(pivotGroupState(entity));
};

const chartGroupState = atomFamily<string, string>({
  key: 'chartGroup',
  default: defaultChartGroupQuery,
});

const chartCategoryGroupState = atomFamily<string, string>({
  key: 'chartCategoryGroup',
  default: '--',
});

const filterToCondition = (filter: FilterState): ConditionElementDTO => {
  if (filter.field.filterType === 'textField' || filter.field.filterType === 'textFieldStartsWith') {
    return {
      type: 'field',
      conditionComparator: filter.field.filterType === 'textField' ? 'EQUAL_COMPARATOR' : 'LIKE_COMPARATOR',
      field: { entityName: filter.field.entityName as string, name: filter.field.name },
      value: filter.text,
    };
  }
  if (filter.field.filterType === 'range') {
    return {
      type: 'range',
      conditionComparator: 'RANGE_COMPARATOR',
      field: { entityName: filter.field.entityName as string, name: filter.field.name },
      fromValue:
        '' +
        (filter.field.conversionFactor && filter.rangeFrom
          ? filter.field.conversionFactor * filter.rangeFrom
          : filter.rangeFrom),
      toValue:
        '' +
        (filter.field.conversionFactor && filter.rangeTo
          ? filter.field.conversionFactor * filter.rangeTo
          : filter.rangeTo),
    };
  }
  if (filter.field.filterType === 'choice') {
    return {
      type: 'field',
      conditionComparator: 'EQUAL_COMPARATOR',
      field: { entityName: filter.field.entityName as string, name: filter.field.name },
      value: Number(filter.choice?.[0]),
    };
  }
  if (filter.field.filterType === 'multiSelectFilter') {
    return {
      type: 'map',
      conditionComparator: 'MAP_COMPARATOR',
      field: { entityName: filter.field.entityName as string, name: filter.field.name },
      values: filter.choice?.map(v => Number(v)),
    };
  }
  throw new Error('Unknown filter type: ' + filter.field.filterType);
};

const analysisTableGroupingQuery = selectorFamily<string[], string>({
  key: 'analysisTableGrouping',
  get:
    entity =>
    ({ get }) =>
      [
        get(tableGroupState({ entity, index: '1' })),
        get(tableGroupState({ entity, index: '2' })),
        get(tableGroupState({ entity, index: '3' })),
      ].filter(g => g !== '--') as string[],
});

export const useGetAnalysisTableGroupings = () => {
  const entity = useEntity();
  return useRecoilValue(analysisTableGroupingQuery(entity));
};

export const analysisTableChangeState = atomFamily<boolean, string>({
  key: 'analysisTableChangeState',
  default: false,
});

export const useSetAnalysisTableChangeState = () => {
  const entity = useEntity();
  return useSetRecoilState(analysisTableChangeState(entity));
};

export const useAnalysisTableChangeState = () => {
  const entity = useEntity();
  return useRecoilValue(analysisTableChangeState(entity));
};

export const analysisTableFieldState = atomFamily<FieldDTO[], string>({
  key: 'analysisTableFieldState',
  default: entity => [],
});

export const useColumnPanelUpdateAnalysisTableFieldState = () => {
  const columnVisibilityModel = useGetColumnPanelVisibilityModel();
  const columnOrderModel = useGetColumnPanelOrderModel();
  const updateAnalysisTableFieldState = useUpdateAnalysisTableFieldState();

  return () => {
    updateAnalysisTableFieldState(columnVisibilityModel, columnOrderModel);
  };
};

export const useUpdateAnalysisTableFieldState = () => {
  const entity = useEntity();
  const [analysisTableFields, setAnalysisTableFields] = useRecoilState(analysisTableFieldState(entity));

  return (newColumnVisibilityModel: GridColumnVisibilityModel, newColumnOrderModel: string[]) => {
    const newAnalysisTableFields = determineNewAnalysisTableFields(
      analysisTableFields,
      newColumnVisibilityModel,
      newColumnOrderModel
    );
    setAnalysisTableFields(newAnalysisTableFields);
  };
};

export const determineNewAnalysisTableFields = (
  allAnalysisTableFields: FieldDTO[],
  columnVisibilityModel: GridColumnVisibilityModel,
  columnOrderModel: string[]
) => {
  const newFields: FieldDTO[] = [];
  const currentNameToFieldSet = keyBy(allAnalysisTableFields, 'name');

  for (const columnName of columnOrderModel) {
    const visibility = columnVisibilityModel[columnName];
    const field = currentNameToFieldSet[columnName];
    const updatedField: FieldDTO = { ...field, visible: visibility };
    newFields.push(updatedField);
  }

  const newNonAggregatedFields = newFields.filter(it => !it.aggregated);
  const finalNonAggregatedFields: FieldDTO[] = [];
  for (let i = 0; i < newNonAggregatedFields.length; i++) {
    const field = newNonAggregatedFields[i];
    const newPosition = field.visible ? i : -1;
    const newField: FieldDTO = { ...field, position: newPosition };
    finalNonAggregatedFields.push(newField);
  }

  const newAggregatedFields = newFields.filter(it => it.aggregated);
  const finalAggregatedFields: FieldDTO[] = [];
  for (let i = 0; i < newAggregatedFields.length; i++) {
    const field = newAggregatedFields[i];
    const newPosition = field.visible ? i : -1;
    const newField: FieldDTO = { ...field, position: newPosition };
    finalAggregatedFields.push(newField);
  }

  return [...finalNonAggregatedFields, ...finalAggregatedFields];
};

function determineColumnOrder(fields: FieldDTO[]): FieldDTO[] {
  const sortedNonAggregateColumns = fields
    .slice()
    .filter(it => !it.aggregated)
    .sort((a, b) => (a.position === -1 ? 999 : a.position) - (b.position === -1 ? 999 : b.position));
  const sortedAggregateColumns = fields
    .slice()
    .filter(it => it.aggregated)
    .sort((a, b) => (a.position === -1 ? 999 : a.position) - (b.position === -1 ? 999 : b.position));
  return [...sortedNonAggregateColumns, ...sortedAggregateColumns];
}

export const useInitializeAnalysisTableFields = () => {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (entity: string) => {
        const fieldsDTO = await snapshot.getPromise(fieldsQuery({ entity, property: 'userSelectableField' }));
        const fields = fieldsDTO.fields;
        const sortedFields = determineColumnOrder(fields);
        set(analysisTableFieldState(entity), sortedFields);
      },
    []
  );
};

export const analysisTableCurrentAggregationFieldQuery = selectorFamily<FieldDTO[], string>({
  key: 'selectQuery',
  get:
    entity =>
    ({ get }) => {
      const allSelectFields = get(analysisTableFieldState(entity));
      const grouping = get(analysisTableGroupingQuery(entity));
      return allSelectFields
        .filter(f => (grouping.length > 0 ? f.aggregated || grouping.includes(f.name) : !f.aggregated))
        .sort((a, b) => (a.aggregated ? 1 : 0) - (b.aggregated ? 1 : 0));
    },
});

export const useCurrentAggregationAnalysisTableFields = () => {
  const entity = useEntity();
  return useRecoilValue(analysisTableCurrentAggregationFieldQuery(entity));
};

export const useRefreshCurrentAggregationAnalysisTableFields = () => {
  return useRecoilCallback(
    ({ refresh }) =>
      async (entity: string) => {
        refresh(analysisTableCurrentAggregationFieldQuery(entity));
      },
    []
  );
};

export const analysisTableAllFieldQuery = selectorFamily<FieldDTO[], string>({
  key: 'selectQuery',
  get:
    entity =>
    ({ get }) => {
      const allSelectFields = get(analysisTableFieldState(entity));
      return allSelectFields.slice().sort((a, b) => (a.aggregated ? 1 : 0) - (b.aggregated ? 1 : 0));
    },
});

export const useAllAnalysisTableFields = () => {
  const entity = useEntity();
  return useRecoilValue(analysisTableAllFieldQuery(entity));
};

export const useRefreshAfterTagValuesChange = () => {
  const loadSelectedCustomReport = useLoadSelectedCustomReport();
  const loadVisibleTableQueryObject = useLoadVisibleTableQueryObject();
  const applyReportWithEntityAndQuery = useApplyReportWithEntityAndQuery();

  return async (entity: string) => {
    const currentReport = await loadSelectedCustomReport(entity);
    const visibleQuery = await loadVisibleTableQueryObject(entity);

    await applyReportWithEntityAndQuery(currentReport, visibleQuery, entity);
  };
};

export const useRefreshAfterTagDefinitionChange = () => {
  const loadDefaultReport = useLoadDefaultReport();
  const applyReportWithEntity = useApplyReportWithEntity();
  const refreshOverview = useRefreshOverview();

  return useRecoilCallback(
    ({ refresh, set, snapshot }) =>
      async (entity: string, newLoadDefaultReportState?: boolean) => {
        //This ensures that the visible groupings reflect the correct state of the tags
        refresh(fieldsQuery({ entity, property: 'group' }));

        //This ensures that the columns visible in the gridColumnsManagement reflect the correct state of the tags
        refresh(fieldsQuery({ entity, property: 'userSelectableField' }));
        const fieldsDTO = await snapshot.getPromise(fieldsQuery({ entity, property: 'userSelectableField' }));
        const fields = fieldsDTO.fields;
        const sortedFields = determineColumnOrder(fields);
        set(analysisTableFieldState(entity), sortedFields);

        if (newLoadDefaultReportState !== undefined) {
          //This ensures that the custom reports are reloaded, so that the correct error state of the reports is depicted
          refresh(customReportsQuery(entity));

          //This ensures the default report is loaded so that no query is set off with the old tag state
          const defaultReport = await loadDefaultReport(entity);
          await applyReportWithEntity(defaultReport, entity);

          //This ensures that the reports on the overviewpage are reloaded so that no report with an error is shown
          refreshOverview();
        }
      },
    [applyReportWithEntity, loadDefaultReport, refreshOverview]
  );
};

const conditionElement = selectorFamily<ConditionElementDTO, string>({
  key: 'conditionElement',
  get:
    entity =>
    ({ get }) => {
      return {
        type: 'combined',
        logicalOperator: 'AND_OPERATOR',
        conditionElements: [
          {
            type: 'field',
            field: {
              entityName: entity,
              name: '__cost-center',
            },
            value: get(analysisCostCenterState).costCenterId,
            conditionComparator: 'EQUAL_COMPARATOR',
          } as ConditionElementDTO,
        ]
          .concat(entity === 'Level3' ? [get(invoicePeriodRangeCondition)] : [])
          .concat(entity === 'Level5' ? [get(timeRangeCondition)] : [])
          .concat(get(appliedFiltersState(entity)).map(filterToCondition)),
      };
    },
});

export const useVisibleTableQueryObject = () => {
  const entity = useEntity();
  return useRecoilValue(visibleTableQueryObject(entity));
};

export const useLoadVisibleTableQueryObject = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async (entity: string) =>
        await snapshot.getPromise(visibleTableQueryObject(entity))
  );
};

const analysisTableMetaInfoState = selectorFamily<QueryMetaInformationDTO, string>({
  key: 'analysisTableMetaInfoState',
  get:
    entity =>
    ({ get }) => {
      const views = get(viewsState);
      const viewLabel = views.find(view => view.entity === entity);

      if (viewLabel === undefined) {
        throw Error(`analysisTableMetaInfoState: no view found for entity ${entity}`);
      }

      const currentPage = get(currentPageState(viewLabel.name));
      return {
        beginIndex: currentPage * ANALYSIS_TABLE_PAGE_SIZE,
        endIndex: (currentPage + 1) * ANALYSIS_TABLE_PAGE_SIZE,
      };
    },
});

const visibleTableQueryObject = selectorFamily<QueryObjectDTO, string>({
  key: 'visibleTableQueryObject',
  get:
    entity =>
    ({ get }) => {
      const grouping = get(analysisTableGroupingQuery(entity));
      const selectFields = get(analysisTableCurrentAggregationFieldQuery(entity));

      const sortedNonAggregateColumns = selectFields
        .slice()
        .filter(it => !it.aggregated)
        .sort((a, b) => (a.position === -1 ? 999 : a.position) - (b.position === -1 ? 999 : b.position));
      const sortedAggregateColumns = selectFields
        .slice()
        .filter(it => it.aggregated)
        .sort((a, b) => (a.position === -1 ? 999 : a.position) - (b.position === -1 ? 999 : b.position));
      const sortedSelectFields = [...sortedNonAggregateColumns, ...sortedAggregateColumns];

      return {
        entityElements: [entity],
        selectElements: sortedSelectFields.filter(s => s.visible).map(f => ({ entityName: entity, name: f.name })),
        groupElements: grouping.map(g => ({
          entityName: entity,
          name: g,
        })),
        conditionElement: get(conditionElement(entity)),
        sortElements: get(analysisTableSortState(entity))
          .filter(s => !!s.sort)
          .map(s => ({
            field: { entityName: entity, name: s.field },
            sortOperator: s.sort === 'desc' ? 'DESC_SORT' : 'ASC_SORT',
          })),
        metaInformation: get(analysisTableMetaInfoState(entity)),
      };
    },
});

const pivotQueryObject = selectorFamily<QueryObjectDTO, string>({
  key: 'pivotQueryObject',
  get:
    entity =>
    ({ get }) => {
      const { group1, group2, aggr } = get(pivotGroupState(entity));
      return {
        entityElements: [entity],
        selectElements: [
          { entityName: entity, name: group1 },
          { entityName: entity, name: group2 },
          { entityName: entity, name: aggr },
        ],
        groupElements: [
          { entityName: entity, name: group1 },
          { entityName: entity, name: group2 },
        ],
        conditionElement: get(conditionElement(entity)),
        sortElements: [
          { field: { entityName: entity, name: group1 }, sortOperator: 'ASC_SORT' },
          { field: { entityName: entity, name: group2 }, sortOperator: 'ASC_SORT' },
        ],
      };
    },
});

const chartQueryObject = selectorFamily<QueryObjectDTO, string>({
  key: 'chartQueryObject',
  get:
    entity =>
    ({ get }) => {
      const series = get(chartSeriesQuery(entity));
      const group = get(chartGroupState(entity));
      let categoryGroup = get(chartCategoryGroupState(entity));
      if (categoryGroup === '--') {
        categoryGroup = group;
      }

      return {
        entityElements: [entity],
        selectElements: [
          { entityName: entity, name: series[0].select },
          { entityName: entity, name: group },
          { entityName: entity, name: categoryGroup },
        ],
        groupElements: [
          { entityName: entity, name: group },
          { entityName: entity, name: categoryGroup },
        ],
        conditionElement: get(conditionElement(entity)),
        sortElements: [{ field: { entityName: entity, name: group }, sortOperator: 'ASC_SORT' }],
      };
    },
});

const tableQuery = selectorFamily<ApiResponse<QueryResultDTO>, string>({
  key: 'table',
  get:
    entity =>
    async ({ get }) => {
      const lang = get(clientLanguageState);
      return await get(apiClient(lang)).query(get(visibleTableQueryObject(entity)));
    },
});

const pivotQuery = selectorFamily<ApiResponse<QueryResultDTO>, string>({
  key: 'pivot',
  get:
    entity =>
    async ({ get }) => {
      const lang = get(clientLanguageState);
      return await get(apiClient(lang)).query(get(pivotQueryObject(entity)));
    },
});

const chartQuery = selectorFamily<ApiResponse<AnalysisChartDTO>, string>({
  key: 'chart',
  get:
    entity =>
    async ({ get }) => {
      const lang = get(clientLanguageState);
      return await get(apiClient(lang)).chartQuery(get(chartQueryObject(entity)));
    },
});

export const useAnalysisTable = () => {
  const entity = useEntity();
  return useLoadableApiValue(tableQuery(entity));
};

export const useAnalysisPivot = () => {
  const entity = useEntity();
  return useLoadableApiValue(pivotQuery(entity));
};

export const useAnalysisChart = () => {
  const entity = useEntity();
  return useLoadableApiValue(chartQuery(entity));
};

export const useAnalysisTableDownload = () => {
  const entity = useEntity();
  return useRecoilCallback(
    ({ snapshot }) =>
      async (
        format: TableExportFormatType,
        setWaitingState: (isWaiting: boolean) => void,
        handleFailure: (error?: Error) => void
      ) => {
        const lang = await snapshot.getPromise(clientLanguageState);
        const client = await snapshot.getPromise(apiClient(lang));
        const qo = await snapshot.getPromise(visibleTableQueryObject(entity));

        try {
          const generateExportResponse = dataOrThrow(await client.createTableExportFile(qo, format));
          await client.downloadTableExportFile(
            format,
            generateExportResponse.generatedDirectoryName,
            handleFailure,
            setWaitingState
          );
        } catch (e) {
          const error = createDetailedError(e) ?? toError(e);
          handleFailure(error);
        }
      },
    [entity]
  );
};

export const useDrilldownDownload = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      async (
        qo: QueryObjectDTO,
        format: TableExportFormatType,
        setWaitingState: (isWaiting: boolean) => void,
        handleFailure: (error?: Error) => void,
        fileName?: string
      ) => {
        const lang = await snapshot.getPromise(clientLanguageState);
        const client = await snapshot.getPromise(apiClient(lang));
        try {
          const generateExportResponse = dataOrThrow(await client.createTableExportFile(qo, format, fileName));
          await client.downloadTableExportFile(
            format,
            generateExportResponse.generatedDirectoryName,
            handleFailure,
            setWaitingState
          );
        } catch (e) {
          const error = createDetailedError(e) ?? toError(e);
          handleFailure(error);
        }
      },
    []
  );
};

export const useAnalysisSummaryFields = () => {
  const entity = useEntity();
  return useRecoilValue(fieldsQuery({ entity, property: 'analysisSummary' }));
};

export const useAnalysisGroupFields = () => {
  const entity = useEntity();
  return useRecoilValue(fieldsQuery({ entity, property: 'group' }));
};

export const useAnalysisFilterFields = () => {
  const entity = useEntity();
  return useRecoilValue(fieldsQuery({ entity, property: 'filter' }));
};

export const useReactiveAnalysisGroupFields = (entity: string, groupIndex: number): FieldsDTO => {
  const currentGroupingsList = useRecoilValue(groupingsState(entity));
  const otherGroupingsList = currentGroupingsList.filter(it => it.index !== groupIndex);
  const otherGroupingsNameSet = new Set(otherGroupingsList.map(it => it.name));
  const allFilters = useRecoilValue(fieldsQuery({ entity, property: 'group' }));
  const filteredFields = allFilters.fields.filter(it => !otherGroupingsNameSet.has(it.name));
  return { fields: filteredFields };
};

export const useSubscriptionField = () => {
  const entity = useEntity();
  return useRecoilValue(fieldsQuery({ entity, property: 'SubscriptionField' }));
};

export const useDetailFields = () => {
  const entity = useEntity();
  return useRecoilValue(fieldsQuery({ entity, property: 'recordDetailField' }));
};

export const useChartAggregateFields = () => {
  const entity = useEntity();
  return useRecoilValue(fieldsQuery({ entity, property: 'chartAggregate' }));
};

export interface GroupingItem {
  name: string;
  index: number;
}

const numberVisibleGroupingsState = atomFamily<number, string>({
  key: 'numberVisibleGroupings',
  default: 1,
});

export const useVisibleGroupings = () => {
  const entity = useEntity();
  return useRecoilState(numberVisibleGroupingsState(entity));
};

export const groupingsState = selectorFamily<GroupingItem[], string>({
  key: 'groupings',
  get:
    entity =>
    ({ get }) => [
      { name: get(tableGroupState({ entity, index: '1' })), index: 0 },
      { name: get(tableGroupState({ entity, index: '2' })), index: 1 },
      { name: get(tableGroupState({ entity, index: '3' })), index: 2 },
    ],
});

export const useAnalysisTableGroup = (
  index: GroupIndexType
): [string, (newValue: string, oldValue: string) => void] => {
  const entity = useEntity();
  const transaction = useRecoilTransaction_UNSTABLE(
    ({ get, set }) =>
      (newGroup: string, oldGroup: string, beforeGroupingState: GroupingItem[]) => {
        const groupIndex = parseInt(index.valueOf()) - 1;
        const currentTableSortState = get(analysisTableSortState(entity));
        const allFields = get(analysisTableFieldState(entity));

        set(tableGroupState({ entity, index }), newGroup);

        const newGroupingState: GroupingItem[] = [];
        for (const groupingItem of beforeGroupingState) {
          if (groupingItem.index === groupIndex) {
            newGroupingState.push({ name: newGroup, index: groupIndex });
          } else {
            newGroupingState.push(groupingItem);
          }
        }

        const newTableSortState: GridSortItem[] = [];
        const currentNameToSort = keyBy(currentTableSortState, 'field');
        for (const groupingItem of newGroupingState) {
          if (groupingItem.name !== '--') {
            if (currentNameToSort[groupingItem.name] !== undefined) {
              const existingSort = currentNameToSort[groupingItem.name];
              newTableSortState.push(existingSort);
            } else {
              const newSort: GridSortItem = { field: groupingItem.name, sort: 'asc' };
              newTableSortState.push(newSort);
            }
          }
        }
        set(analysisTableSortState(entity), newTableSortState);

        const currentNonAggregatedFields = allFields.filter(it => !it.aggregated);
        const nonAggregatedFieldsDic = keyBy(currentNonAggregatedFields, 'name');
        const groupingFieldNameSet = new Set(newGroupingState.map(it => it.name));
        const finalNonAggregatedFields: FieldDTO[] = [];

        let nonAggregatedFieldsPosition = 0;
        const filteredGroupingState = newGroupingState.filter(it => it.name !== '--');
        for (const groupingItem of filteredGroupingState) {
          const field = nonAggregatedFieldsDic[groupingItem.name];
          const updatedField: FieldDTO = { ...field, visible: true, position: nonAggregatedFieldsPosition++ };
          finalNonAggregatedFields.push(updatedField);
        }
        for (const currentNonAggregatedField of currentNonAggregatedFields) {
          if (!groupingFieldNameSet.has(currentNonAggregatedField.name)) {
            const newPosition = currentNonAggregatedField.visible ? nonAggregatedFieldsPosition++ : -1;
            const updatedField = { ...currentNonAggregatedField, position: newPosition };
            finalNonAggregatedFields.push(updatedField);
          }
        }

        const newAggregatedFields = allFields.filter(it => it.aggregated);
        const finalNewAllFields = [...finalNonAggregatedFields, ...newAggregatedFields];
        set(analysisTableFieldState(entity), finalNewAllFields);
      },
    [entity, index]
  );
  return [
    useRecoilValue(tableGroupState({ entity, index })),
    useRecoilCallback(({ snapshot }) => async (newGroup: string, oldGroup: string) => {
      const currentGroupingState = await snapshot.getPromise(groupingsState(entity));
      transaction(newGroup, oldGroup, currentGroupingState);
    }),
  ];
};

export const useAnalysisChartGroup = () => {
  const entity = useEntity();
  return useRecoilState(chartGroupState(entity));
};

export const useGetAnalysisChartGroup = () => {
  const entity = useEntity();
  return useRecoilValue(chartGroupState(entity));
};

export const useAnalysisChartCategoryGroup = () => {
  const entity = useEntity();
  return useRecoilState(chartCategoryGroupState(entity));
};

export const useGetAnalysisChartGroupCategory = () => {
  const entity = useEntity();
  return useRecoilValue(chartCategoryGroupState(entity));
};

export interface ChartSeries {
  select: string;
  group: string;
}

export type ChartType = 'barHorizontal' | 'barVertical' | 'line' | 'area' | 'pie';

const chartSeriesDefault: ChartSeries = {
  select: 'RecordCnt',
  group: '--',
};

const defaultChartType: ChartType = 'barVertical';

interface ChartSeriesParams extends Readonly<Record<string, string | number>> {
  entity: string;
  index: number;
  field: keyof ChartSeries;
}

interface ChartTypeParams extends Readonly<Record<string, string | number>> {
  entity: string;
  index: number;
}

const chartSeriesIndex = atomFamily<number[], string>({
  key: 'chartSeriesIndex',
  default: () => [0],
});

const chartSeriesState = atomFamily<string, ChartSeriesParams>({
  key: 'chartSeriesState',
  default: param => chartSeriesDefault[param.field],
});

const chartTypeState = atomFamily<ChartType, ChartTypeParams>({
  key: 'chartTypeState',
  default: () => defaultChartType,
});

const chartSeriesQuery = selectorFamily<ChartSeries[], string>({
  key: 'chartSeriesQuery',
  get:
    entity =>
    ({ get }) => {
      const index = get(chartSeriesIndex(entity));
      return index.map(i => ({
        select: get(chartSeriesState({ entity, index: i, field: 'select' })),
        group: get(chartSeriesState({ entity, index: i, field: 'group' })),
      }));
    },
});

const chartTypeQuery = selectorFamily<ChartType[], string>({
  key: 'chartSeriesQuery',
  get:
    entity =>
    ({ get }) => {
      const index = get(chartSeriesIndex(entity));
      return index.map(i => get(chartTypeState({ entity, index: i })));
    },
});

interface ChartSeriesResult {
  series: (ChartSeries & { type: ChartType })[];
  setValue: (index: number, field: keyof ChartSeries | 'type', value: string) => void;
  add: () => void;
  remove: (index: number) => void;
}

const useSetChartSeriesValue = () => {
  const entity = useEntity();
  return useRecoilTransaction_UNSTABLE(
    ({ get, set }) =>
      (index: number, field: keyof ChartSeries | 'type', value: string) => {
        if (field === 'type') {
          set(chartTypeState({ entity, index: get(chartSeriesIndex(entity))[index] }), value as ChartType);
        } else {
          set(chartSeriesState({ entity, index: get(chartSeriesIndex(entity))[index], field }), value);
        }
      },
    [entity]
  );
};

export const useChartSeries = (): ChartSeriesResult => {
  const entity = useEntity();
  const series = useRecoilValue(chartSeriesQuery(entity));
  const types = useRecoilValue(chartTypeQuery(entity));
  const setValue = useSetChartSeriesValue();
  const setIndex = useSetRecoilState(chartSeriesIndex(entity));
  const add = () => {
    setIndex(index => index.concat(Math.max(...index) + 1));
  };
  const remove = (i: number) => {
    setIndex(index => {
      const newIndex = index.slice();
      newIndex.splice(i, 1);
      return newIndex;
    });
  };
  return { series: series.map((s, i) => ({ ...s, type: types[i] })), setValue, add, remove };
};

export const useGetChartSeriesType = () => {
  const entity = useEntity();
  const types = useRecoilValue(chartTypeQuery(entity));
  return types[0];
};

export interface FilterState {
  field: FieldDTO;
  text?: string;
  startsWith?: boolean;
  rangeFrom?: number;
  rangeTo?: number;
  choice?: string[];
}

export interface SerializedFilterState {
  field: string;
  value: string;
}

interface FilterStateParam extends Readonly<Record<string, string | number>> {
  entity: string;
  filterId: number;
}

export const readFilterStateFromBrowserHistory = (): SerializedFilterState[] => {
  const s = window.location.search;
  if (s.length < 2) {
    return [];
  }
  return s
    .substring(1)
    .split('&')
    .map(p => {
      const [field, value] = p.split('=');
      return { field, value };
    });
};

export const serializeFilter = (filter: FilterState): SerializedFilterState => {
  if (filter.field.filterType === 'textField' || filter.field.filterType === 'textFieldStartsWith') {
    return { field: filter.field.name, value: filter.text + (filter.startsWith ? '*' : '') };
  } else if (filter.field.filterType === 'choice' || filter.field.filterType === 'multiSelectFilter') {
    return { field: filter.field.name, value: (filter.choice as string[]).join('!') };
  } else if (filter.field.filterType === 'range') {
    return { field: filter.field.name, value: `${filter.rangeFrom}-${filter.rangeTo}` };
  }
  throw new Error('Unknown filter type ' + filter.field.filterType);
};

export const persistFilterStateInBrowserHistory = (filters: SerializedFilterState[]) => {
  const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const queryParams = filters.map(f => `${f.field}=${encodeURIComponent(f.value)}`).join('&');
  window.history.replaceState(
    window.history.state,
    window.document.title,
    queryParams.length > 0 ? `${url}?${queryParams}` : url
  );
};

export const filterIdsState = atomFamily<number[], string>({
  key: 'filterIds',
  default: [1],
});

const emptyFilter: FilterState = { field: { name: '--' } as FieldDTO };

export const filterState = atomFamily<FilterState, FilterStateParam>({
  key: 'filter',
  default: emptyFilter,
});

export const appliedFiltersState = atomFamily<FilterState[], string>({
  key: 'appliedFilters',
  default: [],
});

const isFilterValid = (filterState: FilterState): boolean => {
  if (!filterState.field.filterType) {
    return false;
  }
  if (filterState.field.filterType === 'textField' || filterState.field.filterType === 'textFieldStartsWith') {
    return !!filterState.text && filterState.text.trim().length > 0;
  }
  if (filterState.field.filterType === 'range') {
    return typeof filterState.rangeFrom === 'number' && typeof filterState.rangeTo === 'number';
  }
  if (filterState.field.filterType === 'choice' || filterState.field.filterType === 'multiSelectFilter') {
    return !!filterState.choice && filterState.choice.length > 0 && filterState.choice.indexOf('--') < 0;
  }
  return true;
};

export const useUpdateFilters = () => {
  const entity = useEntity();
  return useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      (filters: FilterState[]) => {
        set(filterIdsState(entity), range(1, filters.length + 1));
        filters.forEach((f, i) => set(filterState({ entity, filterId: i + 1 }), f));
        set(appliedFiltersState(entity), filters);
        set(filterPanelState(`additional-${entity}`), true);
      },
    [entity]
  );
};

export const useRestoreFilters = () => {
  const entity = useEntity();
  return useRecoilTransaction_UNSTABLE(
    ({ get, set, reset }) =>
      () => {
        const filters = get(appliedFiltersState(entity));
        if (filters.length === 0) {
          reset(filterIdsState(entity));
          reset(filterState({ entity, filterId: 1 }));
        } else {
          set(filterIdsState(entity), range(1, filters.length + 1));
          filters.forEach((f, i) => set(filterState({ entity, filterId: i + 1 }), f));
          persistFilterStateInBrowserHistory(filters.map(serializeFilter));
        }
      },
    [entity]
  );
};

export const useApplyFilters = () => {
  const entity = useEntity();
  return useRecoilTransaction_UNSTABLE(
    ({ get, set }) =>
      () => {
        const filters = get(filterIdsState(entity))
          .map(filterId => get(filterState({ entity, filterId })))
          .filter(isFilterValid);
        set(appliedFiltersState(entity), filters);
        persistFilterStateInBrowserHistory(filters.map(serializeFilter));
      },
    [entity]
  );
};

export const useAnalysisGroupDrilldown = () => {
  const entity = useEntity();
  return useRecoilTransaction_UNSTABLE(
    ({ get, set, reset }) =>
      (newFilter: FilterState) => {
        const previousFilters = get(appliedFiltersState(entity));
        const filters = previousFilters.concat(newFilter);
        set(appliedFiltersState(entity), filters);
        set(filterIdsState(entity), range(1, filters.length + 1));
        filters.forEach((f, i) => set(filterState({ entity, filterId: i + 1 }), f));
        persistFilterStateInBrowserHistory(filters.map(serializeFilter));
        reset(tableGroupState({ entity, index: '1' }));
        reset(tableGroupState({ entity, index: '2' }));
        reset(tableGroupState({ entity, index: '3' }));
        set(filterPanelState(`additional-${entity}`), true);
        reset(analysisTableSortState(entity));
      },
    [entity]
  );
};

export const useFilterIds = (): [number[], SetterOrUpdater<number[]>, number, boolean] => {
  const entity = useEntity();
  const [value, setter] = useRecoilState(filterIdsState(entity));
  const info = useGetRecoilValueInfo_UNSTABLE()(appliedFiltersState(entity));
  const nextId = value.length === 0 ? 0 : Math.max(...value) + 1;
  return [value, setter, nextId, info.isSet];
};

export const useFilter = (filterId: number) => {
  const entity = useEntity();
  return useRecoilState(filterState({ entity, filterId }));
};

export const useResetFilter = () => {
  const entity = useEntity();
  return useRecoilCallback(
    ({ reset }) =>
      (filterId: number) => {
        reset(filterState({ entity, filterId }));
      },
    [entity]
  );
};

interface FieldValuesParam extends Readonly<Record<string, string>> {
  entity: string;
  field: string;
}

const fieldValuesQuery = selectorFamily<ApiResponse<FormattedValuesDTO>, FieldValuesParam>({
  key: 'fieldValues',
  get:
    ({ entity, field }) =>
    async ({ get }) => {
      if (field === '') {
        return { data: { values: [] } };
      }
      const lang = get(clientLanguageState);
      return await get(apiClient(lang)).getFieldValues(entity, field);
    },
});

export const useFieldValues = (field: string) => {
  const entity = useEntity();
  return dataOrThrow(useRecoilValue(fieldValuesQuery({ entity, field })));
};

export interface InvoicePeriodRange {
  from: InvoicePeriodDTO;
  to: InvoicePeriodDTO;
}

const defaultNewestInvoicePeriodQuery = selector<InvoicePeriodRange>({
  key: 'defaultInvoicePeriodRange',
  get: ({ get }) => {
    const available = get(availableInvoicePeriodsQuery);
    const sortedAvailable = available.slice().sort(descPeriodComparator);
    if (sortedAvailable.length === 0) {
      return { from: {}, to: {} } as InvoicePeriodRange;
    }
    return {
      from: sortedAvailable[0],
      to: sortedAvailable[0],
    };
  },
});

const newestInvoicePeriodRangeState = atom<InvoicePeriodRange>({
  key: 'invoicePeriodRange',
  default: defaultNewestInvoicePeriodQuery,
});

export const useNewestInvoicePeriodRange = () => useRecoilState(newestInvoicePeriodRangeState);

export const useSetNewestInvoicePeriodRange = () => useSetRecoilState(newestInvoicePeriodRangeState);

const invoicePeriodRangeCondition = selector<ConditionElementDTO>({
  key: 'invoicePeriodRangeCondition',
  get: ({ get }) => {
    const range = get(newestInvoicePeriodRangeState);
    return {
      type: 'combined',
      logicalOperator: 'AND_OPERATOR',
      conditionElements: [
        {
          type: 'field',
          field: {
            entityName: 'Level3',
            name: '__invoice-date-from',
          },
          value: moment(range.from.startDate).valueOf(),
          conditionComparator: 'GREATER_OR_EQUAL_THAN_COMPARATOR',
        },
        {
          type: 'field',
          field: {
            entityName: 'Level3',
            name: '__invoice-date-to',
          },
          value: moment(range.to.endDate).add(1, 'd').subtract(1, 's').valueOf(),
          conditionComparator: 'LOWER_OR_EQUAL_THAN_COMPARATOR',
        },
      ],
    };
  },
});

interface InvoicePeriodRangeFilterUpdateCallback {
  callback: (timeRange: InvoicePeriodRange) => void;
}

export const invoicePeriodRangeFilterUpdateCallback = atom<InvoicePeriodRangeFilterUpdateCallback>({
  key: 'invoicePeriodRangeFilterUpdateCallback',
  default: { callback: (timeRange: InvoicePeriodRange) => noop },
});

export const useSetInvoicePeriodRangeFilterUpdateCallback = () =>
  useSetRecoilState(invoicePeriodRangeFilterUpdateCallback);

export const useInvoicePeriodRangeFilterUpdateCallback = () => useRecoilValue(invoicePeriodRangeFilterUpdateCallback);

export interface TimeRange {
  from: string;
  to: string;
}

const defaultNewestTimeRangeQuery = selector<TimeRange>({
  key: 'defaultTimeRange',
  get: ({ get }) => {
    const available = get(availableInvoicePeriodsQuery);
    const sortedInvoices = available.slice().sort(descPeriodComparator);
    if (sortedInvoices.length === 0) {
      return { from: '', to: '' };
    }
    return {
      from: sortedInvoices[0].startDate,
      to: sortedInvoices[0].endDate,
    };
  },
});

export const newestTimeRangeState = atom<TimeRange>({
  key: 'timeRange',
  default: defaultNewestTimeRangeQuery,
});

export const useNewestTimeRange = () => useRecoilState(newestTimeRangeState);

export const useSetTimeRange = () => useSetRecoilState(newestTimeRangeState);

const timeRangeCondition = selector<ConditionElementDTO>({
  key: 'timeRangeCondition',
  get: ({ get }) => {
    const range = get(newestTimeRangeState);
    const from = moment(range.from).valueOf();
    const to = moment(range.to).add(1, 'd').subtract(1, 's').valueOf();
    const dateField = get(fieldsQuery({ entity: 'Level5', property: 'DateField' }));
    return {
      type: 'combined',
      logicalOperator: 'AND_OPERATOR',
      conditionElements: [
        {
          type: 'field',
          field: {
            entityName: 'Level5',
            name: '__usage-date-to',
          },
          value: from,
          conditionComparator: 'GREATER_OR_EQUAL_THAN_COMPARATOR',
        },
        {
          type: 'field',
          field: {
            entityName: 'Level5',
            name: '__usage-date-from',
          },
          value: to,
          conditionComparator: 'LOWER_OR_EQUAL_THAN_COMPARATOR',
        },
        {
          type: 'range',
          field: {
            entityName: 'Level5',
            name: dateField.fields[0].name,
          },
          fromValue: from,
          toValue: to,
          conditionComparator: 'RANGE_COMPARATOR',
        },
      ],
    };
  },
});

interface TimeRangeFilterUpdateCallback {
  callback: (timeRange: TimeRange) => void;
}

export const timeRangeFilterUpdateCallback = atom<TimeRangeFilterUpdateCallback>({
  key: 'timeRangeFilterUpdateCallback',
  default: { callback: (timeRange: TimeRange) => noop },
});

export const useSetTimeRangeFilterUpdateCallback = () => useSetRecoilState(timeRangeFilterUpdateCallback);

export const useTimeRangeFilterUpdateCallback = () => useRecoilValue(timeRangeFilterUpdateCallback);

const defaultAnalysisCostCenterQuery = selector<CostCenterDTO>({
  key: 'defaultAnalysisCostCenter',
  get: ({ get }) => dataOrThrow(get(costCentersQuery)),
});

const analysisCostCenterState = atom<CostCenterDTO>({
  key: 'analysisCostCenter',
  default: defaultAnalysisCostCenterQuery,
});

export const useAnalysisCostCenter = () => useRecoilState(analysisCostCenterState);

export const useSetAnalysisCostCenter = () => useSetRecoilState(analysisCostCenterState);

export const analysisTableSortState = atomFamily<GridSortModel, string>({
  key: 'analysisTableSort',
  default: () => [],
});

export const useAnalysisTableSort = () => {
  const entity = useEntity();
  return useRecoilState(analysisTableSortState(entity));
};

interface ChartDownloadCallback {
  callback: (format: ChartExportFormatType, setWaitingState: (isWaiting: boolean) => void) => Promise<void>;
}

export const chartDownloadCallback = atom<ChartDownloadCallback>({
  key: 'chartDownloadCallback',
  default: {
    callback: (format: ChartExportFormatType, setWaitingState: (isWaiting: boolean) => void) => new Promise(noop),
  },
});

export const useSetChartDownloadCallback = () => useSetRecoilState(chartDownloadCallback);

export const useChartDownloadCallback = () => useRecoilValue(chartDownloadCallback);

interface ModelUpdateAfterGroupingCallback {
  callback: (groupingFields: string[]) => Promise<void>;
}

export const modelsUpdateAfterGroupingCallback = atom<ModelUpdateAfterGroupingCallback>({
  key: 'modelsUpdateAfterGroupingCallback',
  default: { callback: (groupingFields: string[]) => new Promise(noop) },
});

export const useSetModelsUpdateAfterGroupingCallback = () => useSetRecoilState(modelsUpdateAfterGroupingCallback);

export const useModelsUpdateAfterGroupingCallback = () => useRecoilValue(modelsUpdateAfterGroupingCallback);

interface FieldStylesUpdateCallback {
  callback: () => Promise<void>;
}

export const fieldStylesUpdateCallback = atom<FieldStylesUpdateCallback>({
  key: 'fieldStylesUpdateCallback',
  default: { callback: () => new Promise(noop) },
});

export const useSetFieldStylesUpdateCallback = () => useSetRecoilState(fieldStylesUpdateCallback);

export const useFieldStylesUpdateCallback = () => useRecoilValue(fieldStylesUpdateCallback);

interface DataGridUpdate {
  columnVisibilityModel: GridColumnVisibilityModel;
  columnOrderModel: string[];
}

export const dataGridUpdate = atom<DataGridUpdate | undefined>({
  key: 'dataGridUpdate',
  default: undefined,
});

export const useSetDataGridUpdate = () => useSetRecoilState(dataGridUpdate);

export const useDataGridUpdate = () => useRecoilState(dataGridUpdate);

export const columnPanelOrderModel = atom<string[]>({
  key: 'columnPanelOrderModel',
  default: [],
});

export const useSetColumnPanelOrderModel = () => useSetRecoilState(columnPanelOrderModel);

export const useGetColumnPanelOrderModel = () => useRecoilValue(columnPanelOrderModel);

export const columnPanelVisibilityModel = atom<GridColumnVisibilityModel>({
  key: 'columnPanelVisibilityModel',
  default: {},
});

export const useSetColumnPanelVisibilityModel = () => useSetRecoilState(columnPanelVisibilityModel);

export const useGetColumnPanelVisibilityModel = () => useRecoilValue(columnPanelVisibilityModel);

export const useVisibleGroupingsAfterReportSelectionCallback = () => {
  return useRecoilCallback(
    ({ set }) =>
      async (visibleGroupings: number, entity: string) => {
        set(numberVisibleGroupingsState(entity), visibleGroupings);
      },
    []
  );
};

export const useApplyQueryConditionals = () => {
  const api = useApi();

  const setAnalysisCostCenter = useSetAnalysisCostCenter();
  const setInvoicePeriodRange = useSetNewestInvoicePeriodRange();
  const invoicePeriodRangeFilterUpdateCallback = useInvoicePeriodRangeFilterUpdateCallback();
  const setTimeRange = useSetTimeRange();
  const timeRangeFilterUpdateCallback = useTimeRangeFilterUpdateCallback();

  const periods = useAllUnorderedInvoicePeriods();
  const periodsByFrom = useMemo(() => keyBy(periods, 'startDate'), [periods]);
  const periodsByTo = useMemo(() => keyBy(periods, 'endDate'), [periods]);

  return async (queryObject: QueryObjectDTO) => {
    if (queryObject.conditionElement.conditionElements) {
      for (let i = 0; i < queryObject.conditionElement.conditionElements.length; i++) {
        const conditionals = queryObject.conditionElement.conditionElements[i];

        if (
          conditionals.type === 'field' &&
          conditionals.field &&
          conditionals.field.name === '__cost-center' &&
          conditionals.conditionElements === undefined
        ) {
          const costCenterId = conditionals.value as number;
          const costCenter = dataOrThrow(await api.getCostCenter(costCenterId));
          setAnalysisCostCenter(costCenter);
        } else if (
          conditionals.type === 'combined' &&
          conditionals.logicalOperator === 'AND_OPERATOR' &&
          conditionals.conditionElements?.length === 2 &&
          conditionals.conditionElements[0].field &&
          conditionals.conditionElements[0].field.name === '__invoice-date-from' &&
          conditionals.conditionElements[1].field &&
          conditionals.conditionElements[1].field.name === '__invoice-date-to'
        ) {
          const from = moment(conditionals.conditionElements[0].value as number).format('YYYY-MM-DD');
          const fromPeriod = periodsByFrom[from];
          const to = moment(conditionals.conditionElements[1].value as number).format('YYYY-MM-DD');
          const toPeriod = periodsByTo[to];
          const newInvoicePeriodRange: InvoicePeriodRange = { from: fromPeriod, to: toPeriod };

          invoicePeriodRangeFilterUpdateCallback.callback(newInvoicePeriodRange);
          setInvoicePeriodRange(newInvoicePeriodRange);
        } else if (
          conditionals.type === 'combined' &&
          conditionals.logicalOperator === 'AND_OPERATOR' &&
          conditionals.conditionElements?.length === 3 &&
          conditionals.conditionElements[0].field &&
          conditionals.conditionElements[0].field.name === '__usage-date-to' &&
          conditionals.conditionElements[1].field &&
          conditionals.conditionElements[1].field.name === '__usage-date-from'
        ) {
          const from = moment(conditionals.conditionElements[0].value as number).format('YYYY-MM-DD');
          const to = moment(conditionals.conditionElements[1].value as number).format('YYYY-MM-DD');
          const newTimeRange: TimeRange = { from: from, to: to };

          setTimeRange(newTimeRange);
          timeRangeFilterUpdateCallback.callback(newTimeRange);
        }
      }
    }
  };
};
