import { Card, styled } from '@mui/material';
import { useGetNewestInvoicePeriod } from '../../globalState/invoice';
import { useMailTemplate, useUser } from '../../globalState';
import { useFormikContext } from 'formik';
import { CustomReportData } from './CustomReportWizard';
import { formatDate } from '../../util';

export const DefaultTextContainer = styled(Card)`
  background: ${({ theme }) => theme.palette.grey['200']};
  padding: ${({ theme }) => theme.spacing(3)};
  border: 1px solid #979797;
  box-shadow: none;
`;

export const useGetDefaultMailText = (): string => {
  const { startDate, endDate } = useGetNewestInvoicePeriod();
  const { firstName, lastName } = useUser();
  const { values } = useFormikContext<CustomReportData>();
  const { content } = useMailTemplate(
    new Map<string, string>([
      ['name', 'REPORT_MAIL_TEMPLATE'],
      ['lang', values.reportLanguage],
    ])
  );

  return content
    .replace('#{FROM_DATE}', formatDate(startDate))
    .replace('#{TO_DATE}', formatDate(endDate))
    .replace('#{USER_NAME}', `${firstName} ${lastName}`)
    .replace('#{REPORT_ERROR}', '');
};
