import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutlet } from 'react-router';
import MainLayout from '../../MainLayout';
import NavigationTabs, { TabItem } from '../../components/NavigationTabs';
import ProfileMenu from '../../components/profilemenu/ProfileMenu';
import { useLanguage } from '../../i18nContext';
import { displayName, useToErrorBoundary } from '../../util';
import { AdminViewType } from '../../dto';
import Administrators from './Administrators';
import Statistics from './Statistics';
import Jobs from './Jobs';
import Maintenance from './Maintenance';
import { useTranslation } from 'react-i18next';
import { toError } from '../../components';
import Support from './Support';
import { useSetAdditionalUserErrorInformation } from '../../components/Error';
import { AdminView, dataOrThrow, useAdministrator, useAdminViews, useApi, useSetAdminViews } from '../../globalState';
import NotFound from '../NotFound';

const getAdminView = (input: AdminViewType): AdminView => {
  switch (input) {
    case 'SUPPORT':
      return {
        absolutPath: '/:lng/tb/admin/support',
        relativePath: 'administrators',
        matcher: RegExp('^/([a-z]{2})/tb/admin/support$'),
        label: 'admin.support',
        element: <Support />,
      };
    case 'ADMINISTRATORS':
      return {
        absolutPath: '/:lng/tb/admin/administrators',
        relativePath: 'administrators',
        matcher: RegExp('^/([a-z]{2})/tb/admin/administrators$'),
        label: 'admin.administrators',
        element: <Administrators />,
      };
    case 'STATISTICS':
      return {
        absolutPath: '/:lng/tb/admin/statistics',
        relativePath: 'statistics',
        matcher: RegExp('^/([a-z]{2})/tb/admin/statistics$'),
        label: 'admin.statistics',
        element: <Statistics />,
      };
    case 'JOBS':
      return {
        absolutPath: '/:lng/tb/admin/jobs',
        relativePath: 'jobs',
        matcher: RegExp('^/([a-z]{2})/tb/admin/jobs$'),
        label: 'admin.jobs',
        element: <Jobs />,
      };
    case 'MAINTENANCE':
      return {
        absolutPath: '/:lng/tb/admin/maintenance',
        relativePath: 'maintenance',
        matcher: RegExp('^/([a-z]{2})/tb/admin/maintenance'),
        label: 'admin.maintenance',
        element: <Maintenance />,
      };
  }
};

const Admin: React.FC = () => {
  const navigate = useNavigate();
  const language = useLanguage();
  const adminViews = useAdminViews();
  const api = useApi();
  const setAdminViews = useSetAdminViews();
  const toErrorBoundary = useToErrorBoundary();
  const setAccountAdditionalErrorInformation = useSetAdditionalUserErrorInformation();
  const administrator = useAdministrator();
  const outlet = useOutlet();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [checkAdminPages, setCheckAdminPages] = useState(true);

  useEffect(() => {
    if (checkAdminPages) {
      (async () => {
        try {
          const adminViewsDTO = dataOrThrow(await api.getAdminViews());
          setAdminViews(adminViewsDTO.adminViews.map(it => getAdminView(it)));
        } catch (err) {
          toErrorBoundary(new Error(`Error occurred while getting views because of:\n${toError(err)}`));
        } finally {
          setAccountAdditionalErrorInformation({ id: administrator.id, name: administrator.name });
          setCheckAdminPages(false);
        }
      })();
    }
  }, [
    administrator.id,
    administrator.name,
    api,
    checkAdminPages,
    setAccountAdditionalErrorInformation,
    setAdminViews,
    toErrorBoundary,
  ]);
  const selectedView = useMemo(
    () => adminViews.find(v => !!v.matcher.exec(pathname)) as AdminView,
    [adminViews, pathname]
  );
  const handleTabChanged = (value: string): Promise<[string, string]> => {
    navigate(value.replace(':lng', language));
    return new Promise(resolve => ['', '']);
  };
  const tabItems: TabItem[] = useMemo(() => {
    return adminViews.map(it => ({ label: t(`tabs.${it.label}`), path: it.absolutPath }));
  }, [adminViews, t]);

  if (outlet === null) {
    return <NotFound />;
  } else {
    return selectedView !== undefined ? (
      <MainLayout
        navigation={<NavigationTabs tabs={tabItems} path={selectedView.absolutPath} onChange={handleTabChanged} />}
        menu={<ProfileMenu />}
      >
        <Outlet />
      </MainLayout>
    ) : (
      <></>
    );
  }
};

displayName(Admin, 'Admin');

export default Admin;
