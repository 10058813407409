import { I18nextProvider, initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import { baseUri } from './env';
import { useLocation, useParams } from 'react-router';
import { useClientLanguageState } from './globalState/telcobill';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from './globalState';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import React from 'react';
import { TenantType, useTenant } from './TenantContext';
import i18next from 'i18next';

export const useLanguage = () => {
  const { lng } = useParams();
  const [clientLanguageState, setClientLanguageState] = useClientLanguageState();

  if (lng !== undefined && SUPPORTED_LANGUAGES.map(l => l.value).includes(lng) && clientLanguageState !== lng) {
    setClientLanguageState(lng);
    return lng;
  } else {
    return clientLanguageState;
  }
};

export const useLanguageFromURL = () => {
  const { pathname } = useLocation();

  const splitString = pathname.split('/');

  if (splitString.length >= 2 && SUPPORTED_LANGUAGES.map(l => l.value).includes(splitString[1])) {
    return splitString[1];
  } else {
    return DEFAULT_LANGUAGE;
  }
};

const changingLanguage = atom<boolean>({
  key: 'changingLanguage',
  default: false,
});

export const useChangingLanguage = () => useRecoilValue(changingLanguage);

export const useSetChangingLanguage = () => useSetRecoilState(changingLanguage);

const I18nProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const tenant: TenantType = useTenant();

  const i18n = i18next.createInstance();
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .init(
      {
        fallbackLng: 'de',
        debug: true,
        interpolation: {
          escapeValue: false,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          format: (value: any, format?: string, lng?: string) => {
            if (moment.isDate(value)) {
              const dateMoment = moment(value);
              if (lng) {
                dateMoment.locale(lng);
              }
              if (format) {
                return dateMoment.format(format);
              }
            }
            return value;
          },
        },
        react: {
          bindI18nStore: 'added',
          useSuspense: true,
        },
        backend: {
          loadPath: `${baseUri}/i18n/${tenant}/{{lng}}.json`,
        },
        detection: {
          lookupFromPathIndex: 0,
        },
      },
      err => {
        if (err) throw new err();
      }
    )
    .catch(console.error);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default I18nProvider;
