import React, { useMemo, useRef } from 'react';
import { useTheme } from '@mui/material';
import { displayName } from '../../util';
import { ChartDownloadComponent, DownloadContainer } from './ChartDownloadComponent';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ChartProps } from '../analysis/AnalysisChart';

interface DataPoint {
  y: number;
  name: string;
  color: string;
}

interface Series {
  colorByPoint?: boolean;
  name?: string;
  data: number[] | DataPoint[];
  color?: string;
}

interface BarChartData {
  series: Series[];
  categories: string[];
  showLegend: boolean;
}

interface AdditionalProps {
  orientation: 'vertical' | 'horizontal';
  oneSeriesOneColor?: boolean;
}

const BarChart: React.FC<ChartProps & AdditionalProps> = ({
  data,
  valueAxisLabel,
  valueFormatter,
  orientation,
  title,
  className,
  oneSeriesOneColor = false,
}) => {
  const theme = useTheme();

  const chartColorScheme = theme?.custom?.chartColors;
  const chart = useRef<HTMLDivElement | null>(null);
  const barData: BarChartData = useMemo(() => {
    if (
      data != null &&
      data.length > 0 &&
      data[0].grouping !== undefined &&
      data[0].grouping !== '' &&
      data[0].grouping !== data[0].category
    ) {
      const groupingToDataMap = new Map<string, DataPoint[]>();
      const groupingToColorIndexMap = new Map<string, number>();
      let colorIndex = 0;

      data.forEach(it => {
        if (it.grouping) {
          let groupingColorIndex;
          const groupingColorEntry = groupingToColorIndexMap.get(it.grouping);
          if (groupingColorEntry !== undefined) {
            groupingColorIndex = groupingColorEntry;
          } else {
            groupingToColorIndexMap.set(it.grouping, colorIndex);
            groupingColorIndex = colorIndex;
            colorIndex = colorIndex + 1;

            if (colorIndex >= chartColorScheme.length) {
              colorIndex = 0;
            }
          }
          const groupingColor = chartColorScheme[groupingColorIndex];

          const groupingEntries = groupingToDataMap.get(it.grouping);
          if (groupingEntries) {
            groupingEntries.push({ y: it.value, name: it.grouping, color: groupingColor });
          } else {
            groupingToDataMap.set(it.grouping, [{ y: it.value, name: it.grouping, color: groupingColor }]);
          }
        }
      });

      const series: Series[] = [];
      groupingToDataMap.forEach((value: DataPoint[], key: string) => {
        series.push({
          name: key,
          color: value.length > 0 ? value[0].color : undefined,
          data: value,
        });
      });

      return {
        series: series,
        categories: [...new Set(data.map(it => it.category))],
        showLegend: true,
      };
    } else {
      return {
        series: [
          {
            data: data.map(it => it.value),
            colorByPoint: !oneSeriesOneColor,
          },
        ],
        categories: [...new Set(data.map(it => it.category))],
        showLegend: false,
      };
    }
  }, [chartColorScheme, data, oneSeriesOneColor]);

  const options = {
    chart: {
      type: orientation === 'horizontal' ? 'bar' : 'column',
      spacingTop: 15,
    },
    colors: oneSeriesOneColor ? [theme.palette.primary.main] : chartColorScheme,
    legend: {
      enabled: barData.showLegend,
      itemStyle: {
        fontSize: theme?.typography.body1?.fontSize,
        fontWeight: theme?.typography.body1?.fontWeight,
        lineHeight: theme?.typography.body1?.lineHeight,
        fontFamily: theme?.typography.fontFamily,
      },
    },
    title: {
      text: title,
      style: {
        fontSize: theme?.typography.h1?.fontSize,
        fontWeight: theme?.typography.h1?.fontWeight,
        lineHeight: theme?.typography.h1?.lineHeight,
        fontFamily: theme?.typography.fontFamily,
      },
    },
    tooltip: {
      formatter: function (this: Highcharts.Point): string {
        const finalValue = this.y ? valueFormatter(this.y) : '0';
        return `<span>${valueAxisLabel}</span><br><span style="color:${this.color}">${this.key}: </span><b>${finalValue}</b>`;
      },
    },
    xAxis: {
      categories: barData.categories,
      labels: {
        style: {
          fontSize: theme?.typography.body1?.fontSize,
          fontWeight: theme?.typography.body1?.fontWeight,
          lineHeight: theme?.typography.body1?.lineHeight,
          fontFamily: theme?.typography.fontFamily,
        },
      },
    },
    yAxis: {
      title: {
        text: valueAxisLabel,
        style: {
          fontSize: theme?.typography.body1?.fontSize,
          fontWeight: theme?.typography.body1?.fontWeight,
          lineHeight: theme?.typography.body1?.lineHeight,
          fontFamily: theme?.typography.fontFamily,
        },
      },
      labels: {
        style: {
          fontSize: theme?.typography.body1?.fontSize,
          fontWeight: theme?.typography.body1?.fontWeight,
          lineHeight: theme?.typography.body1?.lineHeight,
          fontFamily: theme?.typography.fontFamily,
        },
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject): string {
          return typeof this.value == 'number' ? valueFormatter(this.value) : this.value;
        },
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        cursor: 'pointer',
        dataGrouping: {
          enabled: false,
        },
        dataLabels: [
          {
            enabled: true,
            distance: 20,
            style: {
              fontSize: theme?.typography.body1?.fontSize,
              lineHeight: theme?.typography.body1?.lineHeight,
              fontFamily: theme?.typography.fontFamily,
            },
            formatter: function (this: Highcharts.Point): string {
              return this.y ? valueFormatter(this.y) : '0';
            },
          },
        ],
      },
    },
    series: barData.series,
  };

  return (
    <DownloadContainer ref={chart} className={className}>
      <ChartDownloadComponent chart={chart} />
      <HighchartsReact highcharts={Highcharts} options={options} />
    </DownloadContainer>
  );
};

displayName(BarChart, 'BarChart');

export default BarChart;
