import React, { useContext, useEffect } from 'react';
import { useTenant } from './TenantContext';
import { baseUri } from './env';
import { set } from 'lodash';
import Favicon from 'react-favicon';

export type ImageKey = 'logo' | 'favicon';

type ImageEntry = string;

export type ImagesType = Record<ImageKey, ImageEntry>;

interface ContextProps {
  images: ImagesType;
}

const ImagesContext = React.createContext<ContextProps>({ images: {} } as never);

const isExternal = (uri: string) => uri.startsWith('http://') || uri.startsWith('https://');

export const withPrefix = (uri: string | undefined) => (uri ? (isExternal(uri) ? uri : baseUri + uri) : undefined);

const process = (pathPrefix: string, key: string, value: unknown, result: Record<string, unknown>) => {
  const path = pathPrefix ? `${pathPrefix}.${key}` : key;
  if (typeof value === 'string') {
    set(result, path, withPrefix(value));
  } else {
    Object.entries(value as Record<string, unknown>).forEach(([k, v]) => process(path, k, v, result));
  }
};

export const fetchImages = async (tenant: string) => {
  const response = await fetch(`${baseUri}/images/${tenant}/images.json`);
  const json = await response.json();
  const result = {} as ImagesType;
  process('', '', json, result);
  return result;
};

interface Props {
  children: React.ReactNode;
}

export const ImagesProvider: React.FC<Props> = ({ children }) => {
  const [images, setImages] = React.useState<ImagesType>({} as never);
  const tenant = useTenant();

  useEffect(() => {
    (async () => {
      const result = await fetchImages(tenant as string);
      setImages(result);
    })();
  }, [tenant]);

  return <ImagesContext.Provider value={{ images }}>{children}</ImagesContext.Provider>;
};

export const useImages = () => {
  const { images } = useContext(ImagesContext);
  const getImage = (key: ImageKey): string => images[key];
  return { images, getImage };
};

export const FaviconProvider: React.FC<Props> = ({ children }) => {
  const { getImage } = useImages();

  return (
    <>
      <Favicon url={getImage('favicon') ?? ''} />
      {children}
    </>
  );
};
