import moment from 'moment';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import { InvoicePeriodDTO } from './dto';
import { ApiError, ApiResponse } from './api';
import { MIN_PASSWORD_LENGTH, TelcobillView, useViews } from './globalState';
import { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { toError } from './components';

export const useView = (): TelcobillView => {
  const { pathname } = useLocation();
  const views = useViews();
  const view = views.find(v => v.matcher.exec(pathname));
  if (view) {
    return view;
  }
  throw Error(`No view found for location ${pathname}`);
};

export const useEntity = (): string => {
  const view = useView();
  if (!view.entity) {
    throw Error(`No entity for view type ${view.type}`);
  }
  return view.entity;
};

export const formatTimestamp = (isoTimestamp: string | undefined): string => {
  if (!isoTimestamp) {
    return '';
  }
  return moment(isoTimestamp).format('DD.MM.YYYY HH:mm:ss');
};

export const formatDate = (isoDate: string): string => {
  return moment(isoDate).format('DD.MM.YYYY');
};

export const formatInvoicePeriod = (p: InvoicePeriodDTO): string =>
  p.id === 'all' ? 'Alle' : `${formatDate(p.startDate)} - ${formatDate(p.endDate)}`;

export const useSuspenseFetch = <T, P extends unknown[]>(
  fetchFunc: (...args: P) => Promise<ApiResponse<T>>,
  defaultValue: T
): [T, (...args: P) => void, ApiError | undefined] => {
  const [promise, setPromise] = useState<Promise<ApiResponse<T>>>();
  const [value, setValue] = useState<T>(defaultValue);
  const [error, setError] = useState<ApiError>();
  const callback = useCallback(
    (...args: P) => {
      const p = fetchFunc(...args);
      p.then(response => {
        setPromise(undefined);
        if (response.data) {
          setValue(response.data);
          setError(response.error);
        } else {
          setError(response.error);
        }
      });
      setPromise(p);
    },
    [fetchFunc]
  );
  if (promise) {
    throw promise;
  }
  return [value, callback, error];
};

export const useFetch = <T, P extends unknown[]>(
  fetchFunc: (...args: P) => Promise<ApiResponse<T>>,
  defaultValue: T
): [T, (...args: P) => Promise<void>, boolean, ApiError | undefined] => {
  const [value, setValue] = useState<T>(defaultValue);
  const [error, setError] = useState<ApiError>();
  const [loading, setLoading] = useState(false);
  const callback = useCallback(
    async (...args: P) => {
      setLoading(true);
      try {
        const response = await fetchFunc(...args);
        if (response.error) {
          setError(response.error);
        } else if (response.data) {
          setValue(response.data);
        }
      } finally {
        setLoading(false);
      }
    },
    [fetchFunc]
  );
  return [value, callback, loading, error];
};

export const displayName = <P>(fn: React.FC<P>, name: string) => {
  Object.defineProperty(fn, 'name', { value: name });
};

const emailRegex = /^[a-z0-9._%+!$&*=^|~#%'`?{}/-]+@([a-z0-9-]+\.){1,}([a-z]{2,16})$/;

export const isValidEmailAddress = (s?: string): boolean => !!s && emailRegex.test(s.toLocaleLowerCase());

export const checkColumnVisibilityModelsSame = (a: GridColumnVisibilityModel, b: GridColumnVisibilityModel) => {
  // Filter `false` values only, as `true` and not having a key are the same
  const aFalseValues = new Set(Object.keys(a).filter(key => !a[key]));
  const bFalseValues = new Set(Object.keys(b).filter(key => !b[key]));
  if (aFalseValues.size !== bFalseValues.size) {
    return false;
  }

  let result = true;
  aFalseValues.forEach(key => {
    if (!bFalseValues.has(key)) {
      result = false;
    }
  });
  return result;
};

export const checkColumnOrderModelSame = (a: string[], b: string[]): boolean => {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
};

export const useToErrorBoundary = () => {
  const [, setState] = useState<unknown>();
  return useCallback((error: Error) => {
    setState(() => {
      throw error;
    });
    return undefined;
  }, []);
};

export const usePasswordValidation = () => {
  const { t } = useTranslation();
  return (password: string) => {
    if (password.length < MIN_PASSWORD_LENGTH) {
      return t('validation.password.tooShort', { minLength: MIN_PASSWORD_LENGTH });
    }
    let alph = 0;
    let upper = 0;
    let num = 0;
    let spec = 0;
    for (const c of password) {
      //there is no error on the following line, it is just an IntlIJ bug
      if (RegExp(/^\p{L}/u, 'u').test(c)) {
        alph += 1;
        if (c === c.toUpperCase()) {
          upper += 1;
        }
      } else if (RegExp(/^\d$/).test(c)) {
        num += 1;
      } else {
        spec += 1;
      }
    }
    if (alph > 0 && num > 0 && spec > 0 && upper > 0) {
      return undefined;
    } else {
      return t('validation.password.tooWeak');
    }
  };
};

//Helper functions to prevent "throw of exception caught locally" complaint
export function checkForApiError<T>(apiResponse: ApiResponse<T>): void {
  if (apiResponse.error !== undefined && apiResponse.error !== null) {
    throw toError(apiResponse.error);
  }
}

export function throwApiError(apiError: ApiError | undefined): void {
  throw toError(apiError);
}

export function throwError(message: string): void {
  throw Error(message);
}
