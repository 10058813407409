import React, { useContext } from 'react';
import { envTenant } from './env';

export type TenantType = 'sunrise' | 'versatel' | 'telenet';

interface ContextProps {
  tenant: TenantType;
}

export const TenantContext = React.createContext<ContextProps>({} as never);

export const DefaultTenantProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const tenant = envTenant as TenantType;

  return (
    <TenantContext.Provider
      value={{
        tenant,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};

export const useTenant = () => useContext(TenantContext).tenant;

export const useFlags = () => {
  const tenant = useTenant();

  const isSunrise = () => tenant === 'sunrise';

  const externalUserCreation = () => isSunrise();

  return { externalUserCreation };
};
