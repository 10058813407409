import React, { useMemo, useRef } from 'react';
import { useTheme } from '@mui/material';
import { displayName } from '../../util';
import { ChartDownloadComponent, DownloadContainer } from './ChartDownloadComponent';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ChartProps } from '../analysis/AnalysisChart';

interface DataPoint {
  y: number;
  color?: string;
  name: string;
  custom?: {
    category: string;
  };
}
interface Series {
  name?: string;
  data: DataPoint[];
  color?: string;
  colorByPoint?: boolean;
  size?: string;
  innerSize?: string;
  dataLabel?: Highcharts.DataLabelsOptions;
  tooltip: Highcharts.TooltipOptions;
}

const PieChart: React.FC<ChartProps> = ({ data, valueAxisLabel, valueFormatter, title }) => {
  const theme = useTheme();

  const chartColorScheme = theme?.custom?.chartColors;
  const chart = useRef<HTMLDivElement | null>(null);
  const pieSeries: Series[] = useMemo(() => {
    if (data[0].grouping !== undefined && data[0].grouping !== '' && data[0].grouping !== data[0].category) {
      const categoryToYMap = new Map<string, number>();
      const categoryToColorMap = new Map<string, number>();
      const categoryToGroupingsMap = new Map<string, number>();
      const outerSeriesDataPoints: DataPoint[] = [];
      let colorIndex = 0;

      data.forEach(it => {
        if (it.grouping) {
          let categoryColorIndex;
          const categoryColorEntry = categoryToColorMap.get(it.category);
          if (categoryColorEntry !== undefined) {
            categoryColorIndex = categoryColorEntry;
          } else {
            categoryToColorMap.set(it.category, colorIndex);
            categoryColorIndex = colorIndex;
            colorIndex = colorIndex + 1;

            if (colorIndex >= chartColorScheme.length) {
              colorIndex = 0;
            }
          }
          const categoryColor = chartColorScheme[categoryColorIndex];

          const existingGroupingsToCategory = categoryToGroupingsMap.get(it.category) ?? 0;
          const newGroupingsToCategory = existingGroupingsToCategory + 1;
          categoryToGroupingsMap.set(it.category, newGroupingsToCategory);
          const groupingColor = Highcharts.color(categoryColor)
            .brighten(0.075 * newGroupingsToCategory)
            .get();

          let yEntry = categoryToYMap.get(it.category);
          if (yEntry !== undefined) {
            yEntry += it.value;
            categoryToYMap.set(it.category, yEntry);
          } else {
            categoryToYMap.set(it.category, it.value);
          }
          outerSeriesDataPoints.push({
            name: it.grouping,
            y: it.value,
            color: groupingColor.toString(),
            custom: {
              category: it.category,
            },
          });
        }
      });

      const innerSeriesDataPoints: DataPoint[] = [];
      categoryToYMap.forEach((value: number, key: string) => {
        const colorIndex = categoryToColorMap.get(key) ?? 0;

        innerSeriesDataPoints.push({
          name: key,
          y: value,
          color: chartColorScheme[colorIndex],
        });
      });

      return [
        {
          name: valueAxisLabel,
          data: innerSeriesDataPoints,
          size: '55%',
          dataLabels: [
            {
              enabled: true,
              distance: '-50%',
              style: {
                fontSize: theme?.typography.body1?.fontSize,
                lineHeight: theme?.typography.body1?.lineHeight,
                fontFamily: theme?.typography.fontFamily,
              },
            },
          ],
          tooltip: {
            pointFormatter: function (this: Highcharts.Point): string {
              const finalValue = this.y ? valueFormatter(this.y) : '0';
              return `<span>${valueAxisLabel}</span><br><span style="color:${this.color}">${this.key}: </span><b>${finalValue} / ${this.percentage?.toFixed(2)} %</b>`;
            },
            headerFormat: undefined,
            followPointer: false,
          },
        },
        {
          name: 'Test',
          data: outerSeriesDataPoints,
          size: '100%',
          innerSize: '60%',
          dataLabels: [
            {
              enabled: true,
              distance: 20,
              style: {
                fontSize: theme?.typography.body1?.fontSize,
                lineHeight: theme?.typography.body1?.lineHeight,
                fontFamily: theme?.typography.fontFamily,
              },
            },
            {
              enabled: true,
              distance: '-20%',
              format: '{point.percentage:.1f}%',
              style: {
                textOutline: 'none',
                opacity: 0.7,
                fontSize: theme?.typography.body1?.fontSize,
                lineHeight: theme?.typography.body1?.lineHeight,
                fontFamily: theme?.typography.fontFamily,
              },
              filter: {
                operator: '>',
                property: 'percentage',
                value: 2,
              },
            },
          ],
          tooltip: {
            pointFormatter: function (this: Highcharts.Point): string {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const category: string = (this as any).custom.category;
              const finalValue = this.y ? valueFormatter(this.y) : '0';
              return `<span>${valueAxisLabel}</span><br><span style="color:${this.color}">${category} + ${this.key}: </span>${finalValue}</b>`;
            },
            headerFormat: undefined,
            followPointer: false,
          },
        },
      ];
    } else {
      return [
        {
          name: valueAxisLabel,
          colorByPoint: true,
          data: data.map(item => ({ y: item.value, name: item.category })),
          size: '100%',
          dataLabels: [
            {
              enabled: true,
              distance: 20,
              style: {
                fontSize: theme?.typography.body1?.fontSize,
                lineHeight: theme?.typography.body1?.lineHeight,
                fontFamily: theme?.typography.fontFamily,
              },
            },
            {
              enabled: true,
              distance: '-50%',
              format: '{point.percentage:.1f}%',
              style: {
                textOutline: 'none',
                opacity: 0.7,
                fontSize: theme?.typography.body1?.fontSize,
                lineHeight: theme?.typography.body1?.lineHeight,
                fontFamily: theme?.typography.fontFamily,
              },
              filter: {
                operator: '>',
                property: 'percentage',
                value: 5,
              },
            },
          ],
          tooltip: {
            pointFormatter: function (this: Highcharts.Point): string {
              const finalValue = this.y ? valueFormatter(this.y) : '0';
              return `<span>${valueAxisLabel}</span><br><span style="color:${this.color}">${this.key}: </span><b>${finalValue}</b>`;
            },
            headerFormat: undefined,
            followPointer: false,
          },
        },
      ];
    }
  }, [
    chartColorScheme,
    data,
    theme?.typography.body1?.fontSize,
    theme?.typography.body1?.lineHeight,
    theme?.typography.fontFamily,
    valueAxisLabel,
    valueFormatter,
  ]);

  const options = {
    chart: {
      type: 'pie',
      spacingTop: 15,
    },
    colors: chartColorScheme,
    legend: {
      enabled: false,
    },
    title: {
      text: title,
      style: {
        fontSize: theme?.typography.h1?.fontSize,
        fontWeight: theme?.typography.h1?.fontWeight,
        lineHeight: theme?.typography.h1?.lineHeight,
        fontFamily: theme?.typography.fontFamily,
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        center: ['50%', '50%'],
      },
      series: {
        allowPointSelect: true,
        cursor: 'pointer',
      },
    },
    series: pieSeries,
  };

  return (
    <DownloadContainer ref={chart}>
      <ChartDownloadComponent chart={chart} />
      <HighchartsReact highcharts={Highcharts} options={options} />
    </DownloadContainer>
  );
};

displayName(PieChart, 'PieChart');

export default PieChart;
