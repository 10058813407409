/* eslint-disable prettier/prettier */
import React, { Dispatch, Suspense, useMemo, useState } from 'react';
import { Stack, Step, StepLabel, Stepper } from '@mui/material';
import { ActionButton, CloseButton, StyledButton } from '../Button';
import CustomReportWizardStep1 from './CustomReportWizardStep1';
import CustomReportWizardStep2 from './CustomReportWizardStep2';
import CustomReportWizardStep3 from './CustomReportWizardStep3';
import CustomReportWizardStep4 from './CustomReportWizardStep4';
import { Formik, useFormikContext } from 'formik';
import Spinner, { SuspendedSpinner } from '../Spinner';
import Dialog from '../Dialog';
import { displayName, isValidEmailAddress, throwApiError, useEntity } from '../../util';
import { Dictionary, keyBy, merge } from 'lodash';
import { useTranslation } from 'react-i18next';
import { EmailInformation, EmailProcess } from './Reports';
import { toError, useSetEncounteredError, ValidationError } from '../Error';
import { useLanguage } from '../../i18nContext';
import { useRefreshOverview } from '../../globalState/overview';
import { EMAIL_SEPARATOR_REGEX } from '../../globalState/variables';
import i18n from 'i18next';
import {
  MAX_CUSTOM_REPORT_NAME_LENGTH,
  useAnalysisCostCenter,
  useApi,
  useRefreshCustomReports,
  useSetSelectedCustomReport,
  useSubscriptionField,
  useVisibleTableQueryObject,
} from '../../globalState';
import {
  CreateReportDTO,
  CustomReportDTO,
  ReportDispatchType,
  ReportDocumentType,
  ReportRecipientDTO,
} from '../../dto';

export interface CustomReportData {
  id?: number;
  batchField: string;
  reportName: string;
  reportLanguage: string;
  documentType: ReportDocumentType;
  dispatchType: ReportDispatchType;
  emailAddresses?: string;
  encrypt: string[];
  zipPassword?: string;
  customText?: string;
  recipients?: ReportRecipientDTO[];
  showDefaultText: boolean;
}

export interface ReportRecipient {
  id: string;
  value: string;
  email: string;
  status: string;
}

type ValidationErrors =
  | Partial<Record<keyof CustomReportData, ValidationError>>
  | Record<'batchField', ValidationError>;

interface Props {
  onClose: () => void;
  setProcessState: Dispatch<EmailProcess>;
  initialValues?: Partial<CustomReportData>;
  existingCustomReportNames: Dictionary<CustomReportDTO>;
  creatingReport: boolean;
}

const CustomReportWizard: React.FC<Props> = ({
  onClose,
  setProcessState,
  initialValues = {},
  existingCustomReportNames,
  creatingReport,
}) => {
  const api = useApi();
  const language = useLanguage();
  const entity = useEntity();
  const visibleQueryObject = useVisibleTableQueryObject();
  const refreshCustomReports = useRefreshCustomReports();
  const refreshOverview = useRefreshOverview();
  const setSelectedCustomReport = useSetSelectedCustomReport();
  const setEncounteredError = useSetEncounteredError();
  const { t } = useTranslation();
  const [costCenter] = useAnalysisCostCenter();
  const [sendEmail, setSendEmail] = useState(
    initialValues.dispatchType === 'SINGLE' || initialValues.dispatchType === 'BATCH'
  );
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [canSendEmail, setCanSendEmail] = useState<boolean>(sendEmail && initialValues.emailAddresses !== undefined);
  const [emailInformation, setEmailInformation] = useState<EmailInformation>({
    id: initialValues.id ?? -1,
    reportName: initialValues.reportName ?? '',
    batchField: initialValues.batchField ?? '',
    recipients: initialValues.recipients ?? [],
    dispatchType: initialValues.dispatchType ?? 'NONE',
  });
  const [suspended, setSuspended] = useState<boolean>(false);
  const [maxErrorFreePage, setMaxErrorFreePage] = useState(4);

  const subscriptionField = useSubscriptionField().fields[0];
  const finalInitialValues: CustomReportData = useMemo(
    () =>
      merge(
        {
          reportName: '',
          reportLanguage: language,
          dispatchType: 'NONE',
          batchField: subscriptionField.name,
          documentType: 'XLSX',
          encrypt: [],
          showDefaultText: true,
        },
        initialValues
      ),
    [language, subscriptionField.name, initialValues]
  );
  const validate = (values: CustomReportData): ValidationErrors => {
    const errors: ValidationErrors = {};
    if (!values.reportName || values.reportName.trim().length === 0) {
      errors.reportName = { message: t('sidebar.customReport.validation.emptyReportName') };
    }
    if (creatingReport && existingCustomReportNames[values.reportName] !== undefined) {
      errors.reportName = { message: t('sidebar.customReport.validation.duplicateReportName') };
    }
    if (activeStep === 0) {
      if (values.reportName.length > MAX_CUSTOM_REPORT_NAME_LENGTH) {
        errors.reportName = {
          message: t('sidebar.customReport.validation.tooLongReportName', {
            maxLength: MAX_CUSTOM_REPORT_NAME_LENGTH,
          }),
        };
      }
    }
    if (values.dispatchType === 'SINGLE') {
      if (!values.emailAddresses?.trim()) {
        errors.emailAddresses = { message: t('sidebar.customReport.validation.emptyEmailAddresses') };
      } else {
        const splitAddresses = values.emailAddresses?.split(EMAIL_SEPARATOR_REGEX);
        if (splitAddresses.length === 1) {
          if (!isValidEmailAddress(splitAddresses[0].trim())) {
            errors.emailAddresses = { message: t('sidebar.customReport.validation.invalidEmail.singular') };
          }
        } else {
          splitAddresses.forEach(emailAddress => {
            if (!isValidEmailAddress(emailAddress.trim())) {
              errors.emailAddresses = { message: t('sidebar.customReport.validation.invalidEmail.plural') };
            }
          });
        }
      }
    }
    if (values.dispatchType === 'BATCH') {
      if (!values.recipients) {
        errors.batchField = { message: t('sidebar.customReport.validation.emptyRecipient') };
      } else if (values.recipients.filter(it => it.value === 'nonBatchRecipient').length > 0) {
        errors.batchField = { message: t('sidebar.customReport.validation.missingRecipientValue') };
      }
    }
    if (values.encrypt?.[0] === 'encrypt' && !values.zipPassword) {
      errors.zipPassword = { message: t('sidebar.customReport.validation.emptyPassword') };
    }

    setMaxErrorFreePage(4);
    if (errors.emailAddresses !== undefined || errors.batchField !== undefined || errors.zipPassword !== undefined) {
      setMaxErrorFreePage(0);
    }
    if (errors.reportName !== undefined) {
      setMaxErrorFreePage(-1);
    }

    return errors;
  };

  const handleSaveSubmit = async (submitFunction: () => Promise<void>) => {
    if (sendEmail && activeStep < 3) {
      setActiveStep(s => s + 1);
    } else {
      await submitFunction();
    }
  };

  const convertEmailAddressesToRecipients = (
    emailAddresses: string,
    existingRecipients?: ReportRecipientDTO[]
  ): ReportRecipientDTO[] => {
    if (existingRecipients === undefined) {
      return emailAddresses.split(EMAIL_SEPARATOR_REGEX).map(function (emailAddress): ReportRecipientDTO {
        return {
          value: 'nonBatchRecipient',
          email: emailAddress,
          status: i18n.t('sidebar.customReport.step2.reportRecipient.new'),
        };
      });
    } else {
      const emailToExistingRecipientDic = keyBy(existingRecipients, 'email');
      return emailAddresses.split(EMAIL_SEPARATOR_REGEX).map(function (emailAddress): ReportRecipientDTO {
        return {
          value: 'nonBatchRecipient',
          email: emailAddress,
          status:
            emailToExistingRecipientDic[emailAddress] !== undefined
              ? emailToExistingRecipientDic[emailAddress].status
              : i18n.t('sidebar.customReport.step2.reportRecipient.new'),
        };
      });
    }
  };

  const handleSubmit = async (values: CustomReportData) => {
    setSuspended(true);
    const report: CreateReportDTO = {
      id: values.id,
      costCenterId: costCenter.costCenterId,
      name: values.reportName,
      dispatchType: sendEmail ? values.dispatchType : 'NONE',
      emailAddresses: values.emailAddresses,
      customText: values.customText,
      documentType: values.documentType,
      language: values.reportLanguage,
      password: values.encrypt?.[0] === 'encrypt' ? values.zipPassword : undefined,
      recipients:
        values.dispatchType === 'SINGLE' && values.emailAddresses !== undefined
          ? convertEmailAddressesToRecipients(values.emailAddresses, values.recipients)
          : values.recipients,
      batchField: values.batchField,
      queryObject: visibleQueryObject,
      showDefaultText: values.showDefaultText,
    };
    try {
      const response = await api.saveCustomReport(entity, report);
      if (response.error === null && response.data !== undefined) {
        const newEmailInformation = {
          id: response.data.id,
          reportName: response.data.name,
          batchField: response.data.batchField ?? report.batchField ?? emailInformation.batchField,
          recipients: response.data.recipients ?? report.recipients ?? emailInformation.recipients,
          dispatchType: response.data.dispatchType,
        };
        setSelectedCustomReport(response.data);
        setEmailInformation(newEmailInformation);
        setCanSendEmail(true);
        refreshCustomReports();
        refreshOverview();
        if (isSendingEmail) {
          setProcessState({
            process: {
              state: 'confirm',
              emailInformation: newEmailInformation,
            },
          });
          setIsSendingEmail(false);
        }
      } else {
        throwApiError(response.error);
      }
    } catch (error) {
      setEncounteredError(t('sidebar.customReport.error.saving'), toError(error), {
        api: 'saveCustomReport',
        entity: entity,
      });
    } finally {
      onClose();
      setSuspended(false);
    }
  };

  const triggerSendEmail = async () => {
    setIsSendingEmail(true);
  };

  return (
    <Dialog
      width="800px"
      height="800px"
      header={
        <Stepper
          activeStep={activeStep}
          alternativeLabel={false}
          sx={{
            width: '100%',
            borderColor: 'yellow',
            '& .MuiStepConnector-line': {
              borderColor: sendEmail ? '' : 'grey.300',
            },
            '& .MuiStepLabel-label': {
              color: sendEmail ? '' : 'grey.300',
            },
            '& .MuiStepIcon-root': {
              color: sendEmail ? '' : 'grey.300',
            },
            '& .Mui-completed .MuiStepIcon-root': {
              color: 'grey.500',
            },
          }}
        >
          <Step>
            <StepLabel>{t('sidebar.customReport.step1.title')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('sidebar.customReport.step2.title')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('sidebar.customReport.step3.title')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('sidebar.customReport.step4.title')}</StepLabel>
          </Step>
        </Stepper>
      }
    >
      <Formik initialValues={finalInitialValues} validate={validate} onSubmit={handleSubmit}>
        <>
          <Suspense fallback={<Spinner />}>
            <Stack sx={{ paddingTop: '36px' }} flexGrow={1} flexShrink={1} overflow="auto">
              {activeStep === 0 && (
                <CustomReportWizardStep1
                  sendEmail={sendEmail}
                  onSendEmailChange={setSendEmail}
                  recipientDTOs={initialValues.recipients}
                  isBatchMail={initialValues.dispatchType === 'BATCH'}
                  disabledSendEmail={!canSendEmail || !sendEmail || suspended}
                  triggerSendEmail={triggerSendEmail}
                  batchField={initialValues.batchField}
                />
              )}
              {activeStep === 1 && (
                <CustomReportWizardStep2
                  disabledSendEmail={!canSendEmail || !sendEmail || suspended}
                  triggerSendEmail={triggerSendEmail}
                />
              )}
              {activeStep === 2 && (
                <CustomReportWizardStep3
                  disabledSendEmail={!canSendEmail || !sendEmail || suspended}
                  triggerSendEmail={triggerSendEmail}
                />
              )}
              {activeStep === 3 && (
                <CustomReportWizardStep4
                  disabledSendEmail={!canSendEmail || !sendEmail || suspended}
                  triggerSendEmail={triggerSendEmail}
                />
              )}
              <SuspendedSpinner suspended={suspended} />
            </Stack>
          </Suspense>
          <Stack direction="row" justifyContent="space-between" gap={4} paddingTop={4}>
            <CloseButton onClose={onClose} />
            <Stack direction="row" gap={4}>
              {activeStep > 0 && (
                <ActionButton
                  variant="outlined"
                  width={10}
                  onClick={() => setActiveStep(s => s - 1)}
                  disabled={activeStep < 1 || suspended}
                >
                  {t('dialog.back')}
                </ActionButton>
              )}
              {activeStep < 3 && sendEmail ? (
                <SubmitReportButton
                  width={10}
                  disabled={suspended || activeStep > maxErrorFreePage}
                  handleSubmit={handleSaveSubmit}
                >
                  {t('dialog.next')}
                </SubmitReportButton>
              ) : (
                <SubmitReportButton width={10} disabled={suspended} handleSubmit={handleSaveSubmit}>
                  {t('dialog.save')}
                </SubmitReportButton>
              )}
            </Stack>
          </Stack>
        </>
      </Formik>
    </Dialog>
  );
};

displayName(CustomReportWizard, 'CustomReportWizard');

export default CustomReportWizard;

interface SendEmailButtonProps {
  disabled: boolean;
  triggerSendEmail: () => Promise<void>;
}

export const SendEmailButton: React.FC<SendEmailButtonProps> = ({ disabled, triggerSendEmail }) => {
  const { t } = useTranslation();
  const { submitForm, isSubmitting } = useFormikContext();

  const handleOnClick = async () => {
    await triggerSendEmail();
    await submitForm();
  };

  return (
    <ActionButton variant="outlined" onClick={handleOnClick} disabled={disabled || isSubmitting}>
      {t('dialog.sendEmail')}
    </ActionButton>
  );
};

displayName(SendEmailButton, 'SendEmailButton');

interface SubmitReportButtonProps {
  disabled: boolean;
  handleSubmit: (callback: () => Promise<void>) => Promise<void>;
  width: number;
  children?: React.ReactNode;
}

const SubmitReportButton: React.FC<SubmitReportButtonProps> = ({ disabled, handleSubmit, width, children }) => {
  const { submitForm, isSubmitting } = useFormikContext();

  const handleOnClick = async () => {
    await handleSubmit(submitForm);
  };
  const isDisabled = disabled !== undefined ? isSubmitting || disabled : isSubmitting;
  return (
    <StyledButton
      variant="contained"
      disableElevation={true}
      onClick={handleOnClick}
      disabled={isDisabled}
      width={width}
    >
      {children}
    </StyledButton>
  );
};

displayName(SubmitReportButton, 'SubmitReportButton');
