import React, { useCallback, useMemo, useState } from 'react';
import { InformationText, useCreateError, useLogError, ValidationError } from '../../components/Error';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../globalState';
import { useNavigate, useParams } from 'react-router';
import { displayName, usePasswordValidation } from '../../util';
import useDocumentTitle from '../../useDocumentTitle';
import { Stack, Typography } from '@mui/material';
import { PageCard } from '../../components/Container';
import Text from '../../components/Text';
import { Form, Formik } from 'formik';
import SubmitButton from '../../components/formik/SubmitButton';
import { UList, UListItem } from '../../components/List';
import { ActionButton } from '../../components/Button';
import { useLanguage } from '../../i18nContext';
import FormikTextInput from '../../components/formik/FormikTextInput';
import { SuspendedSpinner } from '../../components/Spinner';

interface FormData {
  newPassword: string;
  confirmPassword: string;
}

type ValidationErrors = Partial<Record<keyof FormData, ValidationError>>;

const ResetPassword: React.FC = () => {
  const api = useApi();
  const language = useLanguage();
  const navigate = useNavigate();
  const validatePassword = usePasswordValidation();
  const createError = useCreateError();
  const logError = useLogError();
  const { t } = useTranslation();
  const { uuid } = useParams();
  const [informationMessage, setInformationMessage] = useState<InformationText>();
  const [suspended, setSuspended] = useState(false);

  useDocumentTitle(t('application.shortName') + ' - ' + t('resetPassword.title'));
  const initialData: FormData = useMemo(() => {
    return {
      newPassword: '',
      confirmPassword: '',
    };
  }, []);
  const validate = (values: FormData): ValidationErrors => {
    const errors: ValidationErrors = {};
    if (!values.newPassword || values.newPassword.trim().length === 0) {
      errors.newPassword = { message: t('resetPassword.validation.emptyField') };
    }
    if (!values.confirmPassword || values.confirmPassword.trim().length === 0) {
      errors.confirmPassword = { message: t('resetPassword.validation.emptyField') };
    } else if (values.confirmPassword !== values.newPassword) {
      errors.newPassword = {};
      errors.confirmPassword = { message: t('resetPassword.validation.unequalPasswords') };
    } else {
      const weakPasswordMessage = validatePassword(values.newPassword);
      if (weakPasswordMessage) {
        errors.newPassword = {};
        errors.confirmPassword = { message: weakPasswordMessage };
      }
    }
    return errors;
  };
  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        if (uuid) {
          setSuspended(true);
          const response = await api.resetPassword(values.newPassword, uuid);
          setSuspended(false);
          if (response.data?.status === 'OK') {
            setInformationMessage({ message: t('resetPassword.information.success'), status: 'success' });
          } else {
            if (response.error?.message === 'uuidExpired') {
              setInformationMessage({ message: t('resetPassword.information.failure.expired'), status: 'error' });
            } else if (response.error?.message === 'invalidUUID') {
              setInformationMessage({ message: t('resetPassword.information.failure.invalid'), status: 'error' });
            } else {
              setInformationMessage({ message: t('resetPassword.information.failure.server'), status: 'error' });
            }
            await logError(createError(response.error));
          }
        }
      } catch (error) {
        setInformationMessage({ message: t('resetPassword.information.failure.server'), status: 'error' });
        await logError(createError(error));
      }
    },
    [api, createError, logError, t, uuid]
  );
  const onBackClick = useCallback(() => {
    navigate(`/${language}/login`);
  }, [language, navigate]);

  return (
    <Stack direction="column" alignItems="center">
      <Stack height={theme => theme.spacing(10)} flexShrink={0} />
      <PageCard data-cy="login">
        <Stack direction="column" padding={4}>
          <Typography variant="h1">{t('application.title')}</Typography>
          <Stack marginTop={3}>
            <Text weight="700">{t('resetPassword.title')}</Text>
          </Stack>
          <Stack marginTop={3}>
            <Typography>{t('passwordRequirements.text')}</Typography>
            <UList>
              <UListItem text={t('passwordRequirements.list.1')} />
              <UListItem text={t('passwordRequirements.list.2')} />
              <UListItem text={t('passwordRequirements.list.3')} />
              <UListItem text={t('passwordRequirements.list.4')} />
            </UList>
          </Stack>
          <Formik initialValues={initialData} validate={validate} onSubmit={handleSubmit}>
            <Stack direction="column" marginTop={4}>
              <Form>
                <Stack direction="column" gap={3}>
                  <FormikTextInput
                    label={t('resetPassword.label.password.new')}
                    fieldName="newPassword"
                    fieldCypressId="newPassword"
                    type="password"
                    autoFocus={true}
                    boldLabel={true}
                  />
                  <FormikTextInput
                    label={t('resetPassword.label.password.confirm')}
                    fieldName="confirmPassword"
                    type="password"
                    boldLabel={true}
                  />
                </Stack>
              </Form>
              {informationMessage && (
                <Stack marginTop={4}>
                  <Typography
                    sx={{
                      color: theme =>
                        informationMessage?.status === 'error' ? theme.palette.error.main : theme.palette.success.main,
                    }}
                    data-cy="errorMessage"
                  >
                    {informationMessage.message}
                  </Typography>
                </Stack>
              )}
              <SuspendedSpinner suspended={suspended} />
              <Stack direction="row" justifyContent="space-between" marginTop={4} data-cy="action">
                <ActionButton variant={'outlined'} onClick={onBackClick}>
                  {t('resetPassword.label.back')}
                </ActionButton>
                <SubmitButton id="submit">{t('resetPassword.label.submit')}</SubmitButton>
              </Stack>
            </Stack>
          </Formik>
        </Stack>
      </PageCard>
    </Stack>
  );
};

displayName(ResetPassword, 'ResetPassword');

export default ResetPassword;
