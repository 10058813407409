import React, { useCallback, useEffect } from 'react';
import { Stack } from '@mui/material';
import { displayName } from '../util';
import useDocumentTitle from '../useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { FallbackProps } from 'react-error-boundary';
import { Navigate, useLocation } from 'react-router';
import { ActionButton } from '../components/Button';
import { ErrorInformationBody, toError } from '../components';
import { useApi } from '../globalState';
import { EnhancedError, isEnhancedError, useCreateError, useLogError } from '../components/Error';
import { useLanguage } from '../i18nContext';
import { PageCard } from '../components/Container';
import { DEFAULT_PROD_LOGIN_PAGE, IS_PRODUCTION } from '../env';
import { DEFAULT_DEV_LOGIN_PAGE } from '../globalState/variables';

const NotAvailable: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const language = useLanguage();
  const createError = useCreateError();

  useDocumentTitle(t('application.shortName') + ' - ' + t('notAvailable.title'));
  const enhancedError: EnhancedError | undefined =
    state === undefined || state === null || state.error === undefined
      ? undefined
      : isEnhancedError(state.error)
        ? state.error
        : createError(state.error);
  const handleBack = useCallback(() => {
    window.location.href = IS_PRODUCTION ? DEFAULT_PROD_LOGIN_PAGE : DEFAULT_DEV_LOGIN_PAGE.replace(':lng', language);
  }, [language]);

  return (
    <Stack direction="column" alignItems="center">
      <Stack height={theme => theme.spacing(10)} flexShrink={0} />
      <PageCard>
        <Stack direction="column" padding={4}>
          <ErrorInformationBody message={t('notAvailable.text')} enhancedError={enhancedError} />
          <Stack direction="row" justifyContent="start" marginTop={4}>
            <ActionButton onClick={handleBack}>{t('dialog.backToPortal')}</ActionButton>
          </Stack>
        </Stack>
      </PageCard>
    </Stack>
  );
};

displayName(NotAvailable, 'NotAvailable');

export default NotAvailable;

export const ErrorFallback: React.FC<FallbackProps> = ({ resetErrorBoundary, error }) => {
  const api = useApi();
  const createError = useCreateError();
  const logError = useLogError();
  const language = useLanguage();
  const { pathname } = useLocation();

  const logoutAfterFailure = useCallback(() => {
    const enhancedError = toError(error);

    const asyncAction = async () => {
      try {
        await api.logout(language, true);
      } catch (err) {
        await logError(createError(`Unable to logout after failure because of error:\n${toError(err)}`));
      }
    };
    if (!enhancedError.message.startsWith('Unauthorized')) {
      asyncAction().catch(logError);
    }
  }, [api, createError, error, language, logError]);

  useEffect(() => {
    resetErrorBoundary();
    logoutAfterFailure();
  }, [logoutAfterFailure, resetErrorBoundary]);

  if (pathname.indexOf('/login') < 0) {
    return <Navigate to={`/${language}/notAvailable`} state={{ error: error }} />;
  } else {
    return <Navigate to={`/${language}/login`} />;
  }
};
