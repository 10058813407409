import React from 'react';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import { displayName } from '../util';

interface Props {
  className?: string;
  value: Moment | null;
  onChange: (value: Moment | null) => void;
  error?: boolean;
}

export const DateTimePicker: React.FC<Props> = ({ className, value, onChange, error }) => {
  return (
    <MuiDateTimePicker
      className={className}
      format="DD.MM.YYYY HH:mm"
      value={value}
      onChange={onChange}
      slotProps={{ textField: { error: error } }}
    />
  );
};

displayName(DateTimePicker, 'DateTimePicker');
