import React, { useEffect } from 'react';
import { Outlet, useOutlet } from 'react-router';
import { displayName } from '../util';
import { useTranslation } from 'react-i18next';
import { useLanguage, useSetChangingLanguage } from '../i18nContext';
import { toError, useCreateError, useLogError } from '../components/Error';
import NotFound from './NotFound';

const Language: React.FC = () => {
  const language = useLanguage();
  const createError = useCreateError();
  const loggError = useLogError();
  const outlet = useOutlet();
  const setChangingLanguage = useSetChangingLanguage();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language !== language) {
      i18n
        .changeLanguage(language)
        .catch(err => loggError(createError(`Could not change i18n language because of:\n${toError(err)}`)));
      setChangingLanguage(false);
    }
  }, [language, i18n, loggError, createError, setChangingLanguage]);

  if (outlet === null) {
    return <NotFound />;
  } else {
    return <Outlet />;
  }
};

displayName(Language, 'Language');

export default Language;
