import React, { useCallback } from 'react';
import { Stack } from '@mui/material';
import { Formik } from 'formik';
import { displayName, isValidEmailAddress } from '../../util';
import { FieldDTO, FilterValueDTO } from '../../dto';
import Dialog from '../Dialog';
import Text from '../Text';
import FormikTextInputField from '../formik/FormikTextInputField';
import SubmitButton from '../formik/SubmitButton';
import { ActionButton } from '../Button';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'components/Error';
import FormikTextInput from '../formik/FormikTextInput';
import FilterValueWarning from './FilterValueWarning';

interface Props {
  field: FieldDTO;
  distinctValues: FilterValueDTO[];
  initialValues?: ReportRecipientValues;
  onAdd: (value: string, email: string) => void;
  onClose: () => void;
}

export interface ReportRecipientValues {
  value: string;
  email: string;
}

type ValidationErrors = Partial<Record<keyof ReportRecipientValues, ValidationError>>;

const ReportRecipientEditor: React.FC<Props> = ({ field, distinctValues, initialValues, onAdd, onClose }) => {
  const { t } = useTranslation();

  const validate = useCallback(
    (values: ReportRecipientValues): ValidationErrors => {
      const errors: ValidationErrors = {};
      if (!values.value.trim()) {
        errors.value = { message: t('sidebar.customReport.validation.emptyField', { field: field.label }) };
      } else if (values.value === 'nonBatchRecipient') {
        errors.value = { message: t('sidebar.customReport.validation.missingRecipientValue') };
      }
      if (!values.email.trim()) {
        errors.email = { message: t('sidebar.customReport.validation.emptyEmailAddress') };
      } else if (!isValidEmailAddress(values.email.trim())) {
        errors.email = { message: t('sidebar.customReport.validation.invalidEmail.singular') };
      }
      return errors;
    },
    [field.label, t]
  );
  const handleSubmit = useCallback(
    (values: ReportRecipientValues) => {
      onAdd(values.value, values.email);
      onClose();
    },
    [onAdd, onClose]
  );

  return (
    <Dialog>
      <Formik initialValues={initialValues ?? { value: '', email: '' }} validate={validate} onSubmit={handleSubmit}>
        <Stack direction="column" gap={4}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Text weight="700">{field.label}</Text>
            <Stack direction="row" alignItems="center" gap={2}>
              <FilterValueWarning distinctValues={distinctValues} />
              <FormikTextInputField fieldName="value" width={12} />
            </Stack>
          </Stack>
          <FormikTextInput label={'Email'} fieldName={'email'} inputWidth={12} boldLabel={true} />
          <Stack direction="row" gap={4} justifyContent="space-between" marginTop={4}>
            <ActionButton variant="outlined" width={10} onClick={onClose}>
              {t('dialog.cancel')}
            </ActionButton>
            <SubmitButton width={10}>{t('dialog.save')}</SubmitButton>
          </Stack>
        </Stack>
      </Formik>
    </Dialog>
  );
};

displayName(ReportRecipientEditor, 'ReportRecipientEditor');

export default ReportRecipientEditor;
