import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Spinner from './components/Spinner';
import LoadingOverlay from './components/LoadingOverlay';
import './i18nContext';
import { ThemeProvider } from './ThemeContext';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './pages/NotAvailable';
import { StyledContainer } from './components/Toast';
import { DefaultTenantProvider } from './TenantContext';
import I18nProvider from './i18nContext';
import { LocalizationProvider } from './LocaliztionContext';
import { FaviconProvider, ImagesProvider } from './ImagesContext';

const container = document.getElementById('app') as Element;
const root = createRoot(container);

root.render(
  <RecoilRoot>
    <DefaultTenantProvider>
      <ThemeProvider>
        <LocalizationProvider>
          <I18nProvider>
            <ImagesProvider>
              <FaviconProvider>
                <Suspense fallback={<Spinner />}>
                  <StyledContainer />
                  <LoadingOverlay />
                  <Router>
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <DndProvider backend={HTML5Backend}>
                        <title>Cost Manager</title>
                        <App />
                      </DndProvider>
                    </ErrorBoundary>
                  </Router>
                </Suspense>
              </FaviconProvider>
            </ImagesProvider>
          </I18nProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </DefaultTenantProvider>
  </RecoilRoot>
);
