import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { MenuItem, SelectChangeEvent, Stack, Typography } from '@mui/material';
import DefaultSelect from '../Select';
import FilterPanel from './FilterPanel';
import { useLanguage } from '../../i18nContext';
import { InvoicePeriodDTO } from '../../dto';
import { displayName } from '../../util';
import { AnalysisFilterComponentProps } from './AnalysisFilterComponent';
import {
  ascPeriodComparator,
  InvoicePeriodRange,
  useAllUnorderedInvoicePeriods,
  useNewestInvoicePeriodRange,
  useSetCurrentPageToZero,
  useSetInvoicePeriodRangeFilterUpdateCallback,
} from '../../globalState';

const InvoicePeriodRangeFilter: React.FC<AnalysisFilterComponentProps> = () => {
  const language = useLanguage();
  const setInvoicePeriodRangeFilterUpdateCallback = useSetInvoicePeriodRangeFilterUpdateCallback();
  const periods = useAllUnorderedInvoicePeriods();
  const periodsById = useMemo(() => keyBy(periods, 'id'), [periods]);
  const setAnalysisPageToZero = useSetCurrentPageToZero();
  const [range, setRange] = useNewestInvoicePeriodRange();
  const [from, setFrom] = useState<InvoicePeriodDTO>(range.from);
  const [to, setTo] = useState<InvoicePeriodDTO>(range.to);
  const { t } = useTranslation();

  const formatYearMonth = useCallback(
    (yearMonth: string) => moment(yearMonth).locale(language).format('MMMM YYYY'),
    [language]
  );
  const invoicePeriodRangeFilterUpdateCallback = useCallback((range: InvoicePeriodRange) => {
    setFrom(range.from);
    setTo(range.to);
  }, []);
  useEffect(() => {
    setInvoicePeriodRangeFilterUpdateCallback({ callback: invoicePeriodRangeFilterUpdateCallback });
  }, [setInvoicePeriodRangeFilterUpdateCallback, invoicePeriodRangeFilterUpdateCallback]);
  const handleChange = (e: SelectChangeEvent<unknown>) => {
    if (e.target.name === 'from') {
      const selectedFrom = periodsById[e.target.value as string];
      if (from !== selectedFrom) {
        setAnalysisPageToZero();
      }
      setFrom(selectedFrom);
      if (selectedFrom.id > to.id) {
        setTo(selectedFrom);
        setRange({ from: selectedFrom, to: selectedFrom });
      } else {
        setRange({ from: selectedFrom, to });
      }
    } else {
      const selectedTo = periodsById[e.target.value as string];
      if (to !== selectedTo) {
        setAnalysisPageToZero();
      }
      setTo(selectedTo);
      if (from.id > selectedTo.id) {
        setFrom(selectedTo);
        setRange({ from: selectedTo, to: selectedTo });
      } else {
        setRange({ from, to: selectedTo });
      }
    }
  };

  return (
    <FilterPanel name="invoice-period-range" title={t('sidebar.filter.invoicePeriodRange.title')}>
      <Stack direction="column" gap={3} sx={{ width: '100%', paddingRight: '30px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
          <Typography>{t('sidebar.filter.invoicePeriodRange.from')}</Typography>
          <DefaultSelect
            id="invoice-period-from"
            width={11}
            name="from"
            value={from.id}
            onChange={e => handleChange(e)}
          >
            {periods
              .slice()
              .sort(ascPeriodComparator)
              .map((p, i) => (
                <MenuItem key={i} value={p.id}>
                  {formatYearMonth(p.startDate)}
                </MenuItem>
              ))}
          </DefaultSelect>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="right">
          <Typography>{t('sidebar.filter.invoicePeriodRange.to')}</Typography>
          <DefaultSelect id="invoice-period-to" width={11} name="to" value={to.id} onChange={e => handleChange(e)}>
            {periods
              .slice()
              .sort(ascPeriodComparator)
              .map((p, i) => (
                <MenuItem key={i} value={p.id}>
                  {formatYearMonth(p.startDate)}
                </MenuItem>
              ))}
          </DefaultSelect>
        </Stack>
      </Stack>
    </FilterPanel>
  );
};

displayName(InvoicePeriodRangeFilter, 'InvoicePeriodRangeFilter');

export default InvoicePeriodRangeFilter;
