import React, { useMemo } from 'react';
import { Stack, styled } from '@mui/material';
import { fromPairs } from 'lodash';
import { displayName } from '../../util';
import BarChart from '../chart/BarChart';
import {
  useApplicationStatisticsChart,
  useApplicationStatisticsChartConfig,
  useApplicationStatisticsSeries,
} from '../../globalState';

const ApplicationStatisticsBarChart = styled(BarChart)`
  margin-top: 32px;
`;

const ApplicationStatisticsChart: React.FC = () => {
  const seriesOptions = useApplicationStatisticsSeries();
  const [config] = useApplicationStatisticsChartConfig();
  const series = useApplicationStatisticsChart();

  const seriesLabelByValue = useMemo(() => fromPairs(seriesOptions.map(o => [o.value, o.label])), [seriesOptions]);
  const valueAxisLabel = useMemo(() => seriesLabelByValue[config.series], [config.series, seriesLabelByValue]);
  const labels = useMemo(() => ({ valueAxis: valueAxisLabel }), [valueAxisLabel]);
  const dataWithLabels = useMemo(() => series.map(item => ({ ...item, labels })), [series, labels]);

  return (
    <Stack height="10px" flexGrow={1}>
      <ApplicationStatisticsBarChart
        orientation={'vertical'}
        data={dataWithLabels}
        valueAxisLabel={valueAxisLabel}
        valueFormatter={(input: number) => input.toString()}
        oneSeriesOneColor={true}
      />
    </Stack>
  );
};

displayName(ApplicationStatisticsChart, 'ApplicationStatisticsChart');

export default ApplicationStatisticsChart;
