import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../useDocumentTitle';
import { Stack, Typography } from '@mui/material';
import { displayName } from '../util';
import { toError, useCreateError, useLogError } from '../components/Error';
import { PageCard } from '../components/Container';
import { ActionButton } from '../components/Button';
import { useLanguageFromURL } from '../i18nContext';
import { DEFAULT_DEV_LOGIN_PAGE } from '../globalState/variables';
import { DEFAULT_PROD_LOGIN_PAGE, IS_PRODUCTION } from '../env';

const NotFound: React.FC = () => {
  const createError = useCreateError();
  const urlLanguage = useLanguageFromURL();
  const loggError = useLogError();
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  useDocumentTitle(t('application.shortName') + ' - ' + t('notFound.title'));
  useEffect(() => {
    if (i18n.language !== urlLanguage) {
      i18n
        .changeLanguage(urlLanguage)
        .catch(err => loggError(createError(`Could not change i18n language because of:\n${toError(err)}`)));
    }
  }, [urlLanguage, i18n, loggError, createError]);

  const handleBack = useCallback(() => {
    window.location.href = IS_PRODUCTION
      ? DEFAULT_PROD_LOGIN_PAGE
      : DEFAULT_DEV_LOGIN_PAGE.replace(':lng', urlLanguage);
  }, [urlLanguage]);

  return (
    <Stack direction="column" alignItems="center">
      <Stack height={theme => theme.spacing(10)} flexShrink={0} />
      <PageCard>
        <Stack direction="column" padding={4} gap={3}>
          <Typography variant={'h2'}>{t('notFound.title')}</Typography>
          <Typography variant={'body1'}>{t('notFound.text')}</Typography>
          <Stack direction={'row'}>
            <ActionButton onClick={handleBack}>{t('dialog.backToPortal')}</ActionButton>
          </Stack>
        </Stack>
      </PageCard>
    </Stack>
  );
};

displayName(NotFound, 'NotFound');

export default NotFound;
