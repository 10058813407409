import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import User from './pages/user/User';
import Admin from './pages/admin/Admin';
import Login from './pages/login/Login';
import ForgottenPassword from './pages/login/ForgottenPassword';
import ResetPassword from './pages/login/ResetPassword';
import Overview from './pages/user/Overview';
import Bills from './pages/user/Bills';
import Analysis from './pages/user/Analysis';
import CostCenters from './pages/user/CostCenters';
import Tags from './pages/user/Tags';
import NotAvailable from './pages/NotAvailable';
import NotFound from './pages/NotFound';
import Logout from './pages/Logout';
import TelcoBill from './pages/TelcoBill';
import AnalysisTable from './components/analysis/AnalysisTable';
import AnalysisChart from './components/analysis/AnalysisChart';
import { useLanguage } from './i18nContext';
import { displayName } from './util';
import Language from './pages/Language';
import Administrators from './pages/admin/Administrators';
import Jobs from './pages/admin/Jobs';
import Maintenance from './pages/admin/Maintenance';
import Support from './pages/admin/Support';
import Mail from './pages/Mail';

const App: React.FC = () => {
  const language = useLanguage();

  return (
    <>
      <Routes>
        <Route path="/login" element={<Navigate to={`/${language}/login`} />} />
        <Route path="/mail" element={<Mail />} />
        <Route path=":lng" element={<Language />}>
          <Route path="login" element={<Login />} />
          <Route path="forgottenPassword" element={<ForgottenPassword />} />
          <Route path="resetPassword/:uuid" element={<ResetPassword />} />
          <Route path="notAvailable" element={<NotAvailable />} />
          <Route path="logout" element={<Logout />} />
          <Route path="tb" element={<TelcoBill />}>
            <Route path="user" element={<User />}>
              <Route path="overview" element={<Overview />} />
              <Route path="bills" element={<Bills />} />
              <Route path="analysis">
                <Route path=":analysisType" element={<Analysis />}>
                  <Route path="table" element={<AnalysisTable />} />
                  <Route path="chart" element={<AnalysisChart />} />
                </Route>
              </Route>
              <Route path="costcenters" element={<CostCenters />} />
              <Route path="tags" element={<Tags />} />
            </Route>
            <Route path="admin" element={<Admin />}>
              <Route path="support" element={<Support />} />
              <Route path="administrators" element={<Administrators />} />
              <Route path="jobs" element={<Jobs />} />
              <Route path="maintenance" element={<Maintenance />} />
            </Route>
          </Route>
        </Route>
        <Route path="mail" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

displayName(App, 'App');

export default App;
